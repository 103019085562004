import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import { Col, Empty, Grid, Modal, Row, Spin } from 'antd';
import { gql } from 'apollo-boost';
import type { DocumentNode, FetchPolicy } from 'apollo-boost';
import { I18n, Storage } from 'aws-amplify';
import classNames from 'classnames';
import { debounce } from 'debounce';
import FileSaver from 'file-saver';
import { omit } from 'ramda';
import * as React from 'react';
import { useParams } from 'react-router';
import { useAsyncFn, useSetState } from 'react-use';
import { shallow } from 'zustand/shallow';

import { GetVideoDataByIdQuery } from '../../api/elevid';
import NoMessages from '../../assets/NoMessages.svg';
import { ChatMessages } from '../../components/ChatMessages';
import { ChatProfileList } from '../../components/ChatProfileList';
import { Page } from '../../components/Page';
import { VideoPreview } from '../../components/VideoPreview';
import { createMessage, setMessagesAsRead } from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { onCreateNotification } from '../../graphql/subscriptions';
import { useImperativeLazyQuery, useUnreadMessages } from '../../hooks';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { Roles, VideoAssets } from '../../typings';
import { convertArrayToById, insertAndShift } from '../../utils/helpers';

import styles from './Messenger.module.less';

// setting this to a vey far date to mark ALL messages as read
const SET_AS_READ_FROM = new Date('3000').toISOString();

interface Params {
  senderId?: string;
}

interface Types {
  myId: string;
  theirId: string;
  myProfile: string;
  theirProfile: string;
  myResponse: string;
  theirResponse: string;
  match: string;
  matchesByLastMessageSentAt: string;
  listMatchesByLastMessageSentAt: DocumentNode;
}

const {
  ejMatchesByLastMessageSentAt,
  isMatchesByLastMessageSentAt,
  getVideoDataById: getVideoDataByIdQuery,
  messagesByCreatedAt,
} = queries;

const checkName = (name: string, str: string) => {
  const pattern = str
    .split('')
    .map((x) => {
      return `(?=.*${x})`;
    })
    .join('');

  const regex = new RegExp(`${pattern}`, 'g');
  return name.match(regex);
};

const searchArray = <T extends unknown>({
  array = [],
  searchByKey = '',
  searchString = '',
}: {
  array?: T[];
  searchByKey?: string;
  searchString?: string;
}) => {
  const str = searchString.toLowerCase().substring(0, 3);
  const filteredArr = array.filter((x: any) => {
    const xSub = x[searchByKey].substring(0, 3).toLowerCase();
    return x[searchByKey].toLowerCase().includes(str) || checkName(xSub, str);
  });

  return filteredArr;
};

const GET_VIDEO_DATA_BY_ID = gql(getVideoDataByIdQuery);
const LIST_EMPLOYER_JOBSEEKER_MATCHES_BY_LAST_MESSAGE_SENT_AT = gql(ejMatchesByLastMessageSentAt);
const LIST_INVESTOR_STARTUP_MATCHES_BY_LAST_MESSAGE_SENT_AT = gql(isMatchesByLastMessageSentAt);
const LIST_MESSAGES_BY_CREATED_AT = gql(messagesByCreatedAt);
const CREATE_MESSAGE = gql(createMessage);
const SET_MESSAGES_AS_READ = gql(setMessagesAsRead);

const ON_CREATE_NOTIFICATION = gql(onCreateNotification);

const TYPES_BY_ROLE: Record<Roles, Types> = {
  Employers: {
    myId: 'employerId',
    theirId: 'jobSeekerId',
    myProfile: 'employer',
    theirProfile: 'jobSeeker',
    myResponse: 'employerResponse',
    theirResponse: 'jobSeekerResponse',
    match: 'EmployerJobSeekerMatch',
    matchesByLastMessageSentAt: 'EJMatchesByLastMessageSentAt',
    listMatchesByLastMessageSentAt: LIST_EMPLOYER_JOBSEEKER_MATCHES_BY_LAST_MESSAGE_SENT_AT,
  },
  JobSeekers: {
    myId: 'jobSeekerId',
    theirId: 'employerId',
    myProfile: 'jobSeeker',
    theirProfile: 'employer',
    myResponse: 'jobSeekerResponse',
    theirResponse: 'employerResponse',
    match: 'EmployerJobSeekerMatch',
    matchesByLastMessageSentAt: 'EJMatchesByLastMessageSentAt',
    listMatchesByLastMessageSentAt: LIST_EMPLOYER_JOBSEEKER_MATCHES_BY_LAST_MESSAGE_SENT_AT,
  },
  Investors: {
    myId: 'investorId',
    theirId: 'startupId',
    myProfile: 'investor',
    theirProfile: 'startup',
    myResponse: 'investorResponse',
    theirResponse: 'startupResponse',
    match: 'InvestorStartupMatch',
    matchesByLastMessageSentAt: 'ISMatchesByLastMessageSentAt',
    listMatchesByLastMessageSentAt: LIST_INVESTOR_STARTUP_MATCHES_BY_LAST_MESSAGE_SENT_AT,
  },
  Startups: {
    myId: 'startupId',
    theirId: 'investorId',
    myProfile: 'startup',
    theirProfile: 'investor',
    myResponse: 'startupResponse',
    theirResponse: 'investorResponse',
    match: 'InvestorStartupMatch',
    matchesByLastMessageSentAt: 'ISMatchesByLastMessageSentAt',
    listMatchesByLastMessageSentAt: LIST_INVESTOR_STARTUP_MATCHES_BY_LAST_MESSAGE_SENT_AT,
  },
};

export const Messenger = () => {
  const [
    {
      matchId,
      otherUserId,
      otherIdentityId,
      discussionTitle,
      document,
      videoAssets,
      searchValue,
      showMessages,
      discussionChangeCount,
      videoModalVisible,
    },
    setState,
  ] = useSetState<{
    otherUserId: string | null;
    otherIdentityId: string | null;
    discussionTitle: string;
    document: string | null;
    videoAssets: VideoAssets | null;
    searchValue: string;
    matchId: string | null;
    showMessages: boolean;
    discussionChangeCount: number;
    videoModalVisible: boolean;
  }>({
    otherUserId: null,
    otherIdentityId: null,
    discussionTitle: '',
    document: null,
    videoAssets: null,
    searchValue: '',
    matchId: null,
    showMessages: false,
    discussionChangeCount: 0,
    videoModalVisible: false,
  });

  const breakpoint = Grid.useBreakpoint();
  const { senderId } = useParams<Params>();
  const thisRef = React.useRef<any>({ debouncedProfilesSearch: null });

  const onDiscussionChange = ({ matchId, profileId, identityId, title, document, videoAssets }: any) =>
    setState({
      matchId,
      otherUserId: profileId,
      otherIdentityId: identityId,
      discussionTitle: title,
      document,
      videoAssets,
      showMessages: discussionChangeCount > 0,
      discussionChangeCount: discussionChangeCount + 1,
    });

  const { user, userRole } = useAuthStore((store) => ({ ...store }), shallow);
  const connectedUserId = user?.attributes.sub;
  const T = TYPES_BY_ROLE[userRole as Roles]; // userRole should never be null
  const unreadMessages = useUnreadMessages(connectedUserId);

  const dynamicQuery = {
    variables: {
      type: T.match,
      sortDirection: 'DESC',
      filter: {
        [T.myId]: { eq: user?.attributes?.sub },
        and: [{ [T.myResponse]: { eq: true } }, { [T.theirResponse]: { eq: true } }],
      },
    },
    onCompleted: (data: any) => {
      const matches = ((data ?? {})[T.matchesByLastMessageSentAt]?.items ?? []) as any;
      const senderMatch = senderId ? matches.find((match: any) => match[T.theirId] === senderId) : null;
      const selectedMatch = senderMatch || matches[0];

      if (selectedMatch) {
        const { userId: profileId, identityId, firstName: title, document } = selectedMatch[T.theirProfile];

        onDiscussionChange({ matchId: selectedMatch.id, profileId, identityId, title, document });
      }
    },
    fetchPolicy: 'network-only' as FetchPolicy,
  };

  const {
    data,
    loading: loadingProfiles,
    fetchMore: fetchMoreProfiles,
    refetch: refetchProfiles,
  } = useQuery(T.listMatchesByLastMessageSentAt, dynamicQuery);

  useSubscription(ON_CREATE_NOTIFICATION, {
    variables: { userId: connectedUserId },
    skip: !connectedUserId,
    onSubscriptionData: () => {
      refetchProfiles();
    },
  });

  const profilesQueryKey = T.matchesByLastMessageSentAt;

  const handleFetchMoreProfiles = React.useCallback(() => {
    fetchMoreProfiles({
      variables: { nextToken: data[profilesQueryKey]?.nextToken },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        return {
          ...fetchMoreResult,
          [profilesQueryKey]: {
            ...fetchMoreResult[profilesQueryKey],
            items: [...previousResult[profilesQueryKey].items, ...fetchMoreResult[profilesQueryKey].items],
          },
        };
      },
    });
  }, [data, fetchMoreProfiles, profilesQueryKey]);

  const discussionMessagesParams = React.useMemo(
    () => ({
      variables: {
        type: 'Message',
        sortDirection: 'DESC',
        filter: {
          or: [
            {
              and: [{ senderId: { eq: connectedUserId } }, { receiverId: { eq: otherUserId } }],
            },
            {
              and: [{ senderId: { eq: otherUserId } }, { receiverId: { eq: connectedUserId } }],
            },
          ],
        },
      },
      skip: !otherUserId || !connectedUserId,
    }),
    [connectedUserId, otherUserId]
  );

  const {
    data: messagesData,
    fetchMore: fetchMoreMessage,
    loading: loadingMessages,
  } = useQuery(LIST_MESSAGES_BY_CREATED_AT, discussionMessagesParams);

  const handleFetchMoreMessages = React.useCallback(() => {
    fetchMoreMessage({
      variables: { nextToken: messagesData?.MessagesByCreatedAt?.nextToken },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        return {
          ...fetchMoreResult,
          MessagesByCreatedAt: {
            ...fetchMoreResult.MessagesByCreatedAt,
            items: [...previousResult.MessagesByCreatedAt.items, ...fetchMoreResult.MessagesByCreatedAt.items],
          },
        };
      },
    });
  }, [fetchMoreMessage, messagesData]);

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [setMessagesAsRead] = useMutation(SET_MESSAGES_AS_READ);

  const profiles = React.useMemo(() => {
    const allProfiles = [];
    const otherUsersIds: (string | null)[] = [];

    for (const match of ((data ?? {})[T.matchesByLastMessageSentAt]?.items ?? []) as any) {
      const { userId: profileId, identityId, firstName: title, video: videoId, document } = match[T.theirProfile];

      if (!otherUsersIds.includes(profileId)) {
        allProfiles.push({
          handleChangeProps: { profileId, identityId, title, matchId: match.id, document, videoId },
          title,
          videoId,
          isCurrent: otherUserId === profileId,
          unreadMessagesCount: unreadMessages.countBySenderId[profileId],
        });

        otherUsersIds.push(profileId);
      }
    }

    return searchArray({ array: allProfiles, searchByKey: 'title', searchString: searchValue });
  }, [T.matchesByLastMessageSentAt, T.theirProfile, data, otherUserId, searchValue, unreadMessages.countBySenderId]);

  const getVideoDataById = useImperativeLazyQuery<GetVideoDataByIdQuery>(GET_VIDEO_DATA_BY_ID);
  const getVideoAssets = React.useCallback(
    (id: string) => getVideoDataById({ id }).then((value) => value?.data?.getVideoDataById?.assets),
    [getVideoDataById]
  );

  const sendMessage = ({ message }: any) => {
    message = (message || '').trim();

    if (!message) return;

    createMessage({
      variables: {
        input: { text: message, senderId: connectedUserId, receiverId: otherUserId, type: 'Message', read: false },
      },
      update: (proxy, { data: { createMessage } }) => {
        const { MessagesByCreatedAt, ...restMessages }: any = proxy.readQuery({
          query: LIST_MESSAGES_BY_CREATED_AT,
          ...discussionMessagesParams,
        });
        const messages = [createMessage, ...MessagesByCreatedAt.items];

        proxy.writeQuery({
          query: LIST_MESSAGES_BY_CREATED_AT,
          ...discussionMessagesParams,
          data: { ...restMessages, MessagesByCreatedAt: { ...MessagesByCreatedAt, items: messages } },
        });

        const profileMatchesQueryParams: any = omit(['onCompleted'], dynamicQuery);
        const profileMatchesData: any = proxy.readQuery({
          ...profileMatchesQueryParams,
          query: T.listMatchesByLastMessageSentAt,
        });

        const matchQueryName = T.matchesByLastMessageSentAt;
        const { items: matches } = profileMatchesData[matchQueryName];
        const involvedMatchIndex = matches.findIndex((match: any) => match.id === matchId);
        const newMatches = involvedMatchIndex >= 0 ? insertAndShift(matches, involvedMatchIndex, 0) : [...matches];

        newMatches[0].lastMessageSentAt = new Date(createMessage.createdAt).getTime();

        proxy.writeQuery({
          ...profileMatchesQueryParams,
          query: T.listMatchesByLastMessageSentAt,
          data: { [matchQueryName]: { ...profileMatchesData[matchQueryName], items: newMatches } },
        });

        return proxy;
      },
    });
  };

  const setAsRead = React.useCallback(() => {
    const isInDiscussion = !!connectedUserId && !!otherUserId;
    const hasUnreadReceivedMessages = (messagesData?.MessagesByCreatedAt?.items ?? []).some(
      ({ receiverId, read }: any) => receiverId === connectedUserId && !read
    );

    if (isInDiscussion && hasUnreadReceivedMessages) {
      setMessagesAsRead({
        variables: { senderId: otherUserId, receiverId: connectedUserId, from: SET_AS_READ_FROM },
        update: (proxy, { data: { setMessagesAsRead } }) => {
          const { MessagesByCreatedAt }: any = proxy.readQuery({
            query: LIST_MESSAGES_BY_CREATED_AT,
            ...discussionMessagesParams,
          });
          const newMessageById = convertArrayToById(setMessagesAsRead.readMessages);
          const messagesById = {
            ...convertArrayToById(MessagesByCreatedAt.items),
            ...newMessageById,
          };

          const messages = [];

          for (const msg of MessagesByCreatedAt.items) {
            messages.push(messagesById[msg.id]);
          }

          proxy.writeQuery({
            query: LIST_MESSAGES_BY_CREATED_AT,
            ...discussionMessagesParams,
            data: { MessagesByCreatedAt: { ...MessagesByCreatedAt, items: messages } },
          });

          const globalUnreadMessage: any = proxy.readQuery(unreadMessages.params);

          const newReadMessagesIds = Object.keys(newMessageById);

          const globalMessages = globalUnreadMessage.listMessages.items.filter(
            (msg: any) => !newReadMessagesIds.includes(msg.id)
          );

          proxy.writeQuery({
            ...unreadMessages.params,
            data: {
              ...globalUnreadMessage,
              listMessages: { ...globalUnreadMessage.listMessages, items: globalMessages },
            },
          });

          return proxy;
        },
      });
    }
  }, [connectedUserId, discussionMessagesParams, messagesData, otherUserId, setMessagesAsRead, unreadMessages.params]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ loading: loadingDocument }, downloadDocument] = useAsyncFn(async () => {
    if (document && otherIdentityId) {
      const storageDoc: any = await Storage.get(document as string, {
        level: 'protected',
        identityId: otherIdentityId,
        download: true,
        expires: 120,
      });

      if (storageDoc && storageDoc.Body) {
        return FileSaver.saveAs(storageDoc.Body as Blob, document);
      }
    }

    return null;
  }, [document]);

  const onProfilesSearch = React.useCallback(
    (event: any) => {
      event.persist();

      if (!thisRef.current.debouncedProfilesSearch) {
        thisRef.current.debouncedProfilesSearch = debounce(() => {
          setState({ searchValue: event.target.value });
        }, 300);
      }
      thisRef.current.debouncedProfilesSearch();
    },
    [setState]
  );

  const noMessages = profiles.length === 0 && !searchValue;

  return (
    <>
      <Page
        className={styles.page}
        type="private"
        browserTitle={I18n.get(lang.MESSENGER)}
        title={I18n.get(lang.MESSENGER)}
        layout={loadingProfiles || noMessages ? 'center' : 'full'}
        viewportHeightPage
      >
        {loadingProfiles ? (
          <Spin></Spin>
        ) : noMessages ? (
          <Empty description={I18n.get(lang.NO_MESSAGES_TO_SHOW)} image={NoMessages} />
        ) : (
          <Row className={classNames(styles.chatContainer, { [styles.showMessages]: showMessages })}>
            <Col className={styles.profilesCol} xs={12} md={9} lg={7} xl={6} xxl={4}>
              <ChatProfileList
                getVideoAssets={getVideoAssets}
                profiles={profiles}
                handleProfileChange={onDiscussionChange}
                onSearch={onProfilesSearch}
                fetchMoreProfiles={handleFetchMoreProfiles}
                hasMoreProfiles={typeof (data[profilesQueryKey] && data[profilesQueryKey])?.nextToken === 'string'}
              />
            </Col>
            <Col className={styles.messagesCol} xs={12} md={15} lg={17} xl={18} xxl={20}>
              {otherUserId && (showMessages || breakpoint.md) ? (
                <ChatMessages
                  // Set the key to force React to create a new component for every discussion
                  key={otherUserId}
                  downloadDocument={document && otherIdentityId ? downloadDocument : null}
                  discussionTitle={discussionTitle}
                  messages={messagesData?.MessagesByCreatedAt?.items}
                  currentUserId={connectedUserId}
                  sendMessage={sendMessage}
                  handleSeen={setAsRead}
                  loadingDocument={loadingDocument}
                  fetchMoreMessages={handleFetchMoreMessages}
                  hasMoreMessages={typeof messagesData?.MessagesByCreatedAt?.nextToken === 'string'}
                  loading={loadingMessages}
                  onShowVideo={() => setState({ videoModalVisible: true })}
                  onBack={() => setState({ showMessages: false })}
                />
              ) : null}
            </Col>
          </Row>
        )}
      </Page>

      {videoModalVisible && videoAssets ? (
        <Modal
          title={I18n.get(lang.PREVIEW_VIDEO)}
          visible={true}
          onOk={() => setState({ videoModalVisible: false })}
          onCancel={() => setState({ videoModalVisible: false })}
          className={styles.previewModal}
          cancelButtonProps={{ disabled: true, style: { display: 'none' } }}
          centered
        >
          <VideoPreview src={videoAssets.mp4} poster={videoAssets.thumbnail} style={{ maxHeight: '50vh' }} />
        </Modal>
      ) : null}
    </>
  );
};
