import * as React from 'react';

export function useFixSelectTabIndex() {
  const wrapperRef = React.useRef<any>(null);

  // Workaround to avoid having the main select container get focus.
  // When we pass tabIndex it's currently being set on the container
  // and the internal input but we want to have only the input get
  // the focus so we remove the container's tabIndex attribute.
  React.useEffect(() => {
    if (wrapperRef.current && wrapperRef.current.firstChild) {
      wrapperRef.current.firstChild.removeAttribute('tabIndex');
    }
  }, []);

  return wrapperRef;
}
