import * as React from 'react';

import { ComponentWithChildrenProps } from '../../typings/components';

interface Props extends ComponentWithChildrenProps {}

export function Label({ style, className, children }: Props) {
  return (
    <div style={style} className={`ant-col-24 ant-form-item-label ${className}`}>
      <label>
        <b>{children}</b>
      </label>
    </div>
  );
}
