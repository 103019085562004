import { Input, Select } from 'antd';
import { InputProps } from 'antd/lib/input';
import { countries } from 'countries-list';
import * as React from 'react';

import { useFixSelectTabIndex } from '../../hooks';
import { ComponentProps, TypeInputProps } from '../../typings';

import { ValueType } from './shared';

interface Props
  extends ComponentProps,
    Omit<InputProps, 'value' | 'onChange' | 'onFocus' | 'onBlur'>,
    TypeInputProps<ValueType> {}

// @ts-ignore Exclude 'Western Sahara'
delete countries.EH;

// Modify phone prefixes to be single instead of multiple ones for specific countries
// 1 - Dominican Republic
countries.DO.phone = '1';
// 2 - Kazakhstan
countries.KZ.phone = '7';
// 3 - Puerto Rico
countries.PR.phone = '1';
// 4 - Kosovo
countries.XK.phone = '383';

const options = Object.entries(countries)
  .map(([key, { name, phone, emoji }]) => ({ key, name, phone, emoji }))
  .sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  })
  .map(({ key, name, phone, emoji }) => ({
    key: `${name} ${phone} ${key}`,
    label: `${emoji}  (+${phone})`,
    value: phone,
  }));

const addonSelectStyle = { minWidth: '110px' };

const InternalPhoneInput = ({ style, onChange, value: valueObject, tabIndex, ...rest }: Props) => {
  const selectRef = useFixSelectTabIndex();

  const handlePrefixChange = React.useCallback(
    (prefix = '') => {
      if (typeof onChange === 'function') onChange({ prefix, number: valueObject?.number ?? '' });
    },
    [onChange, valueObject]
  );
  const handleNumberChange = React.useCallback(
    (event) => {
      if (typeof onChange === 'function')
        onChange({
          prefix: valueObject?.prefix ?? '',
          number: event.target.value,
        });
    },
    [onChange, valueObject]
  );

  return (
    <Input
      addonBefore={
        <div ref={selectRef}>
          <Select
            optionFilterProp="key"
            options={options}
            value={valueObject?.prefix ?? ''}
            onChange={handlePrefixChange}
            style={addonSelectStyle}
            tabIndex={tabIndex}
            showSearch
          />
        </div>
      }
      style={{ width: '100%', ...style }}
      value={valueObject?.number ?? ''}
      tabIndex={tabIndex}
      onChange={handleNumberChange}
      {...rest}
    />
  );
};

export const PhoneInput = React.memo(InternalPhoneInput);
