import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useMemo } from 'react';

import { ListMessagesQuery } from '../api/elevid';
import { listMessages } from '../graphql/queries';

const LIST_MESSAGES = gql(listMessages);

export function useUnreadMessages(userId?: string) {
  const variables = { filter: { receiverId: { eq: userId }, read: { ne: true } } };

  const query = useQuery<ListMessagesQuery>(LIST_MESSAGES, {
    variables,
    skip: !userId,
  });

  const countBySenderId = useMemo(
    () =>
      (query.data?.listMessages?.items || []).reduce<Record<string, number>>((countBySenderId, message) => {
        if (message) {
          const count = countBySenderId[message.senderId] || 0;

          countBySenderId[message.senderId] = count + 1;
        }

        return countBySenderId;
      }, {}),
    [query.data]
  );

  return {
    query,
    params: { query: LIST_MESSAGES, variables },
    countBySenderId,
  };
}
