import { useMutation } from '@apollo/react-hooks';
import { Button, Col, Form, Row, Space, Steps } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import type { DocumentNode } from 'apollo-boost';
import { I18n, Storage } from 'aws-amplify';
import * as React from 'react';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { shallow } from 'zustand/shallow';

import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES } from '../../utils/constants';
import { captureException } from '../../utils/sentry';
import { uploadVideo } from '../../utils/video-helpers';
import { ProfileSaveProgress, ProfileSaveStage } from '../ProfileSaveProgress';
import { VideoInputMessages } from '../VideoInput';

import styles from './RegisterWizard.module.less';
import { SupportingDocument } from './components/SupportingDocument';
import { Video } from './components/Video';

interface Props {
  personalInfoComponent: React.FC;
  questionnaireComponent: React.FC;
  createProfileMutation: DocumentNode;
  dataSelector: string;
  videInputMessages: VideoInputMessages;
}

const { Step } = Steps;

export const RegisterWizard = ({
  personalInfoComponent: PersonalInfo,
  questionnaireComponent: Questionnaire,
  createProfileMutation,
  dataSelector,
  videInputMessages,
}: Props) => {
  const [form] = Form.useForm();
  const breakpoint = useBreakpoint();
  const isWide = !!breakpoint.md;
  const [current, setCurrent] = useState(0);
  const [accValues, setAccValues] = useState({});
  const [profileSaveStage, setProfileSaveStage] = useState<ProfileSaveStage>('none');
  const [profileSaveFailed, setProfileSaveFailed] = useState(false);
  const [profileSubmitted, setProfileSubmitted] = useState(false);
  const { user, profile, setProfile } = useAuthStore((store) => store, shallow);
  const history = useHistory();

  const [createProfile, { data, loading: loadingCreateProfile, called: createProfileCalled }] =
    useMutation(createProfileMutation);

  // Using React.createElement() because 'eslint(react-hooks/exhaustive-deps)' doesn't recognize dependencies in JSX
  const steps = React.useMemo(
    () => [
      {
        title: I18n.get(lang.PERSONAL_INFORMATION),
        actionLabel: I18n.get(lang.GO_STEP_TWO),
        content: React.createElement(PersonalInfo),
      },
      {
        title: I18n.get(lang.VIDEO),
        actionLabel: I18n.get(lang.GO_STEP_THREE),
        content: React.createElement(Video, {
          validateButtonLabel: I18n.get(lang.GO_STEP_THREE),
          onValidate: () => form.submit(),
          messages: videInputMessages,
        }),
      },
      {
        title: I18n.get(lang.QUESTIONNAIRE),
        actionLabel: I18n.get(lang.GO_STEP_FOUR),
        content: React.createElement(Questionnaire),
      },
      {
        title: I18n.get(lang.SUPPORTING_DOCUMENT),
        actionLabel: I18n.get(lang.FINISH_BTN),
        content: React.createElement(SupportingDocument),
      },
    ],
    [PersonalInfo, Questionnaire, form, videInputMessages]
  );

  React.useEffect(() => {
    if (createProfileCalled && !loadingCreateProfile && data) {
      setProfile(data[dataSelector]);
    }
  }, [createProfileCalled, data, dataSelector, loadingCreateProfile, setProfile]);

  const next = React.useCallback(() => {
    setCurrent((curr) => curr + 1);
  }, []);

  const prev = React.useCallback(() => {
    setCurrent((curr) => curr - 1);
  }, []);

  const [{ loading }, handleSubmit] = useAsyncFn(
    async (values: any) => {
      const newValues = { ...accValues, ...values };
      setAccValues(newValues);

      if (current !== steps.length - 1) {
        next();
      } else {
        try {
          const { videoRec, supportDoc, ...input } = newValues as any;

          setProfileSubmitted(true);
          setProfileSaveFailed(false);
          setProfileSaveStage('video');

          const videoUploadResponse = await uploadVideo({ video: videoRec, name: user?.attributes.email });

          let supportDocUploadResponse: { key?: string } | null = null;

          if (supportDoc) {
            const document = supportDoc[0];

            if (document) {
              setProfileSaveStage('doc');

              supportDocUploadResponse = await Storage.put(document.name, document.originFileObj, {
                contentType: document.type,
                level: 'protected',
              });
            }
          }

          const mutationProps = {
            variables: {
              input: {
                ...input,
                userId: user?.attributes?.sub ?? null,
                identityId: user?.identityId ?? null,
                birthDate: input.birthDate.format('YYYY-MM-DD'),
                email: user?.attributes.email,
                video: videoUploadResponse.videoId,
                document: supportDocUploadResponse?.key,
              },
            },
          };

          setProfileSaveStage('profile');
          await createProfile(mutationProps);

          setProfileSaveStage('done');
        } catch (err) {
          captureException(err);
          setProfileSaveFailed(true);
        }
      }
    },
    [accValues, createProfile, current, next, steps.length, user]
  );

  const handleSaveProgressClose = () => {
    if (!profileSaveFailed) {
      history.push(APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE);
    }

    setProfileSaveStage('none');
  };

  if (profile && !profileSubmitted) {
    return <Redirect to={APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE} />;
  }

  return (
    <>
      <Row align="middle" justify="center" className={styles.stepsRow}>
        <Col xs={20} sm={20} md={22} lg={22} className={styles.stepsCol}>
          <Steps current={current} direction={isWide ? 'horizontal' : 'vertical'}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
      </Row>

      <Form
        form={form}
        onFinish={handleSubmit}
        hideRequiredMark
        layout="vertical"
        style={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}
      >
        <Row align="middle" justify="center" style={{ flex: '1 0 auto' }}>
          <Col span={24}>
            <div className={styles.stepsContent}>{steps[current].content}</div>
          </Col>

          <Col span={24} className={styles.stepsActionCol}>
            <Space className={styles.stepsAction} direction={isWide ? 'horizontal' : 'vertical'}>
              {current > 0 && (
                <Button className={styles.actionButton} onClick={prev}>
                  {I18n.get(lang.PREVIOUS_BTN)}
                </Button>
              )}
              <Button loading={loading} className={styles.actionButton} type="primary" onClick={() => form.submit()}>
                {current < steps.length - 1 ? (
                  <>
                    <b style={{ fontSize: 28, lineHeight: 0.1 }}>→</b>
                    <br />
                  </>
                ) : null}
                {steps[current].actionLabel}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <ProfileSaveProgress stage={profileSaveStage} failed={profileSaveFailed} onClose={handleSaveProgressClose} />
    </>
  );
};
