import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { gql } from 'apollo-boost';
import type { MutationUpdaterFn } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { shallow } from 'zustand/shallow';

import { Page } from '../../components/Page';
import { StartupSearchForm } from '../../components/StartupSearchForm';
import { createStartupSearch } from '../../graphql/mutations';
import { listStartupSearchs } from '../../graphql/queries';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES, investorRole } from '../../utils/constants';

const CREATE_STARTUP_SEARCH = gql(createStartupSearch);

export const LIST_STARTUPS_SEARCHES = gql(listStartupSearchs);

export const SearchStartup = () => {
  const { user, userRole } = useAuthStore((store) => ({ ...store }), shallow);

  const investorId = user?.attributes.sub;

  // FIXME: Fix missing __typename on cache update
  const addSearchToList: MutationUpdaterFn<any> = React.useCallback(
    (proxy, { data: { createStartupSearch } }) => {
      try {
        const { listStartupSearchs = [] }: any =
          proxy.readQuery({
            query: LIST_STARTUPS_SEARCHES,
            variables: { filter: { investorId: { eq: investorId } } },
          }) || {};
        const searches = [...listStartupSearchs.items, createStartupSearch];

        proxy.writeQuery({
          query: LIST_STARTUPS_SEARCHES,
          data: { listStartupSearchs: { items: searches } },
          variables: { filter: { investorId: { eq: investorId } } },
        });
      } catch {}

      return proxy;
    },
    [investorId]
  );

  const [createStartupSearch, { data }] = useMutation(CREATE_STARTUP_SEARCH, { update: addSearchToList });

  const onFinish = React.useCallback(
    async (values: any) => {
      const mutationProps = {
        variables: {
          input: {
            ...values,
            investorId,
          },
        },
      };

      try {
        await createStartupSearch(mutationProps);
        message.success(I18n.get(lang.SUCCESS_SEARCH_SAVED));
      } catch {
        message.error(I18n.get(lang.ERROR_SEARCH_SAVED));
      }
    },
    [createStartupSearch, investorId]
  );

  const [{ loading }, handleSubmit] = useAsyncFn(onFinish, [createStartupSearch, investorId]);

  if (userRole !== investorRole) return <Redirect to={APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE} />;

  if (data?.createStartupSearch?.id) {
    return <Redirect to={`${APP_NAVIGATION_ROUTES.STARTUPS_SEARCH_ROUTE}/${data?.createStartupSearch?.id}`} />;
  }

  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.CREATE_SEARCH)}
      title={I18n.get(lang.CREATE_SEARCH)}
      subTitle={I18n.get(lang.TITLE_SEARCH_STARTUP)}
    >
      <StartupSearchForm onFinish={handleSubmit} loading={loading} />
    </Page>
  );
};
