import styled from '@emotion/styled';
import { Button } from 'antd';
import { filter } from 'ramda';
import React, { Component } from 'react';
import { Swipeable } from 'react-swipeable';
import type { SwipeCallback } from 'react-swipeable';

import Slide from './Slide';

const NavigationButtons = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    z-index: 3;
    font-weight: bold;
    font-size: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.left {
      margin-left: 0;
    }

    &.right {
      margin-right: 0;
    }
  }
`;

const DEFAULT_GO_TO_SLIDE_DELAY = 200;

interface IState {
  count: number;
  index: number;
  goToSlide: number | null;
  prevPropsGoToSlide: number;
  newSlide: boolean;
}

interface IProps {
  ref?: any;
  slides: any[];
  goToSlide?: number;
  showNavigation: boolean;
  offsetRadius: number;
  animationConfig: object;
  goToSlideDelay: number;
  onCurrentSlideChange?: Function;
  onSwipedLeft?: SwipeCallback;
  onSwipedRight?: SwipeCallback;
  infinit?: boolean;
}

function mod(a: number, b: number): number {
  return ((a % b) + b) % b;
}

function isClassComponent(component: any) {
  return typeof component === 'function' && !!component?.prototype?.isReactComponent;
}

function isFunctionalComponent(Component: any) {
  return typeof Component === 'function' && !Component?.prototype?.isReactComponent;
}

function isReactComponent(component: any) {
  return isClassComponent(component) || isFunctionalComponent(component);
}

export default class Carousel extends Component<IProps, IState> {
  state: IState = {
    count: 0,
    index: 0,
    goToSlide: null,
    prevPropsGoToSlide: 0,
    newSlide: false,
  };

  goToIn?: number;

  generatedSlidesContent: { [key: string]: any } = {};
  generatedSlidesProps: any[] = [];

  static defaultProps = {
    offsetRadius: 2,
    animationConfig: { tension: 120, friction: 14 },
    goToSlideDelay: DEFAULT_GO_TO_SLIDE_DELAY,
    infinit: true,
  };

  static getDerivedStateFromProps(props: IProps, state: IState) {
    const { goToSlide } = props;

    if (goToSlide !== state.prevPropsGoToSlide) {
      return { prevPropsGoToSlide: goToSlide, goToSlide, newSlide: true };
    }

    return null;
  }

  componentDidMount() {
    this.generateSlidesContent();
    this.props.onCurrentSlideChange && this.props.onCurrentSlideChange(this.props.slides[this.state.index]);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    this.generateSlidesContent();
    const { goToSlideDelay } = this.props;
    const { index, goToSlide, newSlide } = this.state;

    if (prevState.count !== this.props.slides.length) {
      const newIndex = Math.max(0, Math.min(index, this.props.slides.length - 1));

      this.props.onCurrentSlideChange && this.props.onCurrentSlideChange(this.props.slides[this.state.index]);
      this.setState({ count: this.props.slides.length, index: newIndex });
    } else {
      if (typeof goToSlide === 'number') {
        if (newSlide) {
          this.handleGoToSlide();
        } else if (index !== goToSlide && typeof window !== 'undefined') {
          window.clearTimeout(this.goToIn);
          this.goToIn = window.setTimeout(this.handleGoToSlide, goToSlideDelay);
        } else if (typeof window !== 'undefined') {
          window.clearTimeout(this.goToIn);
        }
      }

      if (prevState.index !== this.state.index) {
        this.props.onCurrentSlideChange && this.props.onCurrentSlideChange(this.props.slides[this.state.index]);
      }
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.clearTimeout(this.goToIn);
    }
  }

  modBySlidesLength = (index: number): number => {
    return mod(index, this.props.slides.length);
  };

  moveSlide = (direction: -1 | 1) => {
    let index = this.state.index;

    if (!this.props.infinit) {
      index = Math.max(0, Math.min(index + direction, this.props.slides.length - 1));
    } else {
      index = this.modBySlidesLength(this.state.index + direction);
    }

    this.setState({
      index,
      goToSlide: null,
    });
  };

  getShortestDirection(from: number, to: number): -1 | 0 | 1 {
    if (from > to) {
      if (from - to > this.props.slides.length - 1 - from + to) {
        return 1;
      } else return -1;
    } else if (to > from) {
      if (to - from > from + this.props.slides.length - 1 - to) {
        return -1;
      } else return 1;
    }
    return 0;
  }

  handleGoToSlide = () => {
    if (typeof this.state.goToSlide !== 'number') {
      return;
    }

    const { index } = this.state;

    const goToSlide = mod(this.state.goToSlide, this.props.slides.length);

    if (goToSlide !== index) {
      let direction = this.getShortestDirection(index, goToSlide);
      const isFinished = this.modBySlidesLength(index + direction) === goToSlide;

      this.setState({
        index: this.modBySlidesLength(index + direction),
        newSlide: isFinished,
        goToSlide: isFinished ? null : goToSlide,
      });
    }
  };

  clampOffsetRadius(offsetRadius: number): number {
    const { slides } = this.props;
    const upperBound = Math.floor(((slides.length < 3 ? 3 : slides.length) - 1) / 2);

    if (offsetRadius < 0) {
      return 0;
    }
    if (offsetRadius > upperBound) {
      return upperBound;
    }

    return offsetRadius;
  }

  getPresentableSlides(): any {
    const { slides, infinit } = this.props;
    const { index } = this.state;
    let { offsetRadius } = this.props;
    offsetRadius = this.clampOffsetRadius(offsetRadius);
    const presentableSlides: any = [];

    if (infinit) {
      for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
        presentableSlides.push(slides[this.modBySlidesLength(index + i)]);
      }
    } else {
      for (let i = index === 0 ? 0 : -offsetRadius; i < 1 + offsetRadius; i++) {
        presentableSlides.push(slides[index + i]);
      }
    }

    return filter(Boolean, presentableSlides);
  }

  generateSlidesContent = () => {
    const { animationConfig, offsetRadius, infinit } = this.props;
    const presentableSlides = this.getPresentableSlides();

    this.generatedSlidesProps = [];
    presentableSlides.forEach((slide: any, presentableIndex: number) => {
      let ContentComponent: any = null;
      if (isReactComponent(slide.content)) ContentComponent = slide.content;

      this.generatedSlidesContent[slide.key] = this.generatedSlidesContent[slide.key] ? (
        React.cloneElement(this.generatedSlidesContent[slide.key], {
          ...slide.contentProps,
          isCurrentSlide: slide.key === this.props.slides[this.state.index]?.key,
        })
      ) : ContentComponent ? (
        <ContentComponent
          {...slide.contentProps}
          isCurrentSlide={slide.key === this.props.slides[this.state.index]?.key}
        />
      ) : (
        slide.content
      );

      this.generatedSlidesProps[presentableIndex] = {
        key: slide.key,
        onClick: slide.onClick,
        offsetRadius: this.clampOffsetRadius(offsetRadius),
        index: !infinit && this.state.index === 0 ? presentableIndex + 1 : presentableIndex,
        animationConfig: animationConfig,
        isCurrentSlide: slide.key === this.props.slides[this.state.index]?.key,
        child: ContentComponent ? ContentComponent : slide.content,
      };
    });
  };

  render() {
    const {
      showNavigation,
      onSwipedLeft = () => this.moveSlide(1),
      onSwipedRight = () => this.moveSlide(1),
    } = this.props;

    const { count, index } = this.state;

    const isCurrentFirst = count === 0 || index === 0;
    const isCurrentLast = count === 0 || index === count - 1;

    let navigationButtons = null;
    if (showNavigation) {
      navigationButtons = (
        <NavigationButtons>
          <Button
            className="left"
            type="primary"
            size="large"
            onClick={() => this.moveSlide(-1)}
            disabled={isCurrentFirst}
          >
            &lt;
          </Button>
          <Button
            className="right"
            type="primary"
            size="large"
            onClick={() => this.moveSlide(1)}
            disabled={isCurrentLast}
          >
            &gt;
          </Button>
        </NavigationButtons>
      );
    }
    return (
      <div style={{ position: 'relative', width: '100%', flex: 'auto' }}>
        {navigationButtons}
        <Swipeable onSwipedLeft={onSwipedLeft} onSwipedRight={onSwipedRight}>
          {this.generatedSlidesProps.map((slideProps: any) => (
            <Slide {...slideProps}>{this.generatedSlidesContent[slideProps.key]}</Slide>
          ))}
        </Swipeable>
      </div>
    );
  }
}
