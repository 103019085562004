/* eslint-disable no-template-curly-in-string */

import { Col, Form, Grid, Row } from 'antd';
import { I18n } from 'aws-amplify';
import moment from 'moment';
import { range } from 'ramda';
import * as React from 'react';

import { CountrySelect } from '../../../../components/CountrySelect';
import { RangeSelect } from '../../../../components/RangeSelect';
import { SectorSelect } from '../../../../components/SectorSelect';
import { Select } from '../../../../components/Select';
import { lang } from '../../../../i18n/lang';

import styles from './QuestionnaireStartup.module.less';

const employeesBoundaries = [1, 5, 5, 10, 10, 20, 20, 50, 50];

const maxLocationsItems = 1;
const maxSectorsItems = 5;

const years = range(1900, Number(moment().format('YYYY')) + 1)
  .reverse()
  .map((num) => ({ label: num.toString(), value: num }));

const { useBreakpoint } = Grid;

export const QuestionnaireStartup = () => {
  const notif = useBreakpoint();

  return (
    <Row justify="space-around" style={{ padding: '0 24px' }}>
      <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 1 : 1}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_LOCATION_STARTUP)}</b>
              <br />
              <span className={styles.labelInfo}>{I18n.get(lang.CHOOSE_AN_OPTION)}</span>
            </div>
          }
          name="location"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LOCATION_STARTUP) }]}
        >
          <CountrySelect tabIndex={1} maxItems={maxLocationsItems} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 2 : 3}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_SECTORS)}</b>
              <br />
              <span className={styles.labelInfo}>
                {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxSectorsItems)}
              </span>
            </div>
          }
          name="sectors"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_SECTORS_STARTUP) }]}
        >
          <SectorSelect tabIndex={4} maxItems={maxSectorsItems} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 3 : 2}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_CREATION_YEAR)}</b>
              <br />
              <span className={styles.labelInfo}>{I18n.get(lang.CHOOSE_AN_OPTION)}</span>
            </div>
          }
          name="creationYear"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_CREATION_YEAR) }]}
        >
          <Select showSearch placeholder="YYYY" optionFilterProp="value" options={years} tabIndex={2} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 4 : 4}>
        <Form.Item
          hasFeedback
          name="size"
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_SIZE)}</b>
              <br />
              <span className={styles.labelInfo}>{I18n.get(lang.CHOOSE_AN_OPTION)}</span>
            </div>
          }
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_SIZE) }]}
        >
          <RangeSelect tabIndex={5} boundaries={employeesBoundaries} />
        </Form.Item>
      </Col>
    </Row>
  );
};
