import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { useAuthStore } from '../stores/auth';
import { DEFAULT_DASHBOARD_ROUTE } from '../utils/constants';

type Props = RouteProps;

export const UnauthenticatedRoute = (props: Props) => {
  const { isAuthenticated, loaded } = useAuthStore((store) => store, shallow);

  if (!loaded) return null;

  return isAuthenticated ? <Redirect to={DEFAULT_DASHBOARD_ROUTE} /> : <Route {...props} />;
};
