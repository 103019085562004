import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { gql } from 'apollo-boost';
import type { MutationUpdaterFn } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { shallow } from 'zustand/shallow';

import { JobSeekerSearchForm } from '../../components/JobSeekerSearchForm';
import { Page } from '../../components/Page';
import { createJobSeekerSearch } from '../../graphql/mutations';
import { listJobSeekerSearchs } from '../../graphql/queries';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES, employerRole } from '../../utils/constants';

const CREATE_JOB_SEEKER_SEARCH = gql(createJobSeekerSearch);

export const LIST_JOB_SEEKERS_SEARCHES = gql(listJobSeekerSearchs);

export const SearchJobSeeker = () => {
  const { user, userRole } = useAuthStore((store) => ({ ...store }), shallow);

  const employerId = user?.attributes.sub;

  // FIXME: Fix missing __typename on cache update
  const addSearchToList: MutationUpdaterFn<any> = React.useCallback(
    (proxy, { data: { createJobSeekerSearch } }) => {
      try {
        const { listJobSeekerSearchs }: any = proxy.readQuery({
          query: LIST_JOB_SEEKERS_SEARCHES,
          variables: { filter: { employerId: { eq: employerId } } },
        });
        const searches = [...listJobSeekerSearchs.items, createJobSeekerSearch];

        proxy.writeQuery({
          query: LIST_JOB_SEEKERS_SEARCHES,
          data: { listJobSeekerSearchs: { items: searches } },
          variables: { filter: { employerId: { eq: employerId } } },
        });
      } catch {}

      return proxy;
    },
    [employerId]
  );

  const [createJobSeekerSearch, { data }] = useMutation(CREATE_JOB_SEEKER_SEARCH, { update: addSearchToList });

  const onFinish = React.useCallback(
    async (values: any) => {
      const mutationProps = {
        variables: {
          input: {
            ...values,
            employerId,
          },
        },
      };

      try {
        await createJobSeekerSearch(mutationProps);
        message.success(I18n.get(lang.SUCCESS_SEARCH_SAVED));
      } catch {
        message.error(I18n.get(lang.ERROR_SEARCH_SAVED));
      }
    },
    [createJobSeekerSearch, employerId]
  );

  const [{ loading }, handleSubmit] = useAsyncFn(onFinish, [createJobSeekerSearch, employerId]);

  if (userRole !== employerRole) return <Redirect to={APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE} />;

  if (data?.createJobSeekerSearch?.id) {
    return <Redirect to={`${APP_NAVIGATION_ROUTES.JOBSEEKERS_SEARCH_ROUTE}/${data?.createJobSeekerSearch?.id}`} />;
  }

  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.CREATE_SEARCH)}
      title={I18n.get(lang.CREATE_SEARCH)}
      subTitle={I18n.get(lang.TITLE_SEARCH_JOBSEEKER)}
    >
      <JobSeekerSearchForm onFinish={handleSubmit} loading={loading} initialValues={{ experienceYears: null }} />
    </Page>
  );
};
