import { Typography } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { shallow } from 'zustand/shallow';

import { AuthFormWrapper } from '../../components/AuthFormWrapper';
import { Page } from '../../components/Page';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { AuthUser } from '../../typings';
import { APP_NAVIGATION_ROUTES } from '../../utils/constants';

import { LoginFrom } from './components/LoginFrom';
import { UpdatePasswordForm } from './components/UpdatePasswordForm';
import { completeNewPassword, login } from './shared';

interface LocationState {
  from?: { pathname: string };
}

export function Login() {
  const [resetPassword, setResetPassword] = React.useState(false);
  const [cognitoUser, setCognitoUser] = React.useState<AuthUser>(null);
  const location = useLocation<LocationState | null | undefined>();
  const history = useHistory();

  const redirectTo = location.state?.from?.pathname || APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE;

  const {
    isAuthenticated,
    loaded: authIsLoaded,
    setUser,
  } = useAuthStore(({ isAuthenticated, loaded, setUser }) => ({ isAuthenticated, loaded, setUser }), shallow);

  const loginSubmit = React.useCallback(
    async (values) => {
      const { email, password } = values;
      const cognitoUser = await login({ username: email, password }, { setUser: setUser as any, history });

      setCognitoUser(cognitoUser);

      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setResetPassword(true);
      }
    },
    [setUser, history]
  );

  const [{ loading: loadingLogin }, handleLoginSubmit] = useAsyncFn(loginSubmit, [setUser, history]);

  const updatePasswordSubmit = React.useCallback(
    async (values) => {
      const { newPassword } = values;

      await completeNewPassword(cognitoUser, newPassword);
    },
    [cognitoUser]
  );

  const [{ loading: loadingUpdatePassword }, handleUpdatePasswordSubmit] = useAsyncFn(updatePasswordSubmit, [
    cognitoUser,
  ]);

  if (!authIsLoaded) return null;

  if (isAuthenticated) return <Redirect to={redirectTo} />;

  return (
    <Page type="public" loginButton={false} homeIcon={true} bigLogo={false} layout="topCenter">
      <Typography.Text className="loginTitle">{I18n.get(lang.WELCOME_BACK)}</Typography.Text>
      <AuthFormWrapper>
        {resetPassword ? (
          <UpdatePasswordForm onFinish={handleUpdatePasswordSubmit} loading={loadingUpdatePassword} />
        ) : (
          <LoginFrom onFinish={handleLoginSubmit} loading={loadingLogin} />
        )}
      </AuthFormWrapper>
    </Page>
  );
}
