/* eslint-disable no-template-curly-in-string */

import { InboxOutlined } from '@ant-design/icons';
import { Alert, Col, Modal, Row, Typography, Upload } from 'antd';
import USLocale from 'antd/lib/locale/en_US';
import UploadList from 'antd/lib/upload/UploadList';
import { RcFile, UploadFile, UploadLocale } from 'antd/lib/upload/interface';
import { I18n } from 'aws-amplify';
import { always, pipe } from 'ramda';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import { lang } from '../../i18n/lang';
import { blobToRcFile, rcFileToUploadFile } from '../../utils/helpers';
import { loadVideo } from '../../utils/video-helpers';
import { Label } from '../Label';
import { VideoPreview } from '../VideoPreview';

import { CaptureInput } from './CaptureInput';
import { RecorderInput } from './RecorderInput';
import styles from './VideoInput.module.less';

const supportedVideoTypes = '.mp4,.webm,.3gp,.mov,.ogg';
const supportedVideoTypesText = supportedVideoTypes.split(',').join(' ');

const captureSupported = (() => {
  const el = document.createElement('input');
  const supported = (el as any).capture !== undefined;

  return supported;
})();

// @ts-ignore
const mediaRecordingSupported = !!window.MediaRecorder;

const attachUrlStatus = (file: RcFile) => rcFileToUploadFile(file, { url: URL.createObjectURL(file), status: 'done' });

export interface VideoFile extends RcFile {
  url?: string;
}

export interface VideoInputMessages {
  uploadTitle?: string;
  uploadDescription?: string;
  recordTitle?: string;
}
export interface Props {
  onChange?: (file: VideoFile | null) => void;
  value?: VideoFile | null;
  recorderOptions?: Object;
  recordedVideoName?: string;
  maxDuration?: number;
  validateButtonLabel?: string;
  onValidate?: () => void;
  messages?: VideoInputMessages;
}

const { Dragger } = Upload;

export const VideoInput = React.memo(
  ({
    onChange,
    value,
    recorderOptions,
    recordedVideoName,
    maxDuration = 60,
    validateButtonLabel,
    onValidate,
    messages,
  }: Props) => {
    const [previewOn, setPreviewOn] = React.useState<boolean>(false);
    const [fileError, setFileError] = React.useState('');
    const [errorContext, setErrorContext] = React.useState('uploader');

    const togglePreviewModal = React.useCallback(() => {
      setPreviewOn((on) => !on);
    }, []);

    const handleFileChange = React.useCallback(
      (file: RcFile, context: 'capture' | 'uploader') => {
        onChange &&
          loadVideo(file)
            .then((meta: any) => {
              const duration = Math.round(meta.duration);

              if (maxDuration && duration > maxDuration) {
                setFileError(
                  I18n.get(lang.MAX_DURATION_VIDEO)
                    .replace('maxDuration', `${maxDuration}`)
                    .replace('durationTime', `${duration}`)
                );
                setErrorContext(context);
              } else {
                setFileError('');
                onChange(attachUrlStatus(file));
              }
            })
            .catch(() => {
              setFileError(I18n.get(lang.UNSUPPORTED_VIDEO));
              setErrorContext(context);
            });
      },
      [maxDuration, onChange]
    );

    const onRemove: (file: UploadFile<any>) => boolean | void = React.useCallback(
      pipe(always(null), onChange ? onChange : always(false)),
      []
    );

    const onBeforeUpload = (file: RcFile) => {
      handleFileChange(file, 'uploader');

      return false;
    };

    const onCaptureChange = (file: File) => {
      const rcFile = blobToRcFile(file, I18n.get(lang.MY_VIDEO));

      handleFileChange(rcFile, 'capture');
    };

    const onRecorderChange: (b: Blob) => void = React.useCallback(
      (recordedVideoBlob: Blob) => {
        const rcFile = blobToRcFile(recordedVideoBlob, I18n.get(lang.MY_VIDEO));

        setFileError('');
        onChange && onChange(attachUrlStatus(rcFile));
      },
      [onChange]
    );

    const valuesList: Array<UploadFile<any>> = value ? [value] : [];

    return (
      <Row className={styles.videoInputContainer}>
        <Col span={24} className={styles.fullSize}>
          <Row className={styles.fullSize}>
            <Col xs={24} lg={11} className={styles.partitionColDragger}>
              <div className={styles.partitionContainer}>
                {messages?.uploadTitle ? <Label>{messages.uploadTitle}</Label> : null}

                {messages?.uploadDescription ? (
                  <ReactMarkdown className={styles.uploadDescription}>{messages.uploadDescription}</ReactMarkdown>
                ) : null}

                <div className={styles.draggerContainer}>
                  <Dragger accept={supportedVideoTypes} beforeUpload={onBeforeUpload} showUploadList={false}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{I18n.get(lang.CLICK_DRAG_VIDEO)}</p>
                    <p className="ant-upload-hint">
                      {I18n.get(lang.ALLOWED_VIDEO_TYPE)} {supportedVideoTypesText}
                      <br />
                      {I18n.get(lang.MAX_TIME_VIDEO)}
                    </p>
                    {fileError && errorContext === 'uploader' ? (
                      <Typography.Paragraph className={styles.fileError} type="danger">
                        {fileError}
                      </Typography.Paragraph>
                    ) : null}
                  </Dragger>
                </div>
              </div>
            </Col>

            <Col xs={24} lg={1} className={styles.videoInputDivider}>
              <div className={styles.contentDivider}>
                <div className={styles.dividedText}>
                  <strong>{I18n.get(lang.OR)}</strong>
                </div>
              </div>
            </Col>

            <Col xs={24} lg={12} className={styles.partitionColVideo}>
              <div className={styles.partitionContainer}>
                {messages?.recordTitle ? <Label>{messages.recordTitle}</Label> : null}

                {captureSupported ? (
                  <CaptureInput
                    maxDuration={maxDuration}
                    error={errorContext === 'capture' ? fileError : ''}
                    onChange={onCaptureChange}
                  />
                ) : mediaRecordingSupported ? (
                  <RecorderInput
                    onChange={onRecorderChange}
                    recordedVideoName={recordedVideoName}
                    options={recorderOptions}
                    validateButtonLabel={validateButtonLabel}
                    onValidate={onValidate}
                  />
                ) : (
                  <>
                    <Typography.Paragraph>
                      <Alert
                        type="warning"
                        message={I18n.get(lang.VIDEO_RECORDING_NOT_SUPPORTED)
                          .replace('${maxDuration}', maxDuration)
                          .replace('${uploadVideoLabel}', messages?.uploadTitle || '')}
                        showIcon
                      />
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                      {I18n.get(lang.VIDEO_RECORDING_NOT_SUPPORTED_INFOS)
                        .replace('${maxDuration}', maxDuration)
                        .replace('${uploadVideoLabel}', messages?.uploadTitle || '')}
                    </Typography.Paragraph>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row justify="center" align="middle" className={styles.uploadListContainer}>
            <Col lg={6} md={6} sm={18} xs={18}>
              {value && (
                <UploadList
                  onRemove={onRemove}
                  items={valuesList}
                  onPreview={togglePreviewModal}
                  locale={USLocale as UploadLocale}
                />
              )}
            </Col>
          </Row>
        </Col>

        {value && previewOn && (
          <Modal
            title={I18n.get(lang.PREVIEW_VIDEO)}
            visible={previewOn}
            onOk={togglePreviewModal}
            onCancel={togglePreviewModal}
            className={styles.previewModal}
            cancelButtonProps={{ disabled: true, style: { display: 'none' } }}
            centered
          >
            <VideoPreview src={value.url || URL.createObjectURL(value)} style={{ maxHeight: '50vh' }} />
          </Modal>
        )}
      </Row>
    );
  }
);
