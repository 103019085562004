import { Typography, notification } from 'antd';
import { Auth, I18n } from 'aws-amplify';
import * as React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { AuthFormWrapper } from '../../components/AuthFormWrapper';
import { Page } from '../../components/Page';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { useI18nStore } from '../../stores/i18n';
import { APP_NAVIGATION_ROUTES, employerType, investorType, jobSeekerType, startupType } from '../../utils/constants';
import { template } from '../../utils/helpers';
import { captureException } from '../../utils/sentry';
import { login } from '../Login';

import { SignupForm } from './components/SignupForm';

type RoleType = 'job-seeker' | 'startup' | 'investor' | 'employer';

const TITLE = {
  [jobSeekerType]: lang.REGISTER_AS_JOBSEEKER,
  [employerType]: lang.REGISTER_AS_EMPLOYER,
  [investorType]: lang.REGISTER_AS_INVESTOR,
  [startupType]: lang.REGISTER_AS_STARTUP,
};

export function Signup() {
  const history = useHistory();
  const { type } = useParams<{ type: RoleType }>();
  const title = I18n.get(TITLE[type]);
  const locale = useI18nStore((store) => store.locale);
  const setUser = useAuthStore((state) => state.setUser);

  const submit = React.useCallback(
    async (values) => {
      const { email, password } = values;

      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            profile: type,
            locale,
          },
        });

        await login({ username: email, password }, { setUser, history });

        notification.success({
          message: I18n.get(lang.SUCCESS_MSG_SIGNUP),
          description: I18n.get(lang.SUCCESS_DESC_SIGNUP),
          placement: 'topRight',
        });
      } catch (err) {
        // @ts-ignore
        if (err?.code === 'UsernameExistsException') {
          notification.error({
            message: I18n.get(lang.ERROR_EMAIL_SIGNUP),
            description: I18n.get(lang.ERROR_EMAIL_SIGNUP_DESC),
            placement: 'topRight',
          });
        } else {
          captureException(err);

          notification.error({
            message: I18n.get(lang.ERROR_SIGNUP),
            // @ts-ignore
            description: err.message,
            placement: 'topRight',
          });
        }
      }
    },
    [history, locale, setUser, type]
  );

  const [{ loading }, handleSubmit] = useAsyncFn(submit, [history, setUser, type]);

  const terms = (
    <Link key="terms" target="_blank" to={APP_NAVIGATION_ROUTES.TERMS_ROUTE}>
      {I18n.get(lang.TERMS_OF_SERVICES)}
    </Link>
  );

  const privacy = (
    <Link key="privacy" target="_blank" to={APP_NAVIGATION_ROUTES.PRIVACY_ROUTE}>
      {I18n.get(lang.PRIVACY_POLICY)}
    </Link>
  );

  return (
    <Page
      type="public"
      layout="topCenter"
      browserTitle={title}
      registerButton={false}
      loginButton={false}
      homeIcon={true}
      bigLogo={false}
    >
      <Typography.Text className="loginTitle">{I18n.get(TITLE[type])}</Typography.Text>
      <AuthFormWrapper>
        <SignupForm onFinish={handleSubmit} loading={loading} />
        <Typography.Paragraph>{template(I18n.get(lang.AGREE_TERMS_PRIVACY), { terms, privacy })}</Typography.Paragraph>
      </AuthFormWrapper>
    </Page>
  );
}
