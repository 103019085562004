/* eslint-disable no-template-curly-in-string */

import { DeleteFilled, EditFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Col, Empty, Modal, Row, Space, Spin, message } from 'antd';
import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { useUnmount } from 'react-use';
import { shallow } from 'zustand/shallow';

import { CreateInvestorStartupMatchMutation, SearchStartupsQuery } from '../../api/elevid';
import Done from '../../assets/Done.svg';
import NoSearchResult from '../../assets/NoSearchResult.svg';
import { Page } from '../../components/Page';
import { SwipyVideos, VideoItem } from '../../components/SwipyVideos';
import { createInvestorStartupMatch, deleteStartupSearch } from '../../graphql/mutations';
import { getStartupSearch, getVideoDataById as getVideoDataByIdQuery, searchStartups } from '../../graphql/queries';
import { useImperativeLazyQuery } from '../../hooks';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES, investorRole } from '../../utils/constants';
import { LIST_STARTUPS_SEARCHES } from '../SearchStartup/SearchStartup';

import styles from './StartupsSearchResult.module.less';

interface VideoMeta {
  userId: string;
  userName: string;
  companyName: string;
}

const { confirm } = Modal;

const GET_STARTUP_SEARCH = gql(getStartupSearch);
const SEARCH_STARTUPS = gql(searchStartups);
const CREATE_INVESTOR_STARTUP_MATCH = gql(createInvestorStartupMatch);
const GET_VIDEO_DATA_BY_ID = gql(getVideoDataByIdQuery);
const DELETE_STARTUP_SEARCH = gql(deleteStartupSearch);

export function StartupsSearchResult() {
  const { id: searchId } = useParams<{ id: string }>();
  const history = useHistory();
  const { user, userRole } = useAuthStore((store) => ({ ...store }), shallow);
  const [reactedToProfileIds, setReactedToProfileIds] = React.useState<string[]>([]);
  const [videos, setVideos] = React.useState<VideoItem<VideoMeta>[]>([]);
  const [responseType, setResponseType] = React.useState<'APPROVE' | 'REJECT' | null>(null);
  const client = useApolloClient();

  const { loading: loadingSearchData, data: searchData } = useQuery(GET_STARTUP_SEARCH, {
    variables: { id: searchId },
  });

  const { loading: loadingSearchResult, data: searchResult } = useQuery<SearchStartupsQuery>(SEARCH_STARTUPS, {
    variables: { searchId },
    fetchPolicy: 'network-only',
  });

  const [createInvestorStartupMatch, { loading: isResponseInProgress }] =
    useMutation<CreateInvestorStartupMatchMutation>(CREATE_INVESTOR_STARTUP_MATCH, {
      onCompleted: (matchData) =>
        setReactedToProfileIds((ids) => ids.concat(matchData?.createInvestorStartupMatch?.startupId as string)),
    });

  const userId = user?.attributes.sub;

  const [deleteStartupSearch] = useMutation(DELETE_STARTUP_SEARCH, {
    update: (proxy, { data: { deleteStartupSearch } }) => {
      try {
        const { listStartupSearchs }: any = proxy.readQuery({
          query: LIST_STARTUPS_SEARCHES,
          variables: { filter: { investorId: { eq: userId } } },
        });

        const searches = listStartupSearchs.items.filter((search: any) => search.id !== deleteStartupSearch.id);

        proxy.writeQuery({
          query: LIST_STARTUPS_SEARCHES,
          data: { listStartupSearchs: { ...listStartupSearchs, items: searches } },
          variables: { filter: { investorId: { eq: userId } } },
        });
      } catch {}

      return proxy;
    },
  });

  // Using the hook below is important to minimize repetetive network requests
  // In favor of apollo cache usage because the carousel slides re-mount on every move
  // And trigger refetch on and on
  const getVideoDataById = useImperativeLazyQuery(GET_VIDEO_DATA_BY_ID);

  React.useEffect(() => {
    const videos = (searchResult?.searchStartups ?? [])?.reduce<VideoItem<VideoMeta>[]>((items, item) => {
      if (item) {
        const { video, firstName, userId, companyName } = item;

        items.push({
          key: item.id,
          id: video,
          title: firstName,
          meta: { userId, userName: firstName, companyName },
        });
      }

      return items;
    }, []);

    setVideos(videos);
  }, [searchResult]);

  useUnmount(() => {
    const queryOptions = {
      query: SEARCH_STARTUPS,
      variables: {
        searchId,
      },
    };
    try {
      const { searchStartups }: any = client.readQuery(queryOptions);

      const searches = searchStartups.filter(
        (searchItem: any) => !reactedToProfileIds.includes(searchItem.userId as string)
      );

      client.writeQuery({
        ...queryOptions,
        data: { searchStartups: searches },
      });
    } catch {}
  });

  const getVideoAssets = React.useCallback(
    (id: string) => getVideoDataById({ id }).then((value: any) => value?.data?.getVideoDataById?.assets),
    [getVideoDataById]
  );

  const addResponse = React.useCallback(
    async (videoItem: VideoItem<VideoMeta>, response: boolean) => {
      try {
        if (!videoItem.meta) {
          throw new Error(
            `SHOULD NOT HAPPEN: setResponse video has no meta! videoItem:\n${JSON.stringify(videoItem, null, 2)}`
          );
        }

        setResponseType(response ? 'APPROVE' : 'REJECT');

        const {
          key,
          meta: { userId: startupId, userName, companyName },
        } = videoItem;

        const newVideos = videos.filter((video) => video.key !== key);

        await createInvestorStartupMatch({
          variables: {
            input: {
              investorId: user?.attributes.sub,
              startupId,
              investorResponse: response,
              lastMessageSentAt: -1,
              searchId,
              type: 'InvestorStartupMatch',
            },
          },
        });

        setVideos(newVideos);

        if (response) {
          message.success(
            I18n.get(lang.STARTUP_LIKED).replace('user', `${userName}`).replace('companyName', `${companyName}`)
          );
        } else {
          message.warning(
            I18n.get(lang.STARTUP_DISLIKED).replace('user', `${userName}`).replace('companyName', `${companyName}`)
          );
        }
      } catch (err) {
        console.error(err);
        message.error(I18n.get(lang.LIKE_DISLIKE_ERROR));
      }

      setResponseType(null);
    },
    [createInvestorStartupMatch, searchId, user, videos]
  );

  const onApprove = React.useCallback(
    (videoItem: VideoItem<VideoMeta>) => {
      return addResponse(videoItem, true);
    },
    [addResponse]
  );

  const onReject = React.useCallback(
    (videoItem: VideoItem<VideoMeta>) => {
      return addResponse(videoItem, false);
    },
    [addResponse]
  );

  const searchName = searchData?.getStartupSearch?.name;

  const deleteSearch = React.useCallback(() => {
    confirm({
      title: I18n.get(lang.DELETE_SEARCH_TITLE).replace('${searchName}', searchName),
      icon: <ExclamationCircleOutlined />,
      content: I18n.get(lang.DELETE_SEARCH_TEXT),
      onOk() {
        return deleteStartupSearch({ variables: { input: { id: searchId } } }).then(() => {
          history.push(APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE);
        });
      },
      onCancel() {},
    });
  }, [deleteStartupSearch, history, searchId, searchName]);

  if (userRole !== investorRole) return <Redirect to={APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE} />;

  const loading = loadingSearchData || loadingSearchResult;
  const noVideos = videos.length === 0 && reactedToProfileIds.length === 0;
  const noMoreVideosToShow = videos.length === 0 && reactedToProfileIds.length > 0;

  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.SEARCH_RESULTS)}
      title={I18n.get(lang.SEARCH_RESULTS)}
      subTitle={searchName || I18n.get(lang.LOADING)}
      layout={loading || noVideos || noMoreVideosToShow ? 'center' : 'full'}
    >
      {loading ? (
        <Spin />
      ) : noVideos ? (
        <Empty description={I18n.get(lang.NO_USER_FOUND_MSG)} image={NoSearchResult} />
      ) : noMoreVideosToShow ? (
        <Empty description={I18n.get(lang.ALL_VIDEOS_VIEWED_MSG)} image={Done} />
      ) : (
        <Row align="middle" justify="center" className={styles.container}>
          <Col span={24} className={styles.containerBody}>
            <div className={styles.searchContent}>
              <SwipyVideos
                videos={videos}
                getVideoAssets={getVideoAssets}
                onApprove={onApprove}
                onReject={onReject}
                isApproveInProgress={isResponseInProgress && responseType === 'APPROVE'}
                isRejectInProgress={isResponseInProgress && responseType === 'REJECT'}
              />
            </div>
          </Col>
        </Row>
      )}

      <Space size="small" className={styles.actionButtons}>
        <Link to={`${APP_NAVIGATION_ROUTES.STARTUPS_SEARCH_ROUTE}/update/${searchId}`}>
          <Button icon={<EditFilled />} size="large" type="link">
            {I18n.get(lang.EDIT_SEARCH_ACTION_LABEL)}
          </Button>
        </Link>
        <Button onClick={deleteSearch} icon={<DeleteFilled />} size="large" type="link" danger>
          {I18n.get(lang.DELETE_SEARCH_ACTION_LABEL)}
        </Button>
      </Space>
    </Page>
  );
}
