/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVideoToken = /* GraphQL */ `
  query GetVideoToken {
    getVideoToken
  }
`;
export const getVideoDataById = /* GraphQL */ `
  query GetVideoDataById($id: ID!) {
    getVideoDataById(id: $id) {
      videoId
      createdAt
      title
      description
      publishedAt
      updatedAt
      tags
      metadata {
        key
        value
      }
      source {
        uri
      }
      assets {
        hls
        iframe
        player
        thumbnail
        mp4
      }
      playerId
      _public
      panoramic
      mp4Support
    }
  }
`;
export const searchJobSeekers = /* GraphQL */ `
  query SearchJobSeekers($searchId: ID!) {
    searchJobSeekers(searchId: $searchId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const searchStartups = /* GraphQL */ `
  query SearchStartups($searchId: ID!) {
    searchStartups(searchId: $searchId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      location
      sectors
      creationYear
      size {
        min
        max
      }
      desiredFunding {
        min
        max
      }
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const getProfileJobSeeker = /* GraphQL */ `
  query GetProfileJobSeeker($userId: ID!) {
    getProfileJobSeeker(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const listProfileJobSeekers = /* GraphQL */ `
  query ListProfileJobSeekers(
    $userId: ID
    $filter: ModelProfileJobSeekerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfileJobSeekers(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfileStartup = /* GraphQL */ `
  query GetProfileStartup($userId: ID!) {
    getProfileStartup(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      location
      sectors
      creationYear
      size {
        min
        max
      }
      desiredFunding {
        min
        max
      }
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const listProfileStartups = /* GraphQL */ `
  query ListProfileStartups(
    $userId: ID
    $filter: ModelProfileStartupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfileStartups(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfileEmployer = /* GraphQL */ `
  query GetProfileEmployer($userId: ID!) {
    getProfileEmployer(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      sectors
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const listProfileEmployers = /* GraphQL */ `
  query ListProfileEmployers(
    $userId: ID
    $filter: ModelProfileEmployerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfileEmployers(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProfileInvestor = /* GraphQL */ `
  query GetProfileInvestor($userId: ID!) {
    getProfileInvestor(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const listProfileInvestors = /* GraphQL */ `
  query ListProfileInvestors(
    $userId: ID
    $filter: ModelProfileInvestorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfileInvestors(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobSeekerSearch = /* GraphQL */ `
  query GetJobSeekerSearch($id: ID!) {
    getJobSeekerSearch(id: $id) {
      id
      employerId
      name
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      createdAt
      updatedAt
    }
  }
`;
export const listJobSeekerSearchs = /* GraphQL */ `
  query ListJobSeekerSearchs(
    $filter: ModelJobSeekerSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobSeekerSearchs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employerId
        name
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStartupSearch = /* GraphQL */ `
  query GetStartupSearch($id: ID!) {
    getStartupSearch(id: $id) {
      id
      investorId
      name
      locations
      sectors
      desiredFunding {
        min
        max
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStartupSearchs = /* GraphQL */ `
  query ListStartupSearchs(
    $filter: ModelStartupSearchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStartupSearchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        investorId
        name
        locations
        sectors
        desiredFunding {
          min
          max
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployerJobSeekerMatch = /* GraphQL */ `
  query GetEmployerJobSeekerMatch($id: ID!) {
    getEmployerJobSeekerMatch(id: $id) {
      id
      employerId
      jobSeekerId
      employer {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      jobSeeker {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      employerResponse
      jobSeekerResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const listEmployerJobSeekerMatchs = /* GraphQL */ `
  query ListEmployerJobSeekerMatchs(
    $filter: ModelEmployerJobSeekerMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployerJobSeekerMatchs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employerId
        jobSeekerId
        employer {
          id
          userId
          identityId
          email
          firstName
          lastName
          birthDate
          companyName
          jobTitle
          sectors
          locations
          video
          document
          createdAt
          updatedAt
        }
        jobSeeker {
          id
          userId
          identityId
          email
          firstName
          lastName
          birthDate
          expertises
          sectors
          location
          languages
          video
          document
          createdAt
          updatedAt
        }
        employerResponse
        jobSeekerResponse
        lastMessageSentAt
        searchId
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvestorStartupMatch = /* GraphQL */ `
  query GetInvestorStartupMatch($id: ID!) {
    getInvestorStartupMatch(id: $id) {
      id
      investorId
      startupId
      investor {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      startup {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      investorResponse
      startupResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const listInvestorStartupMatchs = /* GraphQL */ `
  query ListInvestorStartupMatchs(
    $filter: ModelInvestorStartupMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvestorStartupMatchs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        investorId
        startupId
        investor {
          id
          userId
          identityId
          email
          firstName
          lastName
          birthDate
          companyName
          locations
          video
          document
          createdAt
          updatedAt
        }
        startup {
          id
          userId
          identityId
          email
          firstName
          lastName
          birthDate
          companyName
          jobTitle
          location
          sectors
          creationYear
          video
          document
          createdAt
          updatedAt
        }
        investorResponse
        startupResponse
        lastMessageSentAt
        searchId
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      senderId
      receiverId
      text
      read
      createdAt
      updatedAt
      type
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderId
        receiverId
        text
        read
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      source
      read
      createdAt
      type
      kind
      meta
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        source
        read
        createdAt
        type
        kind
        meta
        updatedAt
      }
      nextToken
    }
  }
`;
export const ejMatchesByLastMessageSentAt = /* GraphQL */ `
  query EjMatchesByLastMessageSentAt(
    $type: String
    $lastMessageSentAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmployerJobSeekerMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EJMatchesByLastMessageSentAt(
      type: $type
      lastMessageSentAt: $lastMessageSentAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employerId
        jobSeekerId
        employer {
          id
          userId
          identityId
          email
          firstName
          lastName
          birthDate
          companyName
          jobTitle
          sectors
          locations
          video
          document
          createdAt
          updatedAt
        }
        jobSeeker {
          id
          userId
          identityId
          email
          firstName
          lastName
          birthDate
          expertises
          sectors
          location
          languages
          video
          document
          createdAt
          updatedAt
        }
        employerResponse
        jobSeekerResponse
        lastMessageSentAt
        searchId
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const isMatchesByLastMessageSentAt = /* GraphQL */ `
  query IsMatchesByLastMessageSentAt(
    $type: String
    $lastMessageSentAt: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorStartupMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ISMatchesByLastMessageSentAt(
      type: $type
      lastMessageSentAt: $lastMessageSentAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        investorId
        startupId
        investor {
          id
          userId
          identityId
          email
          firstName
          lastName
          birthDate
          companyName
          locations
          video
          document
          createdAt
          updatedAt
        }
        startup {
          id
          userId
          identityId
          email
          firstName
          lastName
          birthDate
          companyName
          jobTitle
          location
          sectors
          creationYear
          video
          document
          createdAt
          updatedAt
        }
        investorResponse
        startupResponse
        lastMessageSentAt
        searchId
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByCreatedAt = /* GraphQL */ `
  query MessagesByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MessagesByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        senderId
        receiverId
        text
        read
        createdAt
        updatedAt
        type
      }
      nextToken
    }
  }
`;
export const notificationsByCreatedAt = /* GraphQL */ `
  query NotificationsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NotificationsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        source
        read
        createdAt
        type
        kind
        meta
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByKind = /* GraphQL */ `
  query NotificationsByKind(
    $kind: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    NotificationsByKind(
      kind: $kind
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        source
        read
        createdAt
        type
        kind
        meta
        updatedAt
      }
      nextToken
    }
  }
`;
