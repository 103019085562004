import { Col, Layout, Row, Space, Typography } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { lang } from '../../i18n/lang';
import { APP_NAVIGATION_ROUTES } from '../../utils/constants';
import { LocaleSelect } from '../LocaleSelect';

import styles from './Footer.module.less';

export function Footer() {
  return (
    <Layout.Footer className={styles.container}>
      <Row gutter={16} align="middle">
        <Col flex="auto" xs={{ order: 1 }}>
          <small>
            <Typography.Text className={styles.linkColor}>{I18n.get(lang.ALL_RIGHT)}</Typography.Text>
          </small>
        </Col>
        <Col xs={{ order: 3 }} sm={{ order: 2 }} md={{ order: 2 }}>
          <Space size={16}>
            <Link to={APP_NAVIGATION_ROUTES.TERMS_ROUTE} className={styles.linkColor}>
              <small>{I18n.get(lang.TERMS_OF_SERVICES)}</small>
            </Link>
            <Link to={APP_NAVIGATION_ROUTES.PRIVACY_ROUTE} className={styles.linkColor}>
              <small>{I18n.get(lang.PRIVACY_POLICY)}</small>
            </Link>
          </Space>
        </Col>
        <Col xs={{ order: 2 }} sm={{ order: 3 }} md={{ order: 3 }}>
          <LocaleSelect />
        </Col>
      </Row>
    </Layout.Footer>
  );
}
