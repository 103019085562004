import { Select } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { useFixSelectTabIndex } from '../../hooks';
import { useMaxItems } from '../../hooks/useMaxItems';
import { useI18nStore } from '../../stores/i18n';
import { ComponentProps, SelectProps } from '../../typings';

import { items } from './shared';

type ValueType = string | string[];

interface Props extends ComponentProps, SelectProps<ValueType> {
  maxItems?: number;
  showSearch?: boolean;
  tabIndex?: number;
}

function InternalExpertiseSelect({
  onChange,
  maxItems,
  placeholder,
  style,
  showSearch = true,
  tabIndex,
  ...rest
}: Props) {
  const isMultiple = maxItems !== 1;
  const handleChange = useMaxItems(maxItems, onChange);
  const locale = useI18nStore((store) => store.locale);
  const selectRef = useFixSelectTabIndex();

  const searchableItems = React.useMemo(
    () =>
      items.map(({ label, value }) => {
        const translatedLabel = I18n.get(label);
        return {
          key: `${translatedLabel} ${value}`,
          label: translatedLabel,
          value,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  return (
    <div ref={selectRef}>
      <Select
        {...rest}
        style={{ width: '100%', ...style }}
        mode={isMultiple ? 'multiple' : undefined}
        placeholder={placeholder}
        onChange={handleChange}
        options={searchableItems}
        optionFilterProp="key"
        showSearch={showSearch}
        tabIndex={tabIndex}
      />
    </div>
  );
}

export const ExpertiseSelect = React.memo(InternalExpertiseSelect);
