import { Card, Col, Row } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { lang } from '../../i18n/lang';

import styles from './SearchAsCard.module.less';

type Props = {
  title: string;
  to?: string;
};

export function SearchAsCard({ title, to }: Props) {
  const content = (
    <div className={styles.cardContainer}>
      <Card title={title} className={styles.searchAsCard}>
        <Row>
          <Col span={24} className={styles.stripe}>
            <b>{I18n.get(lang.VIEW_CANDIDATES)}</b>
          </Col>
        </Row>
      </Card>
    </div>
  );
  return to ? <Link to={to}>{content}</Link> : content;
}
