import { VideoUploader } from '@api.video/video-uploader';
import { API, graphqlOperation } from 'aws-amplify';

import { captureException } from '../utils/sentry';

export async function uploadVideo({ video, name }: { video: File; name?: string }) {
  // Get token
  const {
    data: { getVideoToken: uploadToken },
  } = (await API.graphql(
    graphqlOperation(`
    query GetVideoToken {
      getVideoToken
    }`)
  )) as any;

  // Upload Video
  const uploader = new VideoUploader({
    uploadToken,
    file: new File([await video.arrayBuffer()], name || video.name, {
      lastModified: video.lastModified,
      type: video.type,
    }),
  });

  return await uploader.upload();
}

export const loadVideo = (file: File): any =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve(this);
      };

      video.onerror = function (err) {
        reject(err);
      };

      video.src = window.URL.createObjectURL(file);
    } catch (err) {
      captureException(err);

      reject(err);
    }
  });
