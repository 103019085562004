/* eslint-disable no-template-curly-in-string */

import { Col, Form, Row } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { CountrySelect } from '../../../../components/CountrySelect';
import { SectorSelect } from '../../../../components/SectorSelect';
import { lang } from '../../../../i18n/lang';

import styles from './QuestionnaireEmployer.module.less';

const maxSectorsItems = 5;
const maxLocationsItems = 5;

export const QuestionnaireEmployer = () => {
  return (
    <Row justify="space-around">
      <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_LOCATIONS)}</b>
              <br />
              <span className={styles.labelInfo}>
                {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxLocationsItems)}
              </span>
            </div>
          }
          name="locations"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LOCATIONS) }]}
        >
          <CountrySelect tabIndex={1} maxItems={maxLocationsItems} />
        </Form.Item>
      </Col>

      <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_SECTORS)}</b>
              <br />
              <span className={styles.labelInfo}>
                {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxSectorsItems)}
              </span>
            </div>
          }
          name="sectors"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_SECTORS) }]}
        >
          <SectorSelect tabIndex={2} maxItems={maxSectorsItems} />
        </Form.Item>
      </Col>
    </Row>
  );
};
