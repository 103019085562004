/* eslint-disable no-template-curly-in-string */
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { shallow } from 'zustand/shallow';

import { Page } from '../../components/Page';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { employerRole, investorRole, jobSeekerRole, startupRole } from '../../utils/constants';
import { EmployersMatchResult } from '../EmployersMatchResult';
import { InvestorsMatchResult } from '../InvestorsMatchResult';
import { SearchListJobSeeker } from '../SearchListJobSeeker';
import { SearchListStartup } from '../SearchListStartup';

const component = {
  [jobSeekerRole]: EmployersMatchResult,
  [employerRole]: SearchListJobSeeker,
  [startupRole]: InvestorsMatchResult,
  [investorRole]: SearchListStartup,
};

export function Dashboard() {
  const { userRole, profile } = useAuthStore((store) => store, shallow);

  const Component: React.FunctionComponent | null = React.useMemo(
    () => (userRole ? component[userRole] : null),
    [userRole]
  );

  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.DASHBOARD)}
      title={I18n.get(lang.DASHBOARD)}
      subTitle={I18n.get(lang.DASHBOARD_WELCOME).replace('${name}', profile?.firstName)}
    >
      {Component ? <Component /> : null}
    </Page>
  );
}
