/* eslint-disable no-template-curly-in-string */

import { Col, Form, Grid, Row } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { CountrySelect } from '../../../../components/CountrySelect';
import { ExpertiseSelect } from '../../../../components/ExpertiseSelect';
import { LanguageSelect } from '../../../../components/LanguageSelect';
import { RangeSelect } from '../../../../components/RangeSelect';
import { SectorSelect } from '../../../../components/SectorSelect';
import { lang } from '../../../../i18n/lang';

import styles from './QuestionnaireJobSeeker.module.less';

const experienceYearsBoundaries = [0, 2, 2, 5, 5, 8, 8, 12, 12];

const maxExpertisesItems = 5;
const maxSectorsItems = 5;
const maxLocationsItems = 1;
const maxLanguagesItems = 5;

const { useBreakpoint } = Grid;

export const QuestionnaireJobSeeker = () => {
  const notif = useBreakpoint();
  return (
    <Row justify="space-around" style={{ padding: '0 24px' }}>
      <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 1 : 1}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_EXPERTISES)}</b>
              <br />
              <span className={styles.labelInfo}>
                {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxExpertisesItems)}
              </span>
            </div>
          }
          name="expertises"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_EXPERTISES) }]}
        >
          <ExpertiseSelect tabIndex={1} maxItems={maxExpertisesItems} />
        </Form.Item>
      </Col>

      <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 2 : 4}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_LOCATION_JOBSEEKER)}</b>
              <br />
              <span className={styles.labelInfo}>{I18n.get(lang.CHOOSE_AN_OPTION)}</span>
            </div>
          }
          name="location"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LOCATION_JOBSEEKER) }]}
        >
          <CountrySelect tabIndex={4} maxItems={maxLocationsItems} />
        </Form.Item>
      </Col>

      <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 3 : 2}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_SECTORS_JOBSEEKER)}</b>
              <br />
              <span className={styles.labelInfo}>
                {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxSectorsItems)}
              </span>
            </div>
          }
          name="sectors"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_SECTORS) }]}
        >
          <SectorSelect tabIndex={2} maxItems={maxSectorsItems} />
        </Form.Item>
      </Col>

      <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 4 : 5}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_LANGUAGES)}</b>
              <br />
              <span className={styles.labelInfo}>
                {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxLanguagesItems)}
              </span>
            </div>
          }
          name="languages"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LANGUAGES) }]}
        >
          <LanguageSelect tabIndex={5} maxItems={maxLanguagesItems} />
        </Form.Item>
      </Col>

      <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 5 : 3}>
        <Form.Item
          hasFeedback
          name="experienceYears"
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_EXPERIENCE_YEARS)}</b>
              <br />
              <span className={styles.labelInfo}>{I18n.get(lang.CHOOSE_AN_OPTION)}</span>
            </div>
          }
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_EXPERIENCE_YEARS) }]}
        >
          <RangeSelect tabIndex={3} boundaries={experienceYearsBoundaries} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 6 : 6}></Col>
    </Row>
  );
};
