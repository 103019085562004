import type { StoreApi, UseStore } from 'zustand';

import { setLocale } from '../i18n';
import { Locale } from '../typings/i18n';
import { storeDecorator } from '../utils/storeDecorator';

interface I18nStoreType {
  locale: Locale;
  setLocale: (locale?: Locale) => void;
  init: () => string;
}

const store = (set: Function, get: Function, api: StoreApi<I18nStoreType>) => ({
  locale: setLocale(),
  setLocale: (locale?: Locale) =>
    set((state: I18nStoreType) => {
      state.locale = setLocale(locale);
    }),
});

export const [useI18nStore]: [UseStore<I18nStoreType>] = storeDecorator(store);
