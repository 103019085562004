import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Col, Empty, Row, Spin, message } from 'antd';
import { gql } from 'apollo-boost';
import type { FetchPolicy } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useUnmount } from 'react-use';
import { shallow } from 'zustand/shallow';

import {
  EmployerJobSeekerMatch,
  ListEmployerJobSeekerMatchsQuery,
  OnEmployerJobSeekerMatchCreatedSubscription,
  UpdateEmployerJobSeekerMatchMutation,
} from '../../api/elevid';
import Done from '../../assets/Done.svg';
import NoImages from '../../assets/NoImages.svg';
import { SwipyVideos, VideoItem } from '../../components/SwipyVideos';
import { updateEmployerJobSeekerMatch } from '../../graphql/mutations';
import { getVideoDataById as getVideoDataByIdQuery, listEmployerJobSeekerMatchs } from '../../graphql/queries';
import { onEmployerJobSeekerMatchCreated } from '../../graphql/subscriptions';
import { useImperativeLazyQuery } from '../../hooks';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES, jobSeekerRole } from '../../utils/constants';

import styles from './EmployersMatchResult.module.less';

interface VideoMeta extends EmployerJobSeekerMatch {
  userName: string;
}

const LIST_MATCHES = gql(listEmployerJobSeekerMatchs);
const UPDATE_EMPLOYER_JOBSEEKER_MATCH = gql(updateEmployerJobSeekerMatch);
const GET_VIDEO_DATA_BY_ID = gql(getVideoDataByIdQuery);
const ON_EMPLOYER_JOB_SEEKER_MATCH_CREATED = gql(onEmployerJobSeekerMatchCreated);

export function EmployersMatchResult() {
  const { user, userRole } = useAuthStore((store) => ({ ...store }), shallow);
  const [reactedToProfileIds, setReactedToProfileIds] = React.useState<string[]>([]);
  const [videos, setVideos] = React.useState<VideoItem<VideoMeta>[]>([]);
  const [responseType, setResponseType] = React.useState<'APPROVE' | 'REJECT' | null>(null);
  const [goTo, setGoTo] = React.useState<number>(0);
  const { profileId: employerId } = useParams<{ profileId?: string }>();
  const jobSeekerId = user?.attributes.sub;
  const client = useApolloClient();

  const {
    loading: loadingSearchResult,
    data: matchResult,
    subscribeToMore,
  } = useQuery<ListEmployerJobSeekerMatchsQuery>(LIST_MATCHES, {
    variables: {
      filter: {
        jobSeekerId: { eq: jobSeekerId },
        and: [
          { jobSeekerResponse: { ne: true } },
          { jobSeekerResponse: { ne: false } },
          { employerResponse: { eq: true } },
        ],
      },
    },
    skip: !jobSeekerId,
    fetchPolicy: 'network-only' as FetchPolicy,
  });

  React.useEffect(() => {
    subscribeToMore<OnEmployerJobSeekerMatchCreatedSubscription>({
      document: ON_EMPLOYER_JOB_SEEKER_MATCH_CREATED,
      variables: { jobSeekerId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data) return prev;
        const newMatch = subscriptionData.data.onEmployerJobSeekerMatchCreated;
        return Object.assign({}, prev, {
          listEmployerJobSeekerMatchs: {
            ...prev.listEmployerJobSeekerMatchs,
            items: [...(prev.listEmployerJobSeekerMatchs?.items || []), newMatch],
          },
        });
      },
    });
  }, [subscribeToMore, jobSeekerId]);

  const [updateEmployerJobSeekerMatch, { loading: isResponseInProgress }] =
    useMutation<UpdateEmployerJobSeekerMatchMutation>(UPDATE_EMPLOYER_JOBSEEKER_MATCH, {
      onCompleted: (matchData) =>
        setReactedToProfileIds((ids) => ids.concat(matchData?.updateEmployerJobSeekerMatch?.employerId as string)),
    });

  // Using the hook below is important to minimize repetetive network requests
  // In favor of apollo cache usage because the carousel slides re-mount on every move
  // And trigger refetch on and on
  const getVideoDataById = useImperativeLazyQuery(GET_VIDEO_DATA_BY_ID);

  React.useEffect(() => {
    let goTo = -1;

    const videos = (matchResult?.listEmployerJobSeekerMatchs?.items ?? [])?.reduce<VideoItem<VideoMeta>[]>(
      (items, item) => {
        if (item) {
          const { employer } = item;

          items.push({
            key: item.id,
            id: employer.video,
            title: employer.firstName,
            meta: { ...item, userName: employer.firstName },
          });

          if (goTo < 0 && employerId === employer.userId) {
            goTo = items.length - 1;
          }
        }

        return items;
      },
      []
    );

    setVideos(videos);
    setGoTo(goTo);
  }, [employerId, matchResult]);

  useUnmount(() => {
    const queryOptions = {
      query: LIST_MATCHES,
      variables: {
        filter: {
          jobSeekerId: { eq: user?.attributes.sub },
          and: [
            { jobSeekerResponse: { ne: true } },
            { jobSeekerResponse: { ne: false } },
            { employerResponse: { eq: true } },
          ],
        },
      },
    };
    try {
      const { listEmployerJobSeekerMatchs }: any = client.readQuery(queryOptions);

      const matches = listEmployerJobSeekerMatchs.items.filter(
        (matchItem: any) => !reactedToProfileIds.includes(matchItem.employerId as string)
      );

      client.writeQuery({
        ...queryOptions,
        data: { listEmployerJobSeekerMatchs: { ...listEmployerJobSeekerMatchs, items: matches } },
      });
    } catch {}
  });

  const getVideoAssets = React.useCallback(
    (id: string) => getVideoDataById({ id }).then((value: any) => value?.data?.getVideoDataById?.assets),
    [getVideoDataById]
  );

  const addResponse = React.useCallback(
    async (videoItem: VideoItem<VideoMeta>, response: boolean) => {
      try {
        if (!videoItem.meta) {
          throw new Error(
            `SHOULD NOT HAPPEN: setResponse video has no meta! videoItem:\n${JSON.stringify(videoItem, null, 2)}`
          );
        }

        setResponseType(response ? 'APPROVE' : 'REJECT');

        const {
          key,
          meta: { id, employer, employerResponse, lastMessageSentAt, searchId, userName },
        } = videoItem;

        const newVideos = videos.filter((video) => video.key !== key);

        await updateEmployerJobSeekerMatch({
          variables: {
            input: {
              id,
              employerId: employer?.userId,
              jobSeekerId: user?.attributes.sub,
              employerResponse,
              jobSeekerResponse: response,
              lastMessageSentAt: response ? Date.now() : lastMessageSentAt,
              searchId,
              type: 'EmployerJobSeekerMatch',
            },
          },
        });

        setVideos(newVideos);

        if (response) {
          message.success(
            I18n.get(lang.EMPLOYER_LIKED)
              .replace('user', `${userName}`)
              .replace('companyName', `${employer?.companyName}`)
          );
        } else {
          message.warning(
            I18n.get(lang.EMPLOYER_DISLIKED)
              .replace('user', `${userName}`)
              .replace('companyName', `${employer?.companyName}`)
          );
        }
      } catch (err) {
        console.error(err);
        message.error(I18n.get(lang.LIKE_DISLIKE_ERROR));
      }

      setResponseType(null);
    },
    [updateEmployerJobSeekerMatch, user, videos]
  );

  const onApprove = React.useCallback(
    (videoItem: VideoItem<VideoMeta>) => {
      return addResponse(videoItem, true);
    },
    [addResponse]
  );

  const onReject = React.useCallback(
    (videoItem: VideoItem<VideoMeta>) => {
      return addResponse(videoItem, false);
    },
    [addResponse]
  );

  if (userRole !== jobSeekerRole) return <Redirect to={APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE} />;

  const noVideos = videos.length === 0 && reactedToProfileIds.length === 0;
  const noMoreVideosToShow = videos.length === 0 && reactedToProfileIds.length > 0;

  return (
    <Row align="middle" justify="center" className={styles.container}>
      <Col span={24} className={styles.containerBody}>
        <div className={styles.searchContent}>
          {loadingSearchResult ? (
            <Spin />
          ) : noVideos ? (
            <Empty description={I18n.get(lang.NO_USER_SAID_YES_MSG)} image={NoImages} />
          ) : noMoreVideosToShow ? (
            <Empty description={I18n.get(lang.ALL_VIDEOS_OF_USER_SAID_YES_VIEWED_MSG)} image={Done} />
          ) : (
            <SwipyVideos
              videos={videos}
              getVideoAssets={getVideoAssets}
              onApprove={onApprove}
              onReject={onReject}
              isApproveInProgress={isResponseInProgress && responseType === 'APPROVE'}
              isRejectInProgress={isResponseInProgress && responseType === 'REJECT'}
              goTo={goTo}
            />
          )}
        </div>
      </Col>
    </Row>
  );
}
