import { useSubscription } from '@apollo/react-hooks';
import { message } from 'antd';
import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';

import { ejMatchesByLastMessageSentAt, isMatchesByLastMessageSentAt, messagesByCreatedAt } from '../graphql/queries';
import { onMessageReceived, onMessagesRead } from '../graphql/subscriptions';
import { lang } from '../i18n/lang';
import { employerRole, jobSeekerRole, startupRole } from '../utils/constants';
import { convertArrayToById, insertAndShift } from '../utils/helpers';
import { captureException } from '../utils/sentry';

import { useUnreadMessages } from './useUnreadMessages';

const ON_MESSAGE_RECEIVED = gql(onMessageReceived);
const ON_MESSAGES_READ = gql(onMessagesRead);
const LIST_MESSAGES_BY_CREATED_AT = gql(messagesByCreatedAt);
const LIST_EMPLOYER_JOBSEEKER_MATCHES_BY_LAST_MESSAGE_SENT_AT = gql(ejMatchesByLastMessageSentAt);
const LIST_INVESTOR_STARTUP_MATCHES_BY_LAST_MESSAGE_SENT_AT = gql(isMatchesByLastMessageSentAt);

export function useMessenger({ profile: userProfile, userRole }: any) {
  const userId = userProfile?.userId;
  const unreadMessages = useUnreadMessages(userId);

  useSubscription(ON_MESSAGE_RECEIVED, {
    variables: { receiverId: userId },
    skip: !userId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const newMessage = subscriptionData.data.onMessageReceived;
      const isEmpOrJS = [employerRole, jobSeekerRole].includes(userRole ?? '');

      message.info(I18n.get(lang.NEW_MESSAGE).replace('text', `${newMessage.text}`));

      const discussionQueryParams = {
        query: LIST_MESSAGES_BY_CREATED_AT,
        variables: {
          type: 'Message',
          sortDirection: 'DESC',
          filter: {
            or: [
              {
                and: [{ senderId: { eq: userId } }, { receiverId: { eq: newMessage.senderId } }],
              },
              {
                and: [{ senderId: { eq: newMessage.senderId } }, { receiverId: { eq: userId } }],
              },
            ],
          },
        },
      };

      const discussionMatchProfilesQueryParams = {
        query: isEmpOrJS
          ? LIST_EMPLOYER_JOBSEEKER_MATCHES_BY_LAST_MESSAGE_SENT_AT
          : LIST_INVESTOR_STARTUP_MATCHES_BY_LAST_MESSAGE_SENT_AT,
        variables: {
          type: isEmpOrJS ? 'EmployerJobSeekerMatch' : 'InvestorStartupMatch',
          sortDirection: 'DESC',
          filter: {
            [userRole === jobSeekerRole
              ? 'jobSeekerId'
              : isEmpOrJS
              ? 'employerId'
              : userRole === startupRole
              ? 'startupId'
              : 'investorId']: { eq: userId },
            and: [
              { [isEmpOrJS ? 'employerResponse' : 'investorResponse']: { eq: true } },
              { [isEmpOrJS ? 'jobSeekerResponse' : 'startupResponse']: { eq: true } },
            ],
          },
        },
      };

      let globalUnreadMessage: any = null;

      try {
        globalUnreadMessage = client.readQuery(unreadMessages.params);
      } catch {}

      if (globalUnreadMessage?.listMessages) {
        const messages = [...globalUnreadMessage.listMessages.items, newMessage];

        client.writeQuery({
          ...unreadMessages.params,
          data: { listMessages: { ...globalUnreadMessage.listMessages, items: messages } },
        });
      }

      let discussionMessagesData: any = null;
      try {
        discussionMessagesData = client.readQuery(discussionQueryParams);
      } catch {}

      if (discussionMessagesData?.MessagesByCreatedAt) {
        const discussionMessages = [newMessage, ...discussionMessagesData.MessagesByCreatedAt.items];

        client.writeQuery({
          ...discussionQueryParams,
          data: { MessagesByCreatedAt: { ...discussionMessagesData.MessagesByCreatedAt, items: discussionMessages } },
        });
      }

      const matchQueryName = isEmpOrJS ? 'EJMatchesByLastMessageSentAt' : 'ISMatchesByLastMessageSentAt';
      let discussionMatchProfilesData: any = null;
      try {
        discussionMatchProfilesData = client.readQuery(discussionMatchProfilesQueryParams);
      } catch {}

      if (discussionMatchProfilesData && discussionMatchProfilesData[matchQueryName]) {
        const { items: matches } = discussionMatchProfilesData[matchQueryName];

        const involvedMatchIndex = matches.findIndex((match: any) => {
          const [key1, key2] = isEmpOrJS ? ['employerId', 'jobSeekerId'] : ['investorId', 'startupId'];
          return (
            (match[key1] === userId && match[key2] === newMessage.senderId) ||
            (match[key2] === userId && match[key1] === newMessage.senderId)
          );
        });
        const newMatches = insertAndShift(matches, involvedMatchIndex, 0);

        newMatches[0].lastMessageSentAt = new Date(newMessage.createdAt).getTime();

        client.writeQuery({
          ...discussionMatchProfilesQueryParams,
          data: { [matchQueryName]: { ...discussionMatchProfilesData[matchQueryName], items: newMatches } },
        });
      }
    },
  });

  useSubscription(ON_MESSAGES_READ, {
    variables: { senderId: userId },
    skip: !userId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      try {
        const { receiverId: otherUserId, readMessages: sentReadMessages } = subscriptionData.data.onMessagesRead;

        const discussionQueryParams = {
          query: LIST_MESSAGES_BY_CREATED_AT,
          variables: {
            type: 'Message',
            sortDirection: 'ASC',
            filter: {
              or: [
                {
                  and: [{ senderId: { eq: userId } }, { receiverId: { eq: otherUserId } }],
                },
                {
                  and: [{ senderId: { eq: otherUserId } }, { receiverId: { eq: userId } }],
                },
              ],
            },
          },
        };

        const readMessagesById = convertArrayToById(sentReadMessages);

        let discussionMessagesData: any = null;

        try {
          discussionMessagesData = client.readQuery(discussionQueryParams);
        } catch {}

        if (discussionMessagesData?.MessagesByCreatedAt) {
          const messagesById = {
            ...convertArrayToById(discussionMessagesData.MessagesByCreatedAt.items),
            ...readMessagesById,
          };

          const discussionMessages = [];

          for (const msg of discussionMessagesData.MessagesByCreatedAt.items) {
            discussionMessages.push(messagesById[msg.id]);
          }

          client.writeQuery({
            ...discussionQueryParams,
            data: { MessagesByCreatedAt: { ...discussionMessagesData.MessagesByCreatedAt, items: discussionMessages } },
          });
        }
      } catch (err) {
        captureException(err);
      }
    },
  });

  return {
    numberOfUnreadMessages: unreadMessages.query.data?.listMessages?.items?.length ?? 0,
  };
}
