import { Button, Modal, Typography } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { lang } from '../../i18n/lang';

import styles from './ProfileSaveProgress.module.less';
import { StageStatus, Status } from './components/StageStatus';

export type ProfileSaveStage = 'none' | 'video' | 'doc' | 'profile' | 'done';

interface Props {
  stage?: ProfileSaveStage;
  failed?: boolean;
  onClose?: () => void;
}

export function ProfileSaveProgress({ stage = 'none', failed = false, onClose }: Props) {
  let videoStatus: Status = 'pending';
  let docStatus: Status = 'pending';
  let profileStatus: Status = 'pending';

  switch (stage) {
    case 'video':
      videoStatus = failed ? 'failed' : 'ongoing';
      break;

    case 'doc':
      videoStatus = 'done';
      docStatus = failed ? 'failed' : 'ongoing';
      break;

    case 'profile':
      videoStatus = 'done';
      docStatus = 'done';
      profileStatus = failed ? 'failed' : 'ongoing';
      break;

    case 'done':
      videoStatus = 'done';
      docStatus = 'done';
      profileStatus = 'done';
      break;
  }

  const footer =
    stage === 'done' || failed
      ? [
          <Button key="close" type="primary" onClick={onClose}>
            {I18n.get(lang.CLOSE_FINISH)}
          </Button>,
        ]
      : false;

  return (
    <Modal className={styles.modal} visible={stage !== 'none'} footer={footer} closable={false} centered>
      <Typography.Paragraph>{I18n.get(lang.SAVING_PROFILE_MSG)}</Typography.Paragraph>
      <ol>
        <li>
          {I18n.get(lang.UPLOAD_VIDEO_STAGE)} <StageStatus status={videoStatus} />
        </li>
        <li>
          {I18n.get(lang.UPLOAD_DOC_STAGE)} <StageStatus status={docStatus} />
        </li>
        <li>
          {I18n.get(lang.UPLOAD_DATA_STAGE)} <StageStatus status={profileStatus} />
        </li>
      </ol>
      {stage === 'done' ? (
        <Typography.Paragraph>{I18n.get(lang.SUCCESS_MSG_SAVE_PROFILE)}</Typography.Paragraph>
      ) : failed ? (
        <Typography.Paragraph type="danger">{I18n.get(lang.ERROR_MSG_SAVE_PROFILE)}</Typography.Paragraph>
      ) : null}
    </Modal>
  );
}
