import * as React from 'react';

import { ComponentProps } from '../../typings';

interface Props extends ComponentProps {
  src?: string;
  poster?: string;
}

function VideoPreviewInternal({ src, poster, className, style = {} }: Props, ref?: any) {
  return (
    <video
      className={className}
      style={{ display: 'block', width: '100%', outline: 'none', backgroundColor: 'black', ...style }}
      ref={ref}
      src={src}
      poster={poster}
      controls
    >
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}

export const VideoPreview = React.forwardRef(VideoPreviewInternal);
