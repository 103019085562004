import { Select } from 'antd';
import * as React from 'react';

import { useFixSelectTabIndex } from '../../../hooks';
import { ComponentProps } from '../../../typings';

interface Props extends ComponentProps {
  options: Array<{ label: string; value: string }>;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  tabIndex?: number;
}

function InternalDateSelectFragment({ value, placeholder, options, onChange, style, tabIndex, ...rest }: Props) {
  const selectRef = useFixSelectTabIndex();

  const handleChange = (value: string) => {
    onChange && onChange(value);
  };

  function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (options.find((d) => d.value === e.target.value)) {
      onChange && onChange(e.target.value);
    }
  }

  return (
    <div ref={selectRef}>
      <Select
        style={style}
        options={options}
        value={value}
        placeholder={placeholder}
        optionFilterProp="value"
        onChange={handleChange}
        onBlur={handleBlur}
        notFoundContent={false}
        showSearch
        tabIndex={tabIndex}
        {...rest}
      />
    </div>
  );
}

export const DateSelectFragment = React.memo(InternalDateSelectFragment);
