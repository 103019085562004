/* eslint-disable no-template-curly-in-string */

import { Col, Form, Row } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { CountrySelect } from '../../../../components/CountrySelect';
import { lang } from '../../../../i18n/lang';

import styles from './QuestionnaireInvestor.module.less';

const maxLocationsItems = 5;

export const QuestionnaireInvestor = () => {
  return (
    <Row justify="space-around" align="middle">
      <Col
        xs={{ span: 20, offset: 2 }}
        sm={{ span: 20, offset: 2 }}
        md={{ span: 10, offset: 1 }}
        lg={{ span: 10, offset: 1 }}
      >
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={
            <div>
              <b>{I18n.get(lang.LABEL_LOCATIONS)}</b>
              <br />
              <span className={styles.labelInfo}>
                {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxLocationsItems)}
              </span>
            </div>
          }
          name="locations"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LOCATIONS) }]}
        >
          <CountrySelect maxItems={maxLocationsItems} />
        </Form.Item>
      </Col>
    </Row>
  );
};
