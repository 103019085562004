import { Col, Row } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { Page } from '../../components/Page';
import { SignupAsCard } from '../../components/SignupAsCard';
import { lang } from '../../i18n/lang';
import { APP_NAVIGATION_ROUTES } from '../../utils/constants';

import styles from './SignupPlans.module.less';

const LEARN_MORE_CANDIDATE =
  process.env.REACT_APP_LEARN_MORE_CANDIDATE || 'https://www.youtube.com/watch?v=pSqcR8rg_BU';

const LEARN_MORE_EMPLOYER = process.env.REACT_APP_LEARN_MORE_EMPLOYER || 'https://www.youtube.com/watch?v=IfsA9V5n7P0';
const LEARN_MORE_STARTUP = process.env.REACT_APP_LEARN_MORE_STARTUP || 'https://www.youtube.com/watch?v=qezHb5ldJao';
const LEARN_MORE_INVESTOR = process.env.REACT_APP_LEARN_MORE_INVESTOR || 'https://www.youtube.com/watch?v=avQRNKKpl1g';

export function SignupPlans() {
  return (
    <Page
      type="public"
      browserTitle={I18n.get(lang.REGISTER_FOR_FREE)}
      title={I18n.get(lang.REGISTER_FOR_FREE)}
      layout="center"
      registerButton={false}
    >
      <Row align="middle" justify="center" gutter={[32, 40]}>
        <Col xs={20} sm={20} md={12} lg={12} className={styles.cardCol}>
          <SignupAsCard
            title={I18n.get(lang.IM_A_CANDIDATE)}
            description={I18n.get(lang.SUBTITLE_JOB_SEEKER_CARD)}
            to={APP_NAVIGATION_ROUTES.JOB_SEEKER_SIGN_UP_ROUTE}
            learnMoreURL={LEARN_MORE_CANDIDATE}
          />
        </Col>
        <Col xs={20} sm={20} md={12} lg={12} className={styles.cardCol}>
          <SignupAsCard
            title={I18n.get(lang.IM_AN_EMPLOYER)}
            description={I18n.get(lang.SUBTITLE_EMPLOYER_CARD)}
            to={APP_NAVIGATION_ROUTES.EMPLOYER_SIGN_UP_ROUTE}
            learnMoreURL={LEARN_MORE_EMPLOYER}
          />
        </Col>
        <Col xs={20} sm={20} md={12} lg={12} className={styles.cardCol}>
          <SignupAsCard
            title={I18n.get(lang.IM_A_STARTUP)}
            description={I18n.get(lang.SUBTITLE_STARTUP_CARD)}
            to={APP_NAVIGATION_ROUTES.STARTUP_SIGN_UP_ROUTE}
            learnMoreURL={LEARN_MORE_STARTUP}
          />
        </Col>
        <Col xs={20} sm={20} md={12} lg={12} className={styles.cardCol}>
          <SignupAsCard
            title={I18n.get(lang.IM_AN_INVESTOR)}
            description={I18n.get(lang.SUBTITLE_INVESTOR_CARD)}
            to={APP_NAVIGATION_ROUTES.INVESTOR_SIGN_UP_ROUTE}
            learnMoreURL={LEARN_MORE_INVESTOR}
          />
        </Col>
      </Row>
    </Page>
  );
}
