import { AuthUser, Roles } from '../typings';

declare global {
  var dataLayer: any;
}

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
const trackingEnabled = trackingId && trackingId !== 'off';

loadGA();

export const GA = {
  setPagePath,
  setUser,
  like,
  dislike,
};

// helpers

function gtag(...args: any[]) {
  dataLayer.push(arguments);
}

function setPagePath(pathname: string) {
  gtag('config', trackingId, { page_path: pathname });
}

function setUser(user: AuthUser, userRole?: Roles | null) {
  gtag('set', { user_id: user?.attributes?.sub });
  gtag('set', { user_role: userRole ? userRole : undefined });
}

function like() {
  gtag('event', 'like');
}

function dislike() {
  gtag('event', 'dislike');
}

function loadScript(src: string, async: boolean = true) {
  let script = document.createElement('script');
  script.src = src;
  script.async = async;
  document.body.append(script);
}

function loadGA() {
  window.dataLayer = window.dataLayer || [];

  if (!trackingEnabled) return;

  loadScript(`https://www.googletagmanager.com/gtag/js?id=${trackingId}`);

  gtag('js', new Date());
  gtag('config', trackingId);
}
