import { ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Typography, notification } from 'antd';
import { Auth, I18n } from 'aws-amplify';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { AuthFormWrapper } from '../../components/AuthFormWrapper';
import { Page } from '../../components/Page';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES } from '../../utils/constants';
import { captureException } from '../../utils/sentry';
import { login } from '../Login';

import styles from './ConfirmEmail.module.less';

// regex to check if string is numbers only
const codePattern = /^\d{6}$/;

// we use a unique name to force all browsers to not do autocompletion
const passwordFieldName = `pwd-${Date.now()}`;
const codeFieldName = `confirmationCode-${Date.now()}`;

export function ConfirmEmail() {
  const history = useHistory();
  const setUser = useAuthStore((state) => state.setUser);

  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [confirmationCode, setConfirmationCode] = React.useState<string>('');

  const location = useLocation();

  React.useEffect(() => void setUsername((location.search.split('=') ?? [])[1]), [location.search]);

  const confirmSignup = async ({ username, code, password }: { username: string; code: string; password: string }) => {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (err) {
      if ((err as Error)?.message !== 'User cannot be confirmed. Current status is CONFIRMED') {
        captureException(err);

        notification.error({
          message: I18n.get(lang.ERROR_CONFIRM_EMAIL_MSG),
          description: I18n.get(lang.ERROR_CONFIRM_EMAIL_DESC),
          placement: 'topRight',
        });

        return;
      }
    }

    try {
      await Auth.signIn({
        username: username,
        password,
      });

      await login({ username: username, password }, { setUser: setUser as any, history });

      history.push(APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE);
    } catch (err) {
      captureException(err);

      notification.error({
        message: I18n.get(lang.LOGIN_FAILED_CONFIRM_EMAIL_MSG),
        description: I18n.get(lang.LOGIN_FAILED_CONFIRM_EMAIL_DESC),
        placement: 'topRight',
      });

      history.push(APP_NAVIGATION_ROUTES.LOGIN_ROUTE);
    }
  };

  const resendVerificationCode = async () => {
    try {
      await Auth.resendSignUp(username);

      notification.success({
        message: I18n.get(lang.SUCCESS_MSG_CONFIRM_EMAIL),
        description: I18n.get(lang.SUCCESS_DESC_CONFIRM_EMAIL),
        placement: 'topRight',
      });
    } catch (err) {
      captureException(err);

      notification.error({
        message: I18n.get(lang.ERROR_MSG_CONFIRM_EMAIL),
        description: I18n.get(lang.ERROR_DESC_CONFIRM_EMAIL),
        placement: 'topRight',
      });
    }
  };

  const [{ loading: loadingConfirmation }, handleConfirmSignup] = useAsyncFn(confirmSignup);
  const [{ loading: loadingResend }, handleResendVerificationCode] = useAsyncFn(resendVerificationCode, [username]);

  const handleOnPaste = React.useCallback(
    async (event: React.ClipboardEvent) => {
      const code = event.clipboardData.getData('Text').trim();

      /** Update input */
      setConfirmationCode(code);

      if (codePattern.test(code)) {
        // code is a valid number

        await handleConfirmSignup({ username, code, password });
      }
    },
    [handleConfirmSignup, username, password]
  );

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(event.currentTarget.value);
  }, []);

  const onChangePassword = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  }, []);

  const handleSubmit = React.useCallback(async () => {
    if (codePattern.test(confirmationCode)) {
      handleConfirmSignup({ username, password, code: confirmationCode });
    }
  }, [confirmationCode, handleConfirmSignup, password, username]);

  return (
    <Page
      type="public"
      browserTitle={I18n.get(lang.TITLE_CONFIRM_EMAIL_PAGE)}
      title={I18n.get(lang.TITLE_CONFIRM_EMAIL_PAGE)}
      layout="topCenter"
    >
      <AuthFormWrapper style={{ maxWidth: 600 }}>
        <Form hideRequiredMark layout="vertical" onFinish={handleSubmit}>
          <Space size="middle" direction="vertical">
            <Typography.Paragraph>{I18n.get(lang.CONFIRMATION_CODE_MSG)}</Typography.Paragraph>

            <Form.Item
              label={
                <div>
                  <b>{I18n.get(lang.PASSWORD)}</b>
                  <br />
                  <Typography.Text type="secondary">{I18n.get(lang.SUBTITLE_PASSWORD_CONFIRM_EMAIL)}</Typography.Text>
                </div>
              }
              name={passwordFieldName}
              rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_PASSWORD) }]}
              hasFeedback
            >
              <Input.Password onChange={onChangePassword} value={password} />
            </Form.Item>

            <Form.Item
              label={
                <div>
                  <b>{I18n.get(lang.LABEL_CONFIMATION_CODE)}</b>
                  <br />
                  <Typography.Text type="secondary">{I18n.get(lang.SUBLABEL_CONFIMATION_CODE)}</Typography.Text>
                </div>
              }
              name={codeFieldName}
              rules={[
                { required: true, message: I18n.get(lang.VALIDATOR_MSG_REQUIRED_CODE) },
                { pattern: codePattern, message: I18n.get(lang.VALIDATOR_MSG_PATTERN_CODE) },
              ]}
              hasFeedback
            >
              <Input onChange={handleChange} onPaste={handleOnPaste} value={confirmationCode} autoComplete="off" />
            </Form.Item>
            <div>
              <Button className={styles.confirmButton} type="primary" loading={loadingConfirmation} htmlType="submit">
                {I18n.get(lang.CONFIRM_EMAIL_BTN)}
              </Button>
              <Button
                className={styles.resendButton}
                type="default"
                loading={loadingResend}
                onClick={handleResendVerificationCode}
                icon={<ReloadOutlined />}
              >
                {I18n.get(lang.RESEND_CONFIRM_CODE_BTN)}
              </Button>
            </div>
          </Space>
        </Form>
      </AuthFormWrapper>
    </Page>
  );
}
