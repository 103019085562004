import { Button, Form, Input } from 'antd';
import { FormProps } from 'antd/lib/form';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { lang } from '../../../i18n/lang';

interface Props extends FormProps {
  loading: boolean;
}

export function UpdatePasswordForm({ loading, ...rest }: Props) {
  return (
    <Form layout="vertical" {...rest}>
      <Form.Item
        name="newPassword"
        label={I18n.get(lang.LABEL_NEW_PASSWORD)}
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: I18n.get(lang.VALIDATOR_MSG_NEW_PASSWORD),
          },
          {
            min: 8,
            message: I18n.get(lang.VALIDATOR_MSG_PASSWORD_LEN),
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label={I18n.get(lang.LABEL_CONFIRM_PASSWORD)}
        labelCol={{ span: 24 }}
        dependencies={['newPassword']}
        hasFeedback
        rules={[
          {
            required: true,
            message: I18n.get(lang.VALIDATOR_MSG_CONFIRM_PASSWORD),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(I18n.get(lang.TWO_PASSWORD_NOT_MUCH));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" loading={loading} htmlType="submit" block>
          {I18n.get(lang.SUBMIT_BTN)}
        </Button>
      </Form.Item>
    </Form>
  );
}
