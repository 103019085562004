import { Button, Col, Form, Input, Row, Space, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Auth, I18n } from 'aws-amplify';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { AuthFormWrapper } from '../../components/AuthFormWrapper';
import { Page } from '../../components/Page';
import { lang } from '../../i18n/lang';
import { APP_NAVIGATION_ROUTES } from '../../utils/constants';
import { captureException } from '../../utils/sentry';

export function ForgotPassword() {
  const history = useHistory();

  const submit = React.useCallback(
    async (values: Store) => {
      try {
        await Auth.forgotPassword(values.username);

        notification.success({
          message: I18n.get(lang.REDIRECTION_MSG_FORGOT_PASSWORD),
          description: I18n.get(lang.REDIRECTION_DESCRIPTION_FORGOT_PASSWORD),
          placement: 'topRight',
        });

        history.push({
          pathname: APP_NAVIGATION_ROUTES.RESET_PASSWORD_ROUTE,
          search: `?username=${values.username}`,
        });
      } catch (err) {
        captureException(err);

        notification.error({
          message: I18n.get(lang.ERROR_FORGOT_PASSWORD),
          description: err.message,
          placement: 'topRight',
        });
      }
    },
    [history]
  );

  const [{ loading }, handleSubmit] = useAsyncFn(submit);

  return (
    <Page
      type="public"
      browserTitle={I18n.get(lang.RESET_PASSWORD)}
      title={I18n.get(lang.RESET_PASSWORD)}
      layout="topCenter"
    >
      <AuthFormWrapper>
        <Form layout="vertical" onFinish={handleSubmit} className="login-form">
          <Form.Item
            label={I18n.get(lang.EMAIL)}
            name="username"
            rules={[
              {
                required: true,
                message: I18n.get(lang.VALIDATOR_MSG_EMAIL),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="text-center">
            <Row>
              <Col span={24}>
                <Space style={{ width: '100%' }} direction="vertical">
                  <Button
                    style={{ width: '100%' }}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    {I18n.get(lang.RESET_PASSWORD_BTN)}
                  </Button>
                  <Link to={APP_NAVIGATION_ROUTES.LOGIN_ROUTE}>{I18n.get(lang.IVE_REMEMBERED_LINK)}</Link>
                </Space>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </AuthFormWrapper>
    </Page>
  );
}
