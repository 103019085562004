import type { StoreApi, UseStore } from 'zustand';

import { CurrentPage, PageType } from '../typings/page';
import { storeDecorator } from '../utils/storeDecorator';

interface CurrentPageStoreType {
  currentPage: CurrentPage;
  setCurrentPage: (currentPage: Partial<CurrentPage>, type: PageType) => void;
}

const defaultCurrentPageProps: { [key in PageType]: CurrentPage } = {
  public: {
    browserTitle: '',
    title: '',
    subTitle: '',
    bigLogo: true,
    localeSelect: false,
    loginButton: true,
    logoutButton: false,
    registerButton: true,
    sideMenu: false,
    footer: true,
    viewportHeightPage: false,
    homeIcon: false,
  },
  private: {
    browserTitle: '',
    title: '',
    subTitle: '',
    bigLogo: true,
    localeSelect: true,
    loginButton: false,
    logoutButton: false,
    registerButton: false,
    sideMenu: true,
    footer: false,
    viewportHeightPage: false,
    homeIcon: false,
  },
};

const store = (set: Function, get: Function, api: StoreApi<CurrentPageStoreType>) => ({
  currentPage: getPageProps({} as CurrentPage, 'public'),
  setCurrentPage: (currentPage: Partial<CurrentPage>, type: PageType) =>
    set((state: CurrentPageStoreType) => {
      state.currentPage = getPageProps(currentPage as CurrentPage, type);
      document.title = currentPage.browserTitle ? `${currentPage.browserTitle} - elevid` : 'elevid';
    }),
});

function getPageProps(pageProps: CurrentPage, type: PageType): CurrentPage {
  // Components might pass props as 'undefined' and the spread operator
  // copies all own enumerable properties of an object, even those that
  // have the value 'undefined', so to avoid copying thos undefined values
  // we need to process the props manually

  return (Object.keys(pageProps) as Array<keyof CurrentPage>).reduce(
    <K extends keyof CurrentPage>(finalPageProps: CurrentPage, key: K) => {
      const value = pageProps[key];

      if (value !== undefined) {
        finalPageProps[key] = value;
      }

      return finalPageProps;
    },
    { ...defaultCurrentPageProps[type] }
  );
}

export const [useCurrentPageStore]: [UseStore<CurrentPageStoreType>] = storeDecorator(store);
