import * as React from 'react';

/**
 * Hook to handle limiting the number of selected items in a select
 * to the specified number.
 *
 * @param maxItems Maximum allowed items (unlimited if <1 or undefined)
 * @param onChange Change handler to call if number of items is valid
 *
 * @returns Handler to pass to the 'onChange' prop of the seelct comonent
 */
export function useMaxItems<T>(maxItems: number | undefined, onChange?: (value: T) => void) {
  const handleChange = React.useCallback(
    (value: T) => {
      if (maxItems && Array.isArray(value) && maxItems > 1 && value.length > maxItems) {
        return;
      }
      if (typeof onChange === 'function') onChange(value);
    },
    [maxItems, onChange]
  );

  return handleChange;
}
