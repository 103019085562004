import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import type { History } from 'history';

const dsn = process.env.REACT_APP_SENTRY_DSN;
const enabled = dsn && dsn !== 'off';

interface SentryInitProps {
  history: History;
}

export function init({ history }: SentryInitProps) {
  if (!enabled) return;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'elevid@' + process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    tracesSampleRate: 0.5,
  });
}

export function captureException(err: any) {
  const nativeErr = getNativeError(err);

  if (enabled && nativeErr) {
    Sentry.captureException(nativeErr);
  }

  console.error(nativeErr);
}

function getNativeError(err: any) {
  let nativeErr = null;

  try {
    if (!err || err instanceof Error) {
      nativeErr = err;
    } else if (typeof err === 'string') {
      nativeErr = new Error(err);
    } else {
      nativeErr = new Error(JSON.stringify(err));
    }
  } catch {}

  return nativeErr;
}
