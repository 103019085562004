import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { Page } from '../../components/Page';
import { RegisterWizard } from '../../components/RegisterWizard';
import { createProfileEmployer } from '../../graphql/mutations';
import { lang } from '../../i18n/lang';

import { PersonalInfoEmployer } from './components/PersonalInfoEmployer';
import { QuestionnaireEmployer } from './components/QuestionnaireEmployer';

const CREATE_EMPLOYER_PROFILE = gql(createProfileEmployer);

export const RegisterEmployer = () => {
  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.TITLE_REGISTER_EMPLOYER)}
      title={I18n.get(lang.TITLE_REGISTER_EMPLOYER)}
      subTitle={I18n.get(lang.SUBTITLE_EMPLOYER_CARD)}
      sideMenu={false}
      layout="full"
      logoutButton
    >
      <RegisterWizard
        createProfileMutation={CREATE_EMPLOYER_PROFILE}
        dataSelector="createProfileEmployer"
        personalInfoComponent={PersonalInfoEmployer}
        questionnaireComponent={QuestionnaireEmployer}
        videInputMessages={{
          uploadTitle: I18n.get(lang.VIDEO_UPLOAD_TITLE_EMPLOYER),
          uploadDescription: I18n.get(lang.VIDEO_UPLOAD_DESCRIPTION_EMPLOYER),
          recordTitle: I18n.get(lang.VIDEO_RECORD_TITLE_EMPLOYER),
        }}
      />
    </Page>
  );
};
