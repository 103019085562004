import { Alert, Button } from 'antd';
import { I18n } from 'aws-amplify';
import classNames from 'classnames';
import { merge } from 'ramda';
import * as React from 'react';
import 'video.js/dist/video-js.min.css';
import '@videojs/themes/dist/fantasy/index.css';
import videojs from 'video.js';
import 'webrtc-adapter';
import 'recordrtc';
import 'videojs-record/dist/css/videojs.record.css';
import 'videojs-record/dist/videojs.record.js';
import 'videojs-record/dist/plugins/videojs.record.ts-ebml.js';

import StartRecording from '../../../assets/StartRecording.svg';
import { lang } from '../../../i18n/lang';
import { captureException } from '../../../utils/sentry';

import styles from './RecorderInput.module.less';

type DeviceStatus = 'default' | 'not-found' | 'not-allowed' | 'ready';

const VJSButton = videojs.getComponent('Button');

class TextButton extends VJSButton {
  constructor(player: any, options: any) {
    super(player, options);

    this.text_ = document.createElement('span');
    this.text_.className = styles.contentText;
    this.el().appendChild(this.text_);
  }

  controlText(text: string) {
    super.controlText(text);
    if (this.text_) this.text_.textContent = text;
  }

  buildCSSClass() {
    return `${styles.textButton} ${super.buildCSSClass()}`;
  }
}

class ValidateButton extends TextButton {
  constructor(player: any, options: any, label: string, onClick: () => void) {
    super(player, options);

    this.onClick = onClick;
    this.controlText(label);
  }

  buildCSSClass() {
    return `${styles.validateButton} ${super.buildCSSClass()}`;
  }

  handleClick() {
    if (this.onClick) {
      this.onClick();
    }
  }
}

class RecordButton extends TextButton {
  constructor(player: any, options: any, recordText: string, stopText: string) {
    super(player, options);

    this.recordText = recordText;
    this.stopText = stopText;

    this.controlText(recordText);
  }

  buildCSSClass() {
    return `${styles.recordButton} ${super.buildCSSClass()}`;
  }

  enable() {
    super.enable();

    this.on(this.player_, 'startRecord', this.onStart);
    this.on(this.player_, 'stopRecord', this.onStop);
  }

  disable() {
    super.disable();

    this.off(this.player_, 'startRecord', this.onStart);
    this.off(this.player_, 'stopRecord', this.onStop);
  }

  handleClick() {
    let recorder = this.player_.record();

    if (!recorder.isRecording()) {
      recorder.start();
    } else {
      recorder.stop();
    }
  }

  onStart() {
    this.removeClass(styles.recordStart);
    this.addClass(styles.recordStop);
    this.controlText(this.stopText);
  }

  onStop() {
    this.removeClass(styles.recordStop);
    this.addClass(styles.recordStart);
    this.controlText(this.recordText);
  }
}

interface Props {
  onChange: (file: Blob) => void;
  options?: Object;
  recordedVideoName?: string;
  validateButtonLabel?: string;
  onValidate?: () => void;
}

const defaultVideoJsOptions = {
  controls: true,
  controlBar: {
    progressControl: false,
    pictureInPictureToggle: false,
  },
  bigPlayButton: false,
  fluid: true,
  plugins: {
    record: {
      audio: true,
      video: true,
      maxLength: 60,
      debug: true,
      convertEngine: 'ts-ebml',
      videoMimeType: 'video/webm;codecs=vp8,opus',
    },
  },
};

let videoJsRecorderDisposed = false;

export const RecorderInput = React.memo(
  ({ onChange, recordedVideoName, options = defaultVideoJsOptions, validateButtonLabel, onValidate }: Props) => {
    const recorderRef = React.useRef<HTMLVideoElement | null>(null);
    const playerRef = React.useRef<any>();
    const [recMode, setRecMode] = React.useState<'record' | 'playback' | null>(null);
    const [deviceStatus, setDeviceStatus] = React.useState<DeviceStatus>('default');
    const [isRecBtnClicked, setIsRecBtnClicked] = React.useState<boolean>(false);
    const [showRecBtn, setShowRecBtn] = React.useState<boolean>(true);

    const activateCamera = () => playerRef.current.record()?.getDevice();

    React.useEffect(() => {
      playerRef.current = videojs(recorderRef.current, merge(defaultVideoJsOptions, options));

      // add only new controls not the ones replacing the video.js recorder controls
      playerRef.current.one('ready', () => {
        if (playerRef.current.validateVideo) {
          playerRef.current.controlBar.addChild(playerRef.current.validateVideo);
        }
      });

      // device is ready
      playerRef.current.on('deviceReady', () => {
        setDeviceStatus('ready');
      });

      // error handling
      playerRef.current.on('error', (element: any, error: any) => {
        captureException(new Error(`RecorderInput error - ${JSON.stringify(error)}`));
      });

      playerRef.current.on('deviceError', (...x: any) => {
        const deviceErrorCode = playerRef.current.deviceErrorCode;

        let error = deviceErrorCode;

        // logging the error directly outputs a 'DOMException' so a new
        // error is generated to have a more correct log message
        if (deviceErrorCode.name && deviceErrorCode.message) {
          error = new Error(playerRef.current.deviceErrorCode.message);
          error.name = playerRef.current.deviceErrorCode.name;
        }

        if (error.name === 'NotFoundError') {
          setDeviceStatus('not-found');
        } else if (error.name === 'NotAllowedError') {
          setDeviceStatus('not-allowed');
        } else {
          captureException(error);
        }
      });

      playerRef.current.on('startRecord', () => {
        setRecMode('record');

        if (playerRef.current.validateVideo) {
          playerRef.current.validateVideo.hide();
        }
      });

      playerRef.current.on('stopRecord', () => {
        setRecMode('playback');

        if (playerRef.current.validateVideo) {
          playerRef.current.validateVideo.show();
        }
      });

      activateCamera();

      return () => {
        playerRef.current.dispose();
        videoJsRecorderDisposed = true;
      };
    }, [options]);

    React.useEffect(() => {
      if (!playerRef.current) return;

      if (validateButtonLabel && onValidate && !playerRef.current.validateVideo) {
        // create validate button
        playerRef.current.validateVideo = new ValidateButton(
          playerRef.current,
          options,
          validateButtonLabel,
          onValidate
        );

        playerRef.current.validateVideo.hide();
      }

      // replace video.js recorder recordToggle button
      playerRef.current.recordToggle = new RecordButton(
        playerRef.current,
        options,
        I18n.get(lang.START_RECORDING),
        I18n.get(lang.STOP_RECORDING)
      );
    }, [onValidate, options, validateButtonLabel]);

    React.useEffect(() => {
      // user completed recording and stream is available
      playerRef.current?.on('finishRecord', () => {
        // recordedData is a blob object containing the recorded data that
        // can be downloaded by the user, stored on server etc.
        const record = recordedVideoName
          ? merge(playerRef.current.recordedData, { recordedVideoName })
          : playerRef.current.recordedData;

        onChange(record);
      });

      return () => {
        playerRef.current?.off('finishRecord');
      };
    }, [onChange, recordedVideoName]);

    React.useEffect(() => {
      if (isRecBtnClicked && deviceStatus === 'ready') {
        playerRef.current?.record()?.start();
        setIsRecBtnClicked(false);
      }
    }, [deviceStatus, isRecBtnClicked]);

    // Enable progress control in recorded video playback
    // FIXME: Google Chrome bug for initial playback for videos with length > 5 seconds
    React.useEffect(() => {
      if (recMode === 'playback') {
        playerRef.current?.controlBar?.progressControl?.enable();
        playerRef.current?.controlBar?.progressControl?.show();

        return () => !videoJsRecorderDisposed && playerRef.current?.controlBar?.progressControl?.hide();
      }
    }, [recMode]);

    const handleStartRecordClick = () => {
      setIsRecBtnClicked(true);
      setShowRecBtn(false);
    };

    const hideVideo = deviceStatus === 'not-found' || deviceStatus === 'not-allowed';

    return (
      <>
        {deviceStatus === 'not-found' ? (
          <Alert
            type="warning"
            message={I18n.get(lang.NO_CAMERA_FOUND)}
            description={
              <>
                <p>{I18n.get(lang.CONNECT_CAMERA)}</p>
                <Button onClick={activateCamera}>{I18n.get(lang.ACTIVATE)}</Button>
              </>
            }
          />
        ) : null}

        {deviceStatus === 'not-allowed' ? (
          <Alert
            type="warning"
            message={I18n.get(lang.NO_CAMERA_ACCESS)}
            description={
              <>
                <p>{I18n.get(lang.ALLOW_CAMERA)}</p>
                <Button onClick={activateCamera}>{I18n.get(lang.ACTIVATE)}</Button>
              </>
            }
          />
        ) : null}

        <div
          className={classNames(styles.container, { [styles.showRecBtn]: showRecBtn, [styles.hideVideo]: hideVideo })}
        >
          <div data-vjs-player>
            <video id="myVideo" ref={recorderRef} className="video-js vjs-theme-fantasy"></video>
          </div>
          {showRecBtn ? (
            <div className={styles.recBtn}>
              <button type="button" onClick={handleStartRecordClick}>
                <img src={StartRecording} alt="start recording" />
              </button>
            </div>
          ) : null}
        </div>
      </>
    );
  }
);
