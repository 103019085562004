import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/react';
import { ApolloClient, ApolloLink, InMemoryCache } from 'apollo-boost';
import { Amplify, Auth } from 'aws-amplify';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import './index.less';
import './typings/globals.d.ts';
import App from './App';
import awsExports from './aws-exports.js';
import * as serviceWorker from './serviceWorker';
import * as sentry from './utils/sentry';

const history = createBrowserHistory();

const url = awsExports.aws_appsync_graphqlEndpoint;
const region = awsExports.aws_appsync_region;
const auth: AuthOptions = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

Amplify.configure(awsExports);

sentry.init({ history });

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Router history={history}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById('appRoot')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
