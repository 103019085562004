import { Card, Col, Row, Tag } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { lang } from '../../i18n/lang';

import styles from './SignupAsCard.module.less';

type Props = {
  title: React.ReactNode;
  description?: string;
  yearlyFee?: number;
  free?: boolean;
  to?: string;
  comingSoon?: boolean;
  limitedOffer?: boolean;
  learnMoreURL?: string;
};

export function SignupAsCard({
  title,
  description,
  yearlyFee,
  free = !yearlyFee,
  to,
  comingSoon = false,
  limitedOffer = false,
  learnMoreURL,
}: Props) {
  const card = (
    <Card title={title} className={styles.signupAsCard}>
      <div className={styles.description}>{description}</div>
      <Row>
        <Col span={24} className={styles.stripe}>
          {comingSoon ? (
            <Tag color="blue">{I18n.get(lang.COMING_SOON)}</Tag>
          ) : (
            <>
              {free && (
                <Tag color="blue" className={styles.freeTag}>
                  {I18n.get(limitedOffer ? lang.LIMITED_OFFER : lang.FREE)}
                </Tag>
              )}
              {yearlyFee !== undefined ? (
                <b style={{ marginLeft: free ? 25 : 0 }}>
                  {yearlyFee} € / {I18n.get(lang.YEAR)}
                </b>
              ) : null}
            </>
          )}
        </Col>
      </Row>
    </Card>
  );

  return (
    <div className={styles.cardContainer}>
      <div>
        {to && !comingSoon ? <Link to={to}>{card}</Link> : card}
        {learnMoreURL ? (
          <div className={styles.learnMore}>
            <a href={learnMoreURL} target="_blank" rel="noopener noreferrer">
              {I18n.get(lang.LEARN_MORE)}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
}
