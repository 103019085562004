import { Input, Layout } from 'antd';
import { SearchProps } from 'antd/lib/input';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './ChatProfileList.module.less';
import { Profile, ProfileProps } from './components/Profile';

const { Header, Content } = Layout;
const { Search } = Input;

export interface ChatProfileListProps {
  profiles: Array<Omit<ProfileProps, 'getVideoAssets' | 'onProfileClick'>>;
  getVideoAssets: ProfileProps['getVideoAssets'];
  handleProfileChange: ProfileProps['onProfileClick'];
  onSearch: SearchProps['onChange'];
  fetchMoreProfiles: () => void;
  hasMoreProfiles: boolean;
}

export const ChatProfileList = ({
  profiles,
  getVideoAssets,
  handleProfileChange,
  onSearch,
  fetchMoreProfiles,
  hasMoreProfiles,
}: ChatProfileListProps) => {
  return (
    <Layout className={styles.container}>
      <Header className={styles.header}>
        <Search onChange={onSearch} />
      </Header>
      <Content id="scrollableProfilesDiv" className={styles.content}>
        <InfiniteScroll
          dataLength={profiles.length}
          next={fetchMoreProfiles}
          hasMore={hasMoreProfiles}
          loader={null}
          scrollableTarget="scrollableProfilesElement"
        >
          {profiles.map((profile) => (
            <Profile
              {...profile}
              key={profile.videoId}
              getVideoAssets={getVideoAssets as any}
              onProfileClick={handleProfileChange}
            />
          ))}
        </InfiniteScroll>
      </Content>
    </Layout>
  );
};
