/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onMessageReceived = /* GraphQL */ `
  subscription OnMessageReceived($receiverId: ID!) {
    onMessageReceived(receiverId: $receiverId) {
      id
      senderId
      receiverId
      text
      read
      createdAt
      updatedAt
      type
    }
  }
`;
export const onMessagesRead = /* GraphQL */ `
  subscription OnMessagesRead($senderId: ID!) {
    onMessagesRead(senderId: $senderId) {
      senderId
      receiverId
      readMessages {
        id
        senderId
        receiverId
        text
        read
        createdAt
        updatedAt
        type
      }
    }
  }
`;
export const onEmployerJobSeekerMatchCreated = /* GraphQL */ `
  subscription OnEmployerJobSeekerMatchCreated($jobSeekerId: ID!) {
    onEmployerJobSeekerMatchCreated(jobSeekerId: $jobSeekerId) {
      id
      employerId
      jobSeekerId
      employer {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      jobSeeker {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      employerResponse
      jobSeekerResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onInvestorStartupMatchCreated = /* GraphQL */ `
  subscription OnInvestorStartupMatchCreated($startupId: ID!) {
    onInvestorStartupMatchCreated(startupId: $startupId) {
      id
      investorId
      startupId
      investor {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      startup {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      investorResponse
      startupResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProfileJobSeeker = /* GraphQL */ `
  subscription OnCreateProfileJobSeeker($userId: String) {
    onCreateProfileJobSeeker(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProfileJobSeeker = /* GraphQL */ `
  subscription OnUpdateProfileJobSeeker($userId: String) {
    onUpdateProfileJobSeeker(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProfileJobSeeker = /* GraphQL */ `
  subscription OnDeleteProfileJobSeeker($userId: String) {
    onDeleteProfileJobSeeker(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProfileStartup = /* GraphQL */ `
  subscription OnCreateProfileStartup($userId: String) {
    onCreateProfileStartup(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      location
      sectors
      creationYear
      size {
        min
        max
      }
      desiredFunding {
        min
        max
      }
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProfileStartup = /* GraphQL */ `
  subscription OnUpdateProfileStartup($userId: String) {
    onUpdateProfileStartup(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      location
      sectors
      creationYear
      size {
        min
        max
      }
      desiredFunding {
        min
        max
      }
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProfileStartup = /* GraphQL */ `
  subscription OnDeleteProfileStartup($userId: String) {
    onDeleteProfileStartup(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      location
      sectors
      creationYear
      size {
        min
        max
      }
      desiredFunding {
        min
        max
      }
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProfileEmployer = /* GraphQL */ `
  subscription OnCreateProfileEmployer($userId: String) {
    onCreateProfileEmployer(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      sectors
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProfileEmployer = /* GraphQL */ `
  subscription OnUpdateProfileEmployer($userId: String) {
    onUpdateProfileEmployer(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      sectors
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProfileEmployer = /* GraphQL */ `
  subscription OnDeleteProfileEmployer($userId: String) {
    onDeleteProfileEmployer(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      sectors
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProfileInvestor = /* GraphQL */ `
  subscription OnCreateProfileInvestor($userId: String) {
    onCreateProfileInvestor(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProfileInvestor = /* GraphQL */ `
  subscription OnUpdateProfileInvestor($userId: String) {
    onUpdateProfileInvestor(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProfileInvestor = /* GraphQL */ `
  subscription OnDeleteProfileInvestor($userId: String) {
    onDeleteProfileInvestor(userId: $userId) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJobSeekerSearch = /* GraphQL */ `
  subscription OnCreateJobSeekerSearch($employerId: String) {
    onCreateJobSeekerSearch(employerId: $employerId) {
      id
      employerId
      name
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJobSeekerSearch = /* GraphQL */ `
  subscription OnUpdateJobSeekerSearch($employerId: String) {
    onUpdateJobSeekerSearch(employerId: $employerId) {
      id
      employerId
      name
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJobSeekerSearch = /* GraphQL */ `
  subscription OnDeleteJobSeekerSearch($employerId: String) {
    onDeleteJobSeekerSearch(employerId: $employerId) {
      id
      employerId
      name
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStartupSearch = /* GraphQL */ `
  subscription OnCreateStartupSearch($investorId: String) {
    onCreateStartupSearch(investorId: $investorId) {
      id
      investorId
      name
      locations
      sectors
      desiredFunding {
        min
        max
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStartupSearch = /* GraphQL */ `
  subscription OnUpdateStartupSearch($investorId: String) {
    onUpdateStartupSearch(investorId: $investorId) {
      id
      investorId
      name
      locations
      sectors
      desiredFunding {
        min
        max
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStartupSearch = /* GraphQL */ `
  subscription OnDeleteStartupSearch($investorId: String) {
    onDeleteStartupSearch(investorId: $investorId) {
      id
      investorId
      name
      locations
      sectors
      desiredFunding {
        min
        max
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmployerJobSeekerMatch = /* GraphQL */ `
  subscription OnCreateEmployerJobSeekerMatch(
    $employerId: String
    $jobSeekerId: String
  ) {
    onCreateEmployerJobSeekerMatch(
      employerId: $employerId
      jobSeekerId: $jobSeekerId
    ) {
      id
      employerId
      jobSeekerId
      employer {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      jobSeeker {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      employerResponse
      jobSeekerResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmployerJobSeekerMatch = /* GraphQL */ `
  subscription OnUpdateEmployerJobSeekerMatch(
    $employerId: String
    $jobSeekerId: String
  ) {
    onUpdateEmployerJobSeekerMatch(
      employerId: $employerId
      jobSeekerId: $jobSeekerId
    ) {
      id
      employerId
      jobSeekerId
      employer {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      jobSeeker {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      employerResponse
      jobSeekerResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmployerJobSeekerMatch = /* GraphQL */ `
  subscription OnDeleteEmployerJobSeekerMatch(
    $employerId: String
    $jobSeekerId: String
  ) {
    onDeleteEmployerJobSeekerMatch(
      employerId: $employerId
      jobSeekerId: $jobSeekerId
    ) {
      id
      employerId
      jobSeekerId
      employer {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      jobSeeker {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      employerResponse
      jobSeekerResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvestorStartupMatch = /* GraphQL */ `
  subscription OnCreateInvestorStartupMatch(
    $investorId: String
    $startupId: String
  ) {
    onCreateInvestorStartupMatch(
      investorId: $investorId
      startupId: $startupId
    ) {
      id
      investorId
      startupId
      investor {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      startup {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      investorResponse
      startupResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvestorStartupMatch = /* GraphQL */ `
  subscription OnUpdateInvestorStartupMatch(
    $investorId: String
    $startupId: String
  ) {
    onUpdateInvestorStartupMatch(
      investorId: $investorId
      startupId: $startupId
    ) {
      id
      investorId
      startupId
      investor {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      startup {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      investorResponse
      startupResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvestorStartupMatch = /* GraphQL */ `
  subscription OnDeleteInvestorStartupMatch(
    $investorId: String
    $startupId: String
  ) {
    onDeleteInvestorStartupMatch(
      investorId: $investorId
      startupId: $startupId
    ) {
      id
      investorId
      startupId
      investor {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      startup {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      investorResponse
      startupResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($senderId: String, $receiverId: String) {
    onCreateMessage(senderId: $senderId, receiverId: $receiverId) {
      id
      senderId
      receiverId
      text
      read
      createdAt
      updatedAt
      type
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($senderId: String, $receiverId: String) {
    onUpdateMessage(senderId: $senderId, receiverId: $receiverId) {
      id
      senderId
      receiverId
      text
      read
      createdAt
      updatedAt
      type
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($senderId: String, $receiverId: String) {
    onDeleteMessage(senderId: $senderId, receiverId: $receiverId) {
      id
      senderId
      receiverId
      text
      read
      createdAt
      updatedAt
      type
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($userId: String!) {
    onCreateNotification(userId: $userId) {
      id
      userId
      source
      read
      createdAt
      type
      kind
      meta
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($userId: String!) {
    onUpdateNotification(userId: $userId) {
      id
      userId
      source
      read
      createdAt
      type
      kind
      meta
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($userId: String!) {
    onDeleteNotification(userId: $userId) {
      id
      userId
      source
      read
      createdAt
      type
      kind
      meta
      updatedAt
    }
  }
`;
