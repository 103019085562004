import { lang } from '../../i18n/lang';

export const items = [
  {
    label: lang.ACCOUNTING,
    value: 'ACC',
  },
  {
    label: lang.ADMINISTRATION,
    value: 'ADM',
  },
  {
    label: lang.AIRLINES,
    value: 'AIRL',
  },
  {
    label: lang.ARCHITECTURE_PLANNING,
    value: 'ARCH',
  },
  {
    label: lang.ARTS_CRAFTS,
    value: 'ARTS',
  },
  {
    label: lang.AUTOMOTIVE,
    value: 'AUTO',
  },
  {
    label: lang.AVIATION_AEROSPACE,
    value: 'AVIA',
  },
  {
    label: lang.BANKING,
    value: 'BANK',
  },
  {
    label: lang.BROADCAST_MEDIA,
    value: 'BCAS',
  },
  {
    label: lang.BUILDING_MATERIALS,
    value: 'BUIL',
  },
  {
    label: lang.CAPITAL_MARKETS,
    value: 'CAP',
  },
  {
    label: lang.CHEMICALS,
    value: 'CHEM',
  },
  {
    label: lang.CIVIL_ENGINEERING_CONSTRUCTION,
    value: 'CIVIL',
  },
  {
    label: lang.COMMERCE_EXPERTISE,
    value: 'CMRC',
  },
  {
    label: lang.CONSUMER_GOODS,
    value: 'CONS',
  },
  {
    label: lang.CUSTOMER_SERVICE,
    value: 'CS',
  },
  {
    label: lang.DEFENSE_SPACE,
    value: 'DEFS',
  },
  {
    label: lang.ECOMMERCE,
    value: 'ECOM',
  },
  {
    label: lang.EDUCATION,
    value: 'EDU',
  },
  {
    label: lang.ENGINEERING,
    value: 'ENG',
  },
  {
    label: lang.FINANCE,
    value: 'FIN',
  },
  {
    label: lang.FMCG,
    value: 'FMCG',
  },
  {
    label: lang.GOVERNMENT_PUBLIC_SECTOR,
    value: 'GOV',
  },
  {
    label: lang.GRAPHIC_DESIGN,
    value: 'GDES',
  },
  {
    label: lang.HOSPITAL,
    value: 'HEAL',
  },
  {
    label: lang.HOSPITALITY,
    value: 'HOS',
  },
  {
    label: lang.HUMAN_RESOURCES,
    value: 'HR',
  },
  {
    label: lang.IMPORT_EXPORT,
    value: 'IE',
  },
  {
    label: lang.INDUSTRIAL_AUTOMATION,
    value: 'INAU',
  },
  {
    label: lang.INFORMATION_TECHNOLOGY,
    value: 'IT',
  },
  {
    label: lang.INSURANCE,
    value: 'INS',
  },
  {
    label: lang.INVESTMENT_BANKING,
    value: 'INVE',
  },
  {
    label: lang.LAW_PRACTICE,
    value: 'LAW',
  },
  {
    label: lang.LEISURE_TRAVEL_TOURISM,
    value: 'TOUR',
  },
  {
    label: lang.LOGISTICS_SUPPLY_CHAIN,
    value: 'LOG',
  },
  {
    label: lang.MANAGEMENT,
    value: 'MGMT',
  },
  {
    label: lang.MANAGEMENT_CONSULTING,
    value: 'MGMC',
  },
  {
    label: lang.MARKETING_ADVERTISING,
    value: 'MARK',
  },
  {
    label: lang.MECHANICAL,
    value: 'MECH',
  },
  {
    label: lang.MEDIA,
    value: 'MED',
  },
  {
    label: lang.NGO,
    value: 'NGO',
  },
  {
    label: lang.OFFICE_MANAGEMENT,
    value: 'OFFM',
  },
  {
    label: lang.OIL_ENERGY,
    value: 'OIL',
  },
  {
    label: lang.OPERATIONS_MANAGEMENT,
    value: 'OPM',
  },
  {
    label: lang.OUTSOURCING_OFFSHORING,
    value: 'OUTS',
  },
  {
    label: lang.PHARMACEUTICALS,
    value: 'PHAR',
  },
  {
    label: lang.PRINTING_PUBLISHING,
    value: 'PRIN',
  },
  {
    label: lang.PROCESS_MANAGEMENT,
    value: 'PROC',
  },
  {
    label: lang.PRODUCTION,
    value: 'PROD',
  },
  {
    label: lang.PUBLIC_RELATIONS,
    value: 'PUBR',
  },
  {
    label: lang.PURCHASING,
    value: 'PURC',
  },
  {
    label: lang.QHSE,
    value: 'QHSE',
  },
  {
    label: lang.REAL_ESTATE,
    value: 'REST',
  },
  {
    label: lang.RESEARCH,
    value: 'RESE',
  },
  {
    label: lang.RESEARCH_DEVELOPMENT,
    value: 'RD',
  },
  {
    label: lang.RETAIL,
    value: 'RET',
  },
  {
    label: lang.SALES,
    value: 'SALE',
  },
  {
    label: lang.SECURITY,
    value: 'SEC',
  },
  {
    label: lang.SERVICES,
    value: 'SVC',
  },
  {
    label: lang.SOFTWARE_DEVELOPMENT,
    value: 'SOFT',
  },
  {
    label: lang.TECHNICAL_SUPPORT,
    value: 'TSUP',
  },
  {
    label: lang.TELECOMMUNICATIONS,
    value: 'TCOM',
  },
  {
    label: lang.TRAINING_DEVELOPMENT,
    value: 'TD',
  },
  {
    label: lang.TRANSPORT,
    value: 'TRAN',
  },
  {
    label: lang.VENTURE_CAPITAL,
    value: 'VCAP',
  },
  {
    label: lang.WAREHOUSING,
    value: 'WARE',
  },
];
