import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import { indexBy, prop } from 'ramda';
import { RuleObject } from 'rc-field-form/lib/interface';
import { v4 as uuid } from 'uuid';

/**
 * Generates a random integer from a given interval (inclusive)
 * @param min The minimum (inclusive)
 * @param max The maximum (inclusive)
 */
export function getRandomIntInclusive(min: number, max: number): number {
  const ceiledMin = Math.ceil(min);
  const flooredMax = Math.floor(max);

  return Math.floor(Math.random() * (flooredMax - ceiledMin + 1)) + ceiledMin;
}

export function numberWithPoints(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const blobToRcFile: (blob: Blob | File, fileName: string, uid?: string) => RcFile = (blob, fileName, uid) => {
  const file: any = new File([blob], fileName, { type: blob.type });

  file.uid = uid ?? fileName;

  return file as RcFile;
};

export const rcFileToUploadFile = (rcFile: RcFile, options: Partial<UploadFile>) => {
  const uploadFile = Object.assign(rcFile, options);

  return uploadFile;
};

export const getFileBlobFromUrl = (url: any) => fetch(url).then((r) => r.blob());

export const getUploadFileObjectFromUrl = (url: any, { fileName }: any) =>
  getFileBlobFromUrl(url).then((blobFile) =>
    rcFileToUploadFile(blobToRcFile(blobFile, fileName, uuid()), { url, status: 'done' })
  );

export const validateBirthDate: RuleObject['validator'] = async (rule, value) => {
  if (value && moment().isSameOrBefore(value, 'day')) throw new Error('Invalid Birth Date');
};

export function insertAndShift(arr: any[], from: number, to: number) {
  const arrCopy = [...arr];
  const cutOut = arrCopy.splice(from, 1)[0]; // cut the element at index 'from'
  arrCopy.splice(to, 0, cutOut); // insert it at index 'to'
  return arrCopy;
}

export const convertArrayToById: any = (arr: any) => indexBy(prop('id') as any, arr);

/**
 * Buils an array from the parts that build a template string and the corresponding variables.
 *
 * Example:
 *   * text: Please visit ${link} for more information
 *   * vars: { link: <a key="google" href="https://google.com">Google</a> } // this is JSX
 *   * returns: [ 'Please visit ', <a href="https://google.com">Google</a>, ' for more information' ]
 *
 * The returned array can be used in a JSX expression for rendering. We add the 'key' prop
 * because every node in an array must have a key in JSX.
 *
 * @param text template string
 * @param vars variables to replace in template string
 */
export function template(text: string, vars: { [key: string]: any }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function build(strings: string[], ...keys: any[]) {
    const items = [strings[0]];

    keys.forEach((key, index) => {
      items.push(key, strings[index + 1]);
    });

    return items;
  }

  const declarations = `const {${Object.keys(vars).join(',')}} = vars`;

  // eslint-disable-next-line no-eval
  return eval(`${declarations}; build\`${text}\`;`);
}
