import { I18n } from 'aws-amplify';
import * as React from 'react';

import { Page } from '../../components/Page';
import { TermsContent } from '../../components/TermsContent';
import { lang } from '../../i18n/lang';

import styles from './Terms.module.less';

export function Terms() {
  return (
    <Page
      type="public"
      browserTitle={I18n.get(lang.TERMS_OF_SERVICES)}
      title={I18n.get(lang.TERMS_OF_SERVICES)}
      layout="topCenter"
    >
      <TermsContent className={styles.content} />
    </Page>
  );
}
