import { InboxOutlined } from '@ant-design/icons';
import { Space, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import { lang } from '../../i18n/lang';
import { Label } from '../Label';

import styles from './FileInput.module.less';

const { Dragger } = Upload;

const disableUpload = () => false;

interface Props {
  accept?: string;
  disabled?: boolean;
  value?: Array<UploadFile>;
  multiple?: boolean;
  onChange?: (value: Array<UploadFile>) => void;
}

const InternalFileInput = ({ disabled, multiple, accept, onChange, value = [] }: Props) => {
  const handleChange = (info: UploadChangeParam) => {
    // if 'multiple' is 'false' keep only the last file in the list
    if (!multiple && info.fileList.length > 1) {
      info.fileList = info.fileList.slice(info.fileList.length - 1, info.fileList.length);
    }

    onChange && onChange(info.fileList);
  };

  return (
    <Space className={styles.container} size="large" direction="vertical">
      <div>
        <Label style={{ textAlign: 'center' }}>{I18n.get(lang.TITLE_SUPPORT_DOC)}</Label>
        <ReactMarkdown>{I18n.get(lang.PARAGRAPH_SUPPORT_DOC)}</ReactMarkdown>
      </div>
      <Dragger
        accept={accept}
        disabled={disabled}
        fileList={value ?? []}
        multiple={multiple}
        onChange={handleChange}
        beforeUpload={disableUpload}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{I18n.get(lang.CLICK_DRAG_FILE)}</p>
        {accept && (
          <p className="ant-upload-hint">
            {I18n.get(lang.ALLOWED_FILE_TYPE)}: {accept}
          </p>
        )}
      </Dragger>
    </Space>
  );
};

export const FileInput = React.memo(InternalFileInput);
