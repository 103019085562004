import { Select } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { useFixSelectTabIndex, useMaxItems } from '../../hooks';
import { useI18nStore } from '../../stores/i18n';
import { ComponentProps, SelectProps } from '../../typings';

import { items } from './shared';

type ValueType = string | string[];

interface Props extends ComponentProps, SelectProps<ValueType> {
  maxItems?: number;
  showSearch?: boolean;
  tabIndex?: number;
}

const InternalSectorSelect = ({
  style,
  maxItems,
  onChange,
  placeholder,
  showSearch = true,
  tabIndex,
  ...rest
}: Props) => {
  const isMultiple = maxItems !== 1;
  const handleChange = useMaxItems(maxItems, onChange);
  const locale = useI18nStore((store) => store.locale);
  const selectRef = useFixSelectTabIndex();

  const searchableItems = React.useMemo(
    () =>
      items.map(({ label, value }) => {
        const translatedLabel = I18n.get(label);
        return {
          key: `${translatedLabel} ${value}`,
          label: translatedLabel,
          value,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

  return (
    <div ref={selectRef}>
      <Select
        style={{ width: '100%', ...style }}
        options={searchableItems}
        placeholder={placeholder}
        mode={isMultiple ? 'multiple' : undefined}
        onChange={handleChange}
        optionFilterProp="key"
        showSearch={showSearch}
        tabIndex={tabIndex}
        {...rest}
      />
    </div>
  );
};

export const SectorSelect = React.memo(InternalSectorSelect);
