import composeRefs from '@seznam/compose-react-refs';
import { Button, Col, Row, Spin } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { config } from 'react-spring';
import { useAsync } from 'react-use';

import { lang } from '../../i18n/lang';
import { VideoAssets } from '../../typings';
import { GA } from '../../utils/ga';
import { VideoPreview } from '../VideoPreview';

import styles from './SwipyVideos.module.less';
import Carousel from './components/Carousel';

type VideoSlideProps = {
  videoId: string;
  isCurrentSlide: boolean;
  title: string;
  getVideoAssets: (videoId: string) => Promise<VideoAssets>;
};

function VideoSlideInternal({ videoId, isCurrentSlide, title, getVideoAssets, ...rest }: VideoSlideProps, ref: any) {
  const alreadyDisabled = React.useRef<boolean>(false);
  const videoPreviewRef = React.useRef<any>(null);
  const { loading, value: videoAssets } = useAsync(() => getVideoAssets(videoId), [videoId]);

  React.useEffect(() => {
    if (!isCurrentSlide && !alreadyDisabled.current && videoPreviewRef.current) {
      videoPreviewRef.current?.pause();
      alreadyDisabled.current = true;
    } else {
      alreadyDisabled.current = false;
    }
  }, [isCurrentSlide]);

  return (
    <div className={styles.videoSlideContainer}>
      {loading ? (
        <div className={styles.videoSlideLoader}>
          <Spin size="large" />
        </div>
      ) : (
        <VideoPreview
          ref={ref ? composeRefs(videoPreviewRef, ref) : videoPreviewRef}
          src={videoAssets?.mp4}
          poster={videoAssets?.thumbnail}
        />
      )}
      <div className={styles.videoSlideTitle}>{title}</div>
    </div>
  );
}

const VideoSlide = React.forwardRef(VideoSlideInternal);

export type VideoItem<Meta = any> = {
  key: string;
  id: string;
  title: string;
  meta?: Meta;
};

type SwipyVideosProps<VideoMeta = any> = {
  videos: VideoItem<VideoMeta>[];
  isApproveInProgress?: boolean;
  isRejectInProgress?: boolean;
  goTo?: number;
  onReject?: (video: VideoItem<VideoMeta>) => Promise<any>;
  onApprove?: (video: VideoItem<VideoMeta>) => Promise<any>;
  getVideoAssets: (videoId: string) => Promise<VideoAssets>;
};

export function SwipyVideos<VideoMeta>({
  videos,
  onReject,
  onApprove,
  isApproveInProgress = false,
  isRejectInProgress = false,
  goTo = 0,
  getVideoAssets,
}: SwipyVideosProps<VideoMeta>) {
  const carouselRef = React.useRef<any>();
  const currentSlideRef = React.useRef<any>(null);
  const [currentVideoObject, setCurrentVideoObject] = React.useState<any>(videos[0]);

  const enhancedSlides = React.useMemo(
    () =>
      videos.map(({ key, id: videoId, title, ...rest }, index) => ({
        id: videoId,
        key,
        content: ({ isCurrentSlide }: any) => (
          <VideoSlide
            title={title}
            videoId={videoId}
            isCurrentSlide={isCurrentSlide}
            {...(isCurrentSlide
              ? { ref: currentSlideRef }
              : {
                  style: { pointerEvents: 'none' },
                })}
            getVideoAssets={getVideoAssets}
          />
        ),
        ...rest,
      })),
    [getVideoAssets, videos]
  );

  const moveToPrevSlide = React.useCallback(() => {
    carouselRef.current?.moveSlide(-1);
  }, []);

  const moveToNextSlide = React.useCallback(() => {
    carouselRef.current?.moveSlide(1);
  }, []);

  const approve = React.useCallback(() => {
    onApprove && onApprove(currentVideoObject);
    GA.like();
  }, [currentVideoObject, onApprove]);

  const reject = React.useCallback(() => {
    onReject && onReject(currentVideoObject);
    GA.dislike();
  }, [currentVideoObject, onReject]);

  const keyboardListener = React.useCallback(
    (event) => {
      switch (event.keyCode) {
        case 32: {
          // Pause/Play video on spacebar key press
          if (currentSlideRef?.current?.player?.paused()) {
            currentSlideRef?.current?.player?.play();
          } else {
            currentSlideRef?.current?.player?.pause();
          }
          break;
        }
        case 37: {
          // Left arrow key press
          moveToPrevSlide();
          break;
        }
        case 39:
          // Right arrow key press
          moveToNextSlide();
          break;
      }
    },
    [moveToNextSlide, moveToPrevSlide]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', keyboardListener, false);

    return () => {
      document.removeEventListener('keydown', keyboardListener, false);
    };
  }, [keyboardListener]);

  const isLoading = isApproveInProgress || isRejectInProgress;

  return (
    <div className={styles.container}>
      <Carousel
        ref={carouselRef}
        slides={enhancedSlides}
        goToSlide={goTo}
        offsetRadius={1}
        showNavigation={true}
        animationConfig={config.gentle}
        onSwipedLeft={moveToNextSlide}
        onSwipedRight={moveToPrevSlide}
        infinit={false}
        onCurrentSlideChange={setCurrentVideoObject}
      />

      <Row className={styles.buttonsContainer} justify="center" align="middle">
        <Col>
          <Button size="large" type="primary" onClick={reject} loading={isRejectInProgress} disabled={isLoading}>
            {I18n.get(lang.NO_THANKS)}
          </Button>
        </Col>
        <Col style={{ maxWidth: 32 }} flex="auto"></Col>
        <Col>
          <Button size="large" type="primary" onClick={approve} loading={isApproveInProgress} disabled={isLoading}>
            {I18n.get(lang.YES_PLEASE)}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
