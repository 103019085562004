import { Select as AntSelect } from 'antd';
import type { SelectProps, SelectValue } from 'antd/lib/select';
import * as React from 'react';

import { useFixSelectTabIndex } from '../../hooks';

export function Select<T extends SelectValue = SelectValue>(props: SelectProps<T>) {
  const selectRef = useFixSelectTabIndex();

  return (
    <div ref={selectRef}>
      <AntSelect {...props} />
    </div>
  );
}
