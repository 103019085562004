import { Space } from 'antd';
import moment from 'moment';
import { range } from 'ramda';
import * as React from 'react';

import { ComponentProps } from '../../typings';

import { DateSelectFragment } from './components/DateSelectFragment';

const days = range(1, 32).map((num) => ({
  label: String('0' + num).slice(-2),
  value: String('0' + num).slice(-2),
}));

const months = range(1, 13).map((num) => ({
  label: String('0' + num).slice(-2),
  value: String('0' + num).slice(-2),
}));

const years = range(1900, Number(moment().format('YYYY')) + 1)
  .reverse()
  .map((num) => ({ label: num.toString(), value: num.toString() }));

interface Props extends ComponentProps {
  value?: moment.Moment;
  onChange?: (value?: moment.Moment) => void;
  tabIndex?: number;
}

function InternalDateSelect({ onChange, value, style, tabIndex, ...rest }: Props) {
  const [day, setDay] = React.useState<string | undefined>();
  const [month, setMonth] = React.useState<string | undefined>();
  const [year, setYear] = React.useState<string | undefined>();

  React.useEffect(() => {
    const day = value ? value.format('DD') : undefined;
    const month = value ? value.format('MM') : undefined;
    const year = value ? value.format('YYYY') : undefined;

    setDay(day);
    setMonth(month);
    setYear(year);
  }, [value]);

  const handleChange = (day?: string, month?: string, year?: string) => {
    // use Date() to correct overflows, e.g. 2020-09-31 becomes 2020-10-01 instead of failing
    const finalDate = year && month && day ? new Date(parseInt(year), parseInt(month) - 1, parseInt(day)) : undefined;
    const newValue = finalDate ? moment(finalDate) : undefined;

    setDay(newValue ? newValue.format('DD') : day);
    setMonth(newValue ? newValue.format('MM') : month);
    setYear(newValue ? newValue.format('YYYY') : year);

    const bothUndefined = newValue === undefined && value === undefined;
    const sameDate = newValue !== undefined && value !== undefined && newValue.isSame(value, 'day');

    if (onChange && !bothUndefined && !sameDate) {
      onChange(newValue);
    }
  };

  const handleDayChange = (day: string) => {
    handleChange(day, month, year);
  };

  const handleMonthChange = (month: string) => {
    handleChange(day, month, year);
  };

  const handleYearChange = (year: string) => {
    handleChange(day, month, year);
  };

  return (
    <Space size="small" {...rest}>
      <DateSelectFragment
        style={{ minWidth: 71 }}
        options={days}
        value={day}
        placeholder="DD"
        onChange={handleDayChange}
        tabIndex={tabIndex}
      />
      <DateSelectFragment
        style={{ minWidth: 71 }}
        options={months}
        value={month}
        placeholder="MM"
        onChange={handleMonthChange}
        tabIndex={tabIndex}
      />
      <DateSelectFragment
        style={{ minWidth: 87 }}
        options={years}
        value={year}
        placeholder="YYYY"
        onChange={handleYearChange}
        tabIndex={tabIndex}
      />
    </Space>
  );
}

export const DateSelect = React.memo(InternalDateSelect);
