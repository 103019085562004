import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuthStore } from '../stores/auth';
import { GA } from '../utils/ga';

/**
 * Hook for integration with Google Analytics. Should only be used once on the main
 * app component as it hooks into different othe hooks to automatically set gtag
 * values and send events.
 *
 * To send events in other components use the GA utility object.
 */
export function useGA() {
  const location = useLocation();
  const { user, userRole } = useAuthStore();

  useEffect(() => {
    // only count pages where the user remains longer than 1s
    // to avoid couting redirections, e.g. dahsboard -> wizard
    const timer = setTimeout(() => {
      GA.setPagePath(location.pathname);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [location.pathname]);

  useEffect(() => {
    GA.setUser(user, userRole);
  }, [user, userRole]);
}
