import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { useAuthStore } from '../stores/auth';
import { LOGIN_ROUTE, getRegistrationRouteByRole } from '../utils/constants';

interface Props extends RouteProps {
  redirectTo?: string;
  component: NonNullable<RouteProps['component']>;
}

export const PrivateRoute = ({ component, redirectTo = LOGIN_ROUTE, location, ...rest }: Props) => {
  const { isAuthenticated, loaded, userRole, profile, profileChecked } = useAuthStore((store) => store, shallow);
  const registrationRoute = userRole ? getRegistrationRouteByRole(userRole) : null;

  if (!loaded || (isAuthenticated && !profileChecked)) return null;

  const shouldRedirectToWizard =
    isAuthenticated && profileChecked && !profile && registrationRoute && location?.pathname !== registrationRoute;

  if (shouldRedirectToWizard) {
    return <Redirect to={registrationRoute as string} />;
  }

  return (
    <Route
      {...rest}
      location={location}
      render={(props) => {
        return isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect to={{ pathname: redirectTo, state: { from: location } }} />
        );
      }}
    />
  );
};
