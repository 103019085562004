import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import * as React from 'react';

import { setNotificationsAsRead } from '../graphql/mutations';
import { notificationsByCreatedAt } from '../graphql/queries';
import { convertArrayToById } from '../utils/helpers';

const LIST_NOTIFICATIONS_BY_CREATED_AT = gql(notificationsByCreatedAt);
const SET_NOTIFICATIONS_AS_READ = gql(setNotificationsAsRead);

export function useSetNotifsAsRead({ userId }: { userId?: string | null }) {
  const [setNotificationsAsRead] = useMutation(SET_NOTIFICATIONS_AS_READ);

  const setNotifsAsRead = React.useCallback(
    (ids) => {
      const notificationsQueryVariables = {
        type: 'Notification',
        sortDirection: 'DESC',
        filter: { userId: { eq: userId }, read: { ne: true } },
      };

      setNotificationsAsRead({
        variables: { ids },
        update: (proxy, { data: { setNotificationsAsRead } }) => {
          let globalUnreadNotifications: any = null;

          try {
            globalUnreadNotifications = proxy.readQuery({
              query: LIST_NOTIFICATIONS_BY_CREATED_AT,
              variables: notificationsQueryVariables,
            });
          } catch {}

          if (globalUnreadNotifications?.NotificationsByCreatedAt) {
            const oldNotifsById = convertArrayToById(globalUnreadNotifications?.NotificationsByCreatedAt?.items);

            for (const notif of setNotificationsAsRead) {
              delete oldNotifsById[notif.id];
            }

            proxy.writeQuery({
              query: LIST_NOTIFICATIONS_BY_CREATED_AT,
              variables: notificationsQueryVariables,
              data: {
                NotificationsByCreatedAt: {
                  ...globalUnreadNotifications.NotificationsByCreatedAt,
                  items: Object.values(oldNotifsById),
                },
              },
            });
          }

          let allNotifications: any = null;

          try {
            allNotifications = proxy.readQuery({
              query: LIST_NOTIFICATIONS_BY_CREATED_AT,
              variables: { ...notificationsQueryVariables, filter: { userId: { eq: userId } } },
            });
          } catch {}

          if (allNotifications?.NotificationsByCreatedAt) {
            const newNotifsById = convertArrayToById(setNotificationsAsRead);
            const notifsById = {
              ...convertArrayToById(allNotifications?.NotificationsByCreatedAt?.items),
              ...newNotifsById,
            };

            const notifs = [];

            for (const notif of allNotifications?.NotificationsByCreatedAt?.items) {
              notifs.push(notifsById[notif.id]);
            }

            proxy.writeQuery({
              query: LIST_NOTIFICATIONS_BY_CREATED_AT,
              variables: { ...notificationsQueryVariables, filter: { userId: { eq: userId } } },
              data: {
                NotificationsByCreatedAt: {
                  ...allNotifications.NotificationsByCreatedAt,
                  items: notifs,
                },
              },
            });
          }

          return proxy;
        },
      });
    },
    [setNotificationsAsRead, userId]
  );

  return setNotifsAsRead;
}
