import { notification } from 'antd';
import { Auth, I18n } from 'aws-amplify';
import { useEffect } from 'react';

import { lang } from '../i18n/lang';
import { useAuthStore } from '../stores/auth';

export function useCheckAuth() {
  const isAuthenticated = useAuthStore(({ isAuthenticated }) => isAuthenticated);
  const signOut = useAuthStore(({ signOut }) => signOut);

  useEffect(() => {
    async function checkAuth() {
      try {
        await Auth.currentSession();

        if (!isAuthenticated) {
          document.location.reload();
        }
      } catch (err) {
        if (isAuthenticated) {
          notification.info({
            message: I18n.get(lang.NOT_AUTH_INFO_TITLE),
            description: I18n.get(lang.NOT_AUTH_INFO_MESSAGE),
            duration: 10,
          });

          signOut();
        }
      }
    }

    window.addEventListener('focus', checkAuth);

    return () => window.removeEventListener('focus', checkAuth);
  }, [isAuthenticated, signOut]);
}
