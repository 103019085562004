import { useMutation, useQuery } from '@apollo/react-hooks';
import { Spin, message } from 'antd';
import { gql } from 'apollo-boost';
import type { MutationUpdaterFn } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { shallow } from 'zustand/shallow';

import { Page } from '../../components/Page';
import { StartupSearchForm } from '../../components/StartupSearchForm';
import { updateStartupSearch } from '../../graphql/mutations';
import { getStartupSearch, listStartupSearchs } from '../../graphql/queries';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES, investorRole } from '../../utils/constants';

const UPDATE_STARTUP_SEARCH = gql(updateStartupSearch);
export const LIST_STARTUP_SEARCHES = gql(listStartupSearchs);
const GET_STARTUP_SEARCH = gql(getStartupSearch);

export const UpdateStartupSearch = () => {
  const { user, userRole } = useAuthStore((store) => ({ ...store }), shallow);
  const { id: searchId } = useParams<{ id: string }>();

  const { loading: loadingSearchData, data: searchData } = useQuery(GET_STARTUP_SEARCH, {
    variables: { id: searchId },
  });

  const investorId = user?.attributes.sub;

  // FIXME: Fix missing __typename on cache update
  const updateSearch: MutationUpdaterFn<any> = React.useCallback(
    (proxy, { data: { updateStartupSearch } }) => {
      try {
        const { listStartupSearchs }: any = proxy.readQuery({
          query: LIST_STARTUP_SEARCHES,
          variables: { filter: { employerId: { eq: investorId } } },
        });

        const searchIndex = listStartupSearchs.items.findIndex((search: any) => search.id === updateStartupSearch.id);

        const searches = [...listStartupSearchs.items];
        searches[searchIndex] = updateStartupSearch;

        proxy.writeQuery({
          query: LIST_STARTUP_SEARCHES,
          data: { listStartupSearchs: { items: searches } },
          variables: { filter: { investorId: { eq: investorId } } },
        });
      } catch {}

      proxy.writeQuery({
        query: GET_STARTUP_SEARCH,
        variables: { id: searchId },
        data: { getStartupSearch: updateStartupSearch },
      });

      return proxy;
    },
    [investorId, searchId]
  );

  const [updateStartupSearch, { data }] = useMutation(UPDATE_STARTUP_SEARCH, { update: updateSearch });

  const onFinish = React.useCallback(
    async (values: any) => {
      const mutationProps = {
        variables: {
          input: {
            ...values,
            id: searchId,
            investorId,
            desiredFunding: null, // support for desired funding removed, so always set to null
          },
        },
      };

      try {
        await updateStartupSearch(mutationProps);
        message.success(I18n.get(lang.SUCCESS_SEARCH_SAVED));
      } catch {
        message.error(I18n.get(lang.ERROR_SEARCH_SAVED));
      }
    },
    [searchId, investorId, updateStartupSearch]
  );

  const [{ loading }, handleSubmit] = useAsyncFn(onFinish, [updateStartupSearch, investorId]);

  if (userRole !== investorRole) return <Redirect to={APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE} />;

  if (data?.updateStartupSearch?.id) {
    return <Redirect to={`${APP_NAVIGATION_ROUTES.STARTUPS_SEARCH_ROUTE}/${data?.updateStartupSearch?.id}`} />;
  }

  if (loadingSearchData) return <Spin />;

  const searchName = searchData?.getStartupSearch?.name;

  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.UPDATE_SEARCH)}
      title={I18n.get(lang.UPDATE_SEARCH)}
      subTitle={searchName || I18n.get(lang.LOADING)}
    >
      <StartupSearchForm
        onFinish={handleSubmit}
        loading={loading}
        initialValues={{
          name: searchName,
          locations: searchData?.getStartupSearch?.locations,
          sectors: searchData?.getStartupSearch?.sectors,
        }}
      />
    </Page>
  );
};
