import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { Page } from '../../components/Page';
import { RegisterWizard } from '../../components/RegisterWizard';
import { createProfileStartup } from '../../graphql/mutations';
import { lang } from '../../i18n/lang';

import { PersonalInfoStartup } from './components/PersonalInfoStartup';
import { QuestionnaireStartup } from './components/QuestionnaireStartup';

const CREATE_STARTUP_PROFILE = gql(createProfileStartup);

export const RegisterStartup = () => {
  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.TITLE_REGISTER_STARTUP)}
      title={I18n.get(lang.TITLE_REGISTER_STARTUP)}
      subTitle={I18n.get(lang.SUBTITLE_STARTUP_CARD)}
      sideMenu={false}
      layout="full"
      logoutButton
    >
      <RegisterWizard
        createProfileMutation={CREATE_STARTUP_PROFILE}
        dataSelector="createProfileStartup"
        personalInfoComponent={PersonalInfoStartup}
        questionnaireComponent={QuestionnaireStartup}
        videInputMessages={{
          uploadTitle: I18n.get(lang.VIDEO_UPLOAD_TITLE_JOB_SEEKER),
          uploadDescription: I18n.get(lang.VIDEO_UPLOAD_DESCRIPTION_JOB_SEEKER),
          recordTitle: I18n.get(lang.VIDEO_RECORD_TITLE_JOB_SEEKER),
        }}
      />
    </Page>
  );
};
