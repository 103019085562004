import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

import styles from './Message.module.less';

export const Message = ({ isMe, text, createdAt, read }: any) => {
  return (
    <div className={styles.containerMessage}>
      <div className={classNames(isMe ? styles.myMessage : styles.guestMessage, { [styles.unreadMessage]: !read })}>
        {text}
      </div>
      <div className={styles.date}>{moment(createdAt).format('lll')}</div>
    </div>
  );
};
