import { HomeOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { I18n } from 'aws-amplify';
import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import ElevidLogo from '../../assets/elevid-logo-header.svg';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { useI18nStore } from '../../stores/i18n';
import { APP_NAVIGATION_ROUTES } from '../../utils/constants';
import { LocaleSelect } from '../LocaleSelect';

import styles from './Header.module.less';

interface Props {
  bigLogo?: boolean;
  showLocaleSelect?: boolean;
  showLoginButton?: boolean;
  showLogoutButton?: boolean;
  showRegisterButton?: boolean;
  showHomeIcon?: boolean;
}

export const Header = React.memo(
  ({
    bigLogo = false,
    showLocaleSelect = false,
    showLoginButton = false,
    showLogoutButton = false,
    showRegisterButton = false,
    showHomeIcon = false,
  }: Props) => {
    useI18nStore(); // Force re-render on locale change

    const signOut = useAuthStore((store) => store.signOut);

    return (
      <Row className={styles.container} justify="start" align="bottom">
        {bigLogo || showHomeIcon ? (
          <Col>
            {bigLogo ? (
              <Link to={APP_NAVIGATION_ROUTES.HOME_ROUTE}>
                <img
                  src={ElevidLogo}
                  className={classNames(styles.brand, { [styles.bigBrand]: bigLogo })}
                  alt="elevid"
                />
              </Link>
            ) : null}
            {showHomeIcon ? (
              <Link to={APP_NAVIGATION_ROUTES.HOME_ROUTE}>
                <HomeOutlined className={styles.homeIconStyling} />
              </Link>
            ) : null}
          </Col>
        ) : null}

        {showLocaleSelect || showLoginButton || showLogoutButton || showRegisterButton ? (
          <Col className={styles.colRight}>
            <Space direction="vertical">
              {showLocaleSelect ? (
                <div className={styles.locale}>
                  <LocaleSelect />
                </div>
              ) : null}
              {showLoginButton ? (
                <Link to={APP_NAVIGATION_ROUTES.LOGIN_ROUTE} className={styles.topLink}>
                  <Typography.Text className={styles.note}>{I18n.get(lang.ALREADY_HAVE_ACCOUNT)}</Typography.Text>
                  <Button className={styles.registerBtn} size="large" type="primary">
                    {I18n.get(lang.LOGIN)}
                  </Button>
                </Link>
              ) : null}
              {showLogoutButton ? (
                <Button style={{ width: '100%' }} size="large" type="primary" onClick={signOut}>
                  {I18n.get(lang.LOGOUT)}
                </Button>
              ) : null}
            </Space>
          </Col>
        ) : null}
      </Row>
    );
  }
);
