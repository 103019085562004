export enum Locale {
  EN = 'en',
  FR = 'fr',
}

export const SUPPORTED_LOCALES = Object.values(Locale) as string[];

export function isSupportedLocale(value?: string | null): value is Locale {
  return !!value && SUPPORTED_LOCALES.indexOf(value) >= 0;
}
