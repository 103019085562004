/* eslint-disable no-template-curly-in-string */
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { message } from 'antd';
import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { notificationsByCreatedAt } from '../graphql/queries';
import { onCreateNotification } from '../graphql/subscriptions';
import { lang } from '../i18n/lang';
import { Notification, Roles } from '../typings';
import { processNotification } from '../utils/notifications';

import { useSetNotifsAsRead } from './useSetNotifsAsRead';

const LIST_NOTIFICATIONS_BY_CREATED_AT = gql(notificationsByCreatedAt);
const ON_CREATE_NOTIFICATION = gql(onCreateNotification);

export function useNotifications(profile: any, userRole: Roles | null) {
  const userId = profile?.userId;

  const notificationsQueryVariables = React.useMemo(
    () => ({
      type: 'Notification',
      sortDirection: 'DESC',
      filter: { userId: { eq: userId }, read: { ne: true } },
    }),
    [userId]
  );

  const { data } = useQuery(LIST_NOTIFICATIONS_BY_CREATED_AT, {
    variables: notificationsQueryVariables,
    skip: !userId,
  });

  useSubscription(ON_CREATE_NOTIFICATION, {
    variables: { userId },
    skip: !userId,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const newNotification = subscriptionData.data.onCreateNotification;

      message.info(I18n.get(lang.NEW_NOTIFIATION_MESSAGE));

      let globalUnreadNotifications: any = null;

      try {
        globalUnreadNotifications = client.readQuery({
          query: LIST_NOTIFICATIONS_BY_CREATED_AT,
          variables: notificationsQueryVariables,
        });
      } catch {}

      if (globalUnreadNotifications?.NotificationsByCreatedAt) {
        client.writeQuery({
          query: LIST_NOTIFICATIONS_BY_CREATED_AT,
          variables: notificationsQueryVariables,
          data: {
            NotificationsByCreatedAt: {
              ...globalUnreadNotifications.NotificationsByCreatedAt,
              items: [newNotification, ...globalUnreadNotifications.NotificationsByCreatedAt.items],
            },
          },
        });
      }

      let allNotifications: any = null;

      try {
        allNotifications = client.readQuery({
          query: LIST_NOTIFICATIONS_BY_CREATED_AT,
          variables: { ...notificationsQueryVariables, filter: { userId: { eq: userId } } },
        });
      } catch {}

      if (allNotifications?.NotificationsByCreatedAt) {
        client.writeQuery({
          query: LIST_NOTIFICATIONS_BY_CREATED_AT,
          variables: { ...notificationsQueryVariables, filter: { userId: { eq: userId } } },
          data: {
            NotificationsByCreatedAt: {
              ...allNotifications.NotificationsByCreatedAt,
              items: [newNotification, ...allNotifications.NotificationsByCreatedAt.items],
            },
          },
        });
      }
    },
  });

  const setNotifsAsRead = useSetNotifsAsRead({ userId });
  const notifications = data?.NotificationsByCreatedAt?.items ?? [];

  const numberOfUnreadNotifications = notifications.reduce(
    (count: number, notification: Notification) => count + processNotification(notification, userRole).length,
    0
  );

  return {
    notifications,
    setNotifsAsRead,
    numberOfUnreadNotifications,
  };
}
