import { MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { FormProps } from 'antd/lib/form';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { lang } from '../../../i18n/lang';

interface Props extends FormProps {
  loading: boolean;
}

export function SignupForm({ loading, ...rest }: Props) {
  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: I18n.get(lang.IS_REQUIRED),
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      email: I18n.get(lang.IS_NOT_VALID),
    },
  };
  return (
    <Form validateMessages={validateMessages} {...rest}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: I18n.get(lang.VALIDATOR_MSG_EMAIL),
          },
          {
            type: 'email',
            message: I18n.get(lang.VALIDATOR_MSG_EMAIL_INVALID),
          },
        ]}
      >
        <Input
          placeholder={I18n.get(lang.EMAIL)}
          className="login-input"
          size="large"
          suffix={<MailOutlined className="site-form-item-icon" />}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: I18n.get(lang.VALIDATOR_MSG_PASSWORD),
          },
          {
            min: 8,
            message: I18n.get(lang.VALIDATOR_MSG_PASSWORD_LEN),
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder={I18n.get(lang.PASSWORD)} className="login-input" size="large" />
      </Form.Item>
      <Form.Item className="text-center">
        <Row gutter={16}>
          <Col span={24} style={{ marginTop: '0.8rem' }}>
            <Button
              style={{ width: '100%' }}
              type="primary"
              loading={loading}
              htmlType="submit"
              className="login-btn"
              size="large"
            >
              {I18n.get(lang.REGISTER)}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
}
