import { Form } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { lang } from '../../../i18n/lang';
import { VideoInput } from '../../VideoInput';

export function Video(props: any) {
  return (
    <Form.Item name="videoRec" rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_VIDEO) }]}>
      <VideoInput {...props} />
    </Form.Item>
  );
}
