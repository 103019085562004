import { BellFilled, DashboardFilled, IdcardFilled, LogoutOutlined, MessageFilled } from '@ant-design/icons';
import { Menu as AntdMenu, Badge } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { lang } from '../../../../i18n/lang';
import { useAuthStore } from '../../../../stores/auth';
import { APP_NAVIGATION_ROUTES } from '../../../../utils/constants';

import styles from './Menu.module.less';

enum MenuKey {
  DASHBOARD = 'dashboard',
  NOTIFICATIONS = 'notifications',
  CHAT = 'chat',
  PROFILE = 'profile',
}

interface Props {
  show?: boolean;
  numberOfUnreadMessages: number;
  numberOfUnreadNotifications: number;
}

const ROUTES: { [key in MenuKey]: string } = {
  [MenuKey.DASHBOARD]: APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE,
  [MenuKey.NOTIFICATIONS]: APP_NAVIGATION_ROUTES.NOTIFICATIONS_ROUTE,
  [MenuKey.CHAT]: APP_NAVIGATION_ROUTES.DEFAULT_CHAT_ROUTE,
  [MenuKey.PROFILE]: APP_NAVIGATION_ROUTES.PROFILE_ROUTE,
};

const EXTRA_ROUTES: { [key in MenuKey]: string[] } = {
  [MenuKey.DASHBOARD]: [APP_NAVIGATION_ROUTES.JOBSEEKERS_SEARCH_ROUTE, APP_NAVIGATION_ROUTES.STARTUPS_SEARCH_ROUTE],
  [MenuKey.NOTIFICATIONS]: [],
  [MenuKey.CHAT]: [],
  [MenuKey.PROFILE]: [],
};

export function Menu({ show = true, numberOfUnreadMessages, numberOfUnreadNotifications }: Props) {
  const history = useHistory();
  const location = useLocation();
  const { signOut: handleSignOut } = useAuthStore((store) => store, shallow);

  const handleMenuClick = ({ key }: { key: string }) => {
    const route = ROUTES[key as MenuKey];

    if (route) {
      history.push(route);
    }
  };

  const selectedKeys = React.useMemo(() => {
    return Object.keys(ROUTES).filter((key) => {
      return [ROUTES[key as MenuKey], ...EXTRA_ROUTES[key as MenuKey]].some((route) =>
        location.pathname.startsWith(route)
      );
    });
  }, [location.pathname]);

  return show ? (
    <AntdMenu className={styles.container} mode="inline" selectedKeys={selectedKeys} onClick={handleMenuClick}>
      <AntdMenu.Item key={MenuKey.DASHBOARD} icon={<DashboardFilled />} title="">
        {I18n.get(lang.DASHBOARD)}
      </AntdMenu.Item>

      <AntdMenu.Item
        key={MenuKey.NOTIFICATIONS}
        icon={
          <Badge className={styles.iconBadge} size="small" count={numberOfUnreadNotifications} overflowCount={99}>
            <BellFilled />
          </Badge>
        }
        title=""
      >
        <Badge className={styles.labelBadge} count={numberOfUnreadNotifications} overflowCount={99}>
          {I18n.get(lang.TITLE_NOTIFICATONS)}
        </Badge>
      </AntdMenu.Item>

      <AntdMenu.Item
        key={MenuKey.CHAT}
        icon={
          <Badge className={styles.iconBadge} size="small" count={numberOfUnreadMessages} overflowCount={99}>
            <MessageFilled />
          </Badge>
        }
        title=""
      >
        <Badge className={styles.labelBadge} count={numberOfUnreadMessages} overflowCount={99}>
          {I18n.get(lang.MESSENGER)}
        </Badge>
      </AntdMenu.Item>

      <AntdMenu.Item key={MenuKey.PROFILE} icon={<IdcardFilled />} title="">
        {I18n.get(lang.PROFILE)}
      </AntdMenu.Item>

      <AntdMenu.Item key="sign-out" onClick={handleSignOut} icon={<LogoutOutlined />} title="">
        {I18n.get(lang.LOGOUT)}
      </AntdMenu.Item>
    </AntdMenu>
  ) : null;
}
