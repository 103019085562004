import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { Page } from '../../components/Page';
import { RegisterWizard } from '../../components/RegisterWizard';
import { createProfileJobSeeker } from '../../graphql/mutations';
import { lang } from '../../i18n/lang';

import { PersonalInfoJobSeeker } from './components/PersonalInfoJobSeeker';
import { QuestionnaireJobSeeker } from './components/QuestionnaireJobSeeker';

const CREATE_JOBSEEKER_PROFILE = gql(createProfileJobSeeker);

export const RegisterJobSeeker = () => {
  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.TITLE_REGISTER_JOBSEEKER)}
      title={I18n.get(lang.TITLE_REGISTER_JOBSEEKER)}
      subTitle={I18n.get(lang.SUBTITLE_JOB_SEEKER_CARD)}
      sideMenu={false}
      layout="full"
      logoutButton
    >
      <RegisterWizard
        createProfileMutation={CREATE_JOBSEEKER_PROFILE}
        dataSelector="createProfileJobSeeker"
        personalInfoComponent={PersonalInfoJobSeeker}
        questionnaireComponent={QuestionnaireJobSeeker}
        videInputMessages={{
          uploadTitle: I18n.get(lang.VIDEO_UPLOAD_TITLE_JOB_SEEKER),
          uploadDescription: I18n.get(lang.VIDEO_UPLOAD_DESCRIPTION_JOB_SEEKER),
          recordTitle: I18n.get(lang.VIDEO_RECORD_TITLE_JOB_SEEKER),
        }}
      />
    </Page>
  );
};
