import type { GraphQLResult } from '@aws-amplify/api';
import type { HubCapsule } from '@aws-amplify/core';
import { API, Auth, Hub, graphqlOperation } from 'aws-amplify';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import {
  GetProfileEmployerQuery,
  GetProfileInvestorQuery,
  GetProfileJobSeekerQuery,
  GetProfileStartupQuery,
} from '../api/elevid';
import { getProfileEmployer, getProfileInvestor, getProfileJobSeeker, getProfileStartup } from '../graphql/queries';
import { useAuthStore } from '../stores/auth';
import { useI18nStore } from '../stores/i18n';
import { Profile, Roles } from '../typings/auth';
import { cognitoUserRoles, getRegistrationRouteByRole } from '../utils/constants';
import { captureException } from '../utils/sentry';

const getUserProfile = async ({ userId, role }: { userId: string; role: Roles }) => {
  switch (role) {
    case 'Employers': {
      const response = (await API.graphql(
        graphqlOperation(getProfileEmployer, { userId })
      )) as GraphQLResult<GetProfileEmployerQuery>;

      return response.data?.getProfileEmployer;
    }
    case 'Investors': {
      const response = (await API.graphql(
        graphqlOperation(getProfileInvestor, { userId })
      )) as GraphQLResult<GetProfileInvestorQuery>;

      return response.data?.getProfileInvestor;
    }
    case 'JobSeekers': {
      const response = (await API.graphql(
        graphqlOperation(getProfileJobSeeker, { userId })
      )) as GraphQLResult<GetProfileJobSeekerQuery>;

      return response.data?.getProfileJobSeeker;
    }
    case 'Startups': {
      const response = (await API.graphql(
        graphqlOperation(getProfileStartup, { userId })
      )) as GraphQLResult<GetProfileStartupQuery>;

      return response.data?.getProfileStartup;
    }
    default:
      captureException(new Error(`Unknown user role '${role}' for user id '${userId}'`));

      return null;
  }
};

export function useAuthListener() {
  const history = useHistory();
  const {
    loaded,
    isAuthenticated,
    profile,
    setUser,
    setLoaded,
    setProfile,
    profileChecked,
    setProfileChecked,
    userRole,
  } = useAuthStore((store) => ({ ...store }), shallow);

  const setLocale = useI18nStore((store) => store.setLocale);

  React.useEffect(() => {
    let isMounted = true;

    const updateUser = async (authState?: HubCapsule) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const credentials = await Auth.currentUserCredentials();

        if (user && credentials) {
          user.identityId = credentials.identityId;
        }

        if (isMounted) {
          setUser(user);

          if (user.attributes.locale) {
            setLocale(user.attributes.locale);
          }
        }

        const isLogin = isAuthenticated === false && user;

        if (isLogin || !profile) {
          const role = user?.signInUserSession?.accessToken?.payload['cognito:groups']?.find((role: string) =>
            cognitoUserRoles.includes(role)
          );
          const userId = user.attributes.sub;

          if (role && isMounted) {
            const fetchedProfile = await getUserProfile({ userId, role });

            if (isMounted) {
              setProfile(fetchedProfile as Profile);
            }

            if (!fetchedProfile) {
              history.push({
                pathname: getRegistrationRouteByRole(role),
              });
            }

            if (isMounted) {
              setProfileChecked(true);
            }
          }
        }
      } catch (err) {
        if (typeof err !== 'string' || err !== 'The user is not authenticated') {
          captureException(err);
        }

        if (isMounted) {
          setUser(null);
          setProfile(null);
          setProfileChecked(false);
        }
      }

      if (isMounted) {
        setLoaded(true);
      }

      if (authState?.payload.event === 'signOut') {
        if (isMounted) {
          setUser(null);
          setProfile(null);
          setProfileChecked(false);
        }
      }
    };

    Hub.listen('auth', updateUser); // listen for login/signup events

    updateUser(); // check manually the first time because we won't get a Hub event

    return () => {
      isMounted = false;
      Hub.remove('auth', updateUser);
    }; // cleanup
  }, [history, isAuthenticated, profile, setLoaded, setLocale, setProfile, setProfileChecked, setUser]);

  return { loaded, isAuthenticated, profile, profileChecked, userRole };
}
