import { Col, Form, Grid, Input, Row } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { DateSelect } from '../../../../components/DateSelect';
import { PhoneInput } from '../../../../components/PhoneInput';
import { lang } from '../../../../i18n/lang';
import { validateBirthDate } from '../../../../utils/helpers';

import styles from './PersonalInfoInvestor.module.less';

const { useBreakpoint } = Grid;

export const PersonalInfoInvestor = () => {
  const notif = useBreakpoint();
  return (
    <Row justify="space-around">
      <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol} order={notif.md === true ? 1 : 1}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_FIRST_NAME)}</b>}
          name="firstName"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_FIRST_NAME) }]}
        >
          <Input tabIndex={1} />
        </Form.Item>
      </Col>
      <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol} order={notif.md === true ? 2 : 4}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_COMPANY_NAME_OPTIONAL)}</b>}
          name="companyName"
        >
          <Input tabIndex={4} />
        </Form.Item>
      </Col>
      <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol} order={notif.md === true ? 3 : 2}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_LAST_NAME)}</b>}
          name="lastName"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LAST_NAME) }]}
        >
          <Input tabIndex={2} />
        </Form.Item>
      </Col>
      <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol} order={notif.md === true ? 4 : 5}>
        <Form.Item labelCol={{ span: 24 }} label={<b>{I18n.get(lang.LABEL_PHONE)}</b>} name="phone">
          <PhoneInput tabIndex={5} />
        </Form.Item>
      </Col>
      <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol} order={notif.md === true ? 5 : 3}>
        <Form.Item
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_BIRTH_DATE)}</b>}
          name="birthDate"
          rules={[
            { required: true, message: I18n.get(lang.VALIDATOR_MSG_BIRTH_DATE) },
            { validator: validateBirthDate },
          ]}
        >
          <DateSelect tabIndex={3} />
        </Form.Item>
      </Col>
      {/* This empty col is the best thing to do with flexbox to keep the alignment convenient in this case */}
      <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol} order={6} />
    </Row>
  );
};
