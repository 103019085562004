import { I18n } from 'aws-amplify';
import * as React from 'react';

import { Page } from '../../components/Page';
import { PrivacyPolicyContent } from '../../components/PrivacyPolicyContent';
import { lang } from '../../i18n/lang';

import styles from './PrivacyPolicy.module.less';

export function PrivacyPolicy() {
  return (
    <Page
      type="public"
      browserTitle={I18n.get(lang.PRIVACY_POLICY)}
      title={I18n.get(lang.PRIVACY_POLICY)}
      layout="topCenter"
    >
      <PrivacyPolicyContent className={styles.content} />
    </Page>
  );
}
