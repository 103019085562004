import { MailOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { FormProps } from 'antd/lib/form';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { lang } from '../../../i18n/lang';
import { APP_NAVIGATION_ROUTES } from '../../../utils/constants';

interface Props extends FormProps {
  loading: boolean;
}

export function LoginFrom({ loading, ...rest }: Props) {
  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: I18n.get(lang.IS_REQUIRED),
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      email: I18n.get(lang.IS_NOT_VALID),
    },
  };
  return (
    <Form layout="vertical" validateMessages={validateMessages} {...rest}>
      <Form.Item
        label={I18n.get(lang.EMAIL)}
        name="email"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: I18n.get(lang.VALIDATOR_MSG_EMAIL),
          },
          {
            type: 'email',
            message: I18n.get(lang.VALIDATOR_MSG_EMAIL_INVALID),
          },
        ]}
      >
        <Input className="login-input" size="large" suffix={<MailOutlined className="site-form-item-icon" />} />
      </Form.Item>
      <Form.Item
        label={I18n.get(lang.PASSWORD)}
        name="password"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: I18n.get(lang.VALIDATOR_MSG_PASSWORD),
          },
        ]}
        hasFeedback
      >
        <Input.Password className="login-input" size="large" />
      </Form.Item>
      <Form.Item className="text-center">
        <Space style={{ width: '100%' }} direction="vertical">
          <Row justify="space-around" align="middle">
            <Col span={16}>
              <Button type="primary" loading={loading} htmlType="submit" className="login-btn" size="large">
                {I18n.get(lang.LOGIN)}
              </Button>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <small>
                <Link to={APP_NAVIGATION_ROUTES.FORGOT_PASSWORD_ROUTE}>{I18n.get(lang.FORGOT_PASSWORD_LOGIN)}</Link>
              </small>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <small>
                <Typography.Text className="primary-text">{I18n.get(lang.NO_ACCOUNT)}</Typography.Text>
                <Link to={APP_NAVIGATION_ROUTES.HOME_ROUTE}>
                  <strong>{I18n.get(lang.CREATE_ACCOUNT)}</strong>
                </Link>
              </small>
            </Col>
          </Row>
        </Space>
      </Form.Item>
    </Form>
  );
}
