import { I18n } from 'aws-amplify';
import { getUserLocales } from 'get-user-locale';
import moment from 'moment';

import { Locale, SUPPORTED_LOCALES, isSupportedLocale } from '../typings/i18n';

import { dict } from './dict';

import 'moment/locale/fr';

const LOCAL_STORAGE_KEY = 'elevid_preferred_locale';

I18n.putVocabularies(dict);

export function setLocale(locale: Locale | null = getLocaleFromLocalStorage()): Locale {
  if (!locale || !isSupportedLocale(locale)) {
    const userLocale = getUserLocales().find(
      (locale) => SUPPORTED_LOCALES.indexOf(locale.toLowerCase().substring(0, 2)) >= 0
    );

    locale = (userLocale ? userLocale.toLowerCase().substring(0, 2) : SUPPORTED_LOCALES[0]) as Locale;
  }

  I18n.setLanguage(locale);
  moment.locale(locale);

  return locale;
}

export function getLocaleFromLocalStorage() {
  let locale: Locale | null = null;

  if (localStorage) {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (isSupportedLocale(value)) {
      locale = value;
    }
  }

  return locale;
}

export function saveLocaleToLocalStorage(locale: Locale) {
  if (localStorage && locale) {
    localStorage.setItem(LOCAL_STORAGE_KEY, locale);
  }
}
