import { produce } from 'immer';
import { compose, identity } from 'ramda';
import { create } from 'zustand';
import type { StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';

const isDevEnv = process.env.NODE_ENV === 'development';

const immer = (config: Function) => (set: Function, get: Function, api: StoreApi<Object>) =>
  config((fn: any) => set(produce(fn)), get, api);

// TODO: Try to fix typings
export const storeDecorator: Function = compose(
  create as any,
  (isDevEnv ? devtools : identity) as (x: Function) => Function,
  immer
);
