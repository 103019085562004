import { Col, Form, Grid, Input, Row } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { DateSelect } from '../../../../components/DateSelect';
import { PhoneInput } from '../../../../components/PhoneInput';
import { lang } from '../../../../i18n/lang';
import { validateBirthDate } from '../../../../utils/helpers';

const { useBreakpoint } = Grid;

export const PersonalInfoStartup = () => {
  const notif = useBreakpoint();
  return (
    <Row justify="space-around" style={{ padding: '0 24px' }}>
      <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 1 : 1}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_COMPANY_NAME)}</b>}
          name="companyName"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_COMPANY_NAME) }]}
        >
          <Input tabIndex={1} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 2 : 4}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_JOB_TITLE)}</b>}
          name="jobTitle"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_JOB_TITLE) }]}
        >
          <Input tabIndex={4} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 3 : 2}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_FIRST_NAME)}</b>}
          name="firstName"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_FIRST_NAME) }]}
        >
          <Input tabIndex={2} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 4 : 5}>
        <Form.Item labelCol={{ span: 24 }} label={<b>{I18n.get(lang.LABEL_PHONE)}</b>} name="phone">
          <PhoneInput tabIndex={5} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 5 : 3}>
        <Form.Item
          hasFeedback
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_LAST_NAME)}</b>}
          name="lastName"
          rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LAST_NAME) }]}
        >
          <Input tabIndex={3} />
        </Form.Item>
      </Col>
      <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 6 : 6}>
        <Form.Item
          labelCol={{ span: 24 }}
          label={<b>{I18n.get(lang.LABEL_BIRTH_DATE)}</b>}
          name="birthDate"
          rules={[
            { required: true, message: I18n.get(lang.VALIDATOR_MSG_BIRTH_DATE) },
            { validator: validateBirthDate },
          ]}
        >
          <DateSelect tabIndex={6} />
        </Form.Item>
      </Col>
    </Row>
  );
};
