import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { Page } from '../../components/Page';
import { RegisterWizard } from '../../components/RegisterWizard';
import { createProfileInvestor } from '../../graphql/mutations';
import { lang } from '../../i18n/lang';

import { PersonalInfoInvestor } from './components/PersonalInfoInvestor';
import { QuestionnaireInvestor } from './components/QuestionnaireInvestor';

const CREATE_INVESTOR_PROFILE = gql(createProfileInvestor);

export const RegisterInvestor = () => {
  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.TITLE_REGISTER_INVESTOR)}
      title={I18n.get(lang.TITLE_REGISTER_INVESTOR)}
      subTitle={I18n.get(lang.SUBTITLE_INVESTOR_CARD)}
      sideMenu={false}
      layout="full"
      logoutButton
    >
      <RegisterWizard
        createProfileMutation={CREATE_INVESTOR_PROFILE}
        dataSelector="createProfileInvestor"
        personalInfoComponent={PersonalInfoInvestor}
        questionnaireComponent={QuestionnaireInvestor}
        videInputMessages={{
          uploadTitle: I18n.get(lang.VIDEO_UPLOAD_TITLE_EMPLOYER),
          uploadDescription: I18n.get(lang.VIDEO_UPLOAD_DESCRIPTION_EMPLOYER),
          recordTitle: I18n.get(lang.VIDEO_RECORD_TITLE_EMPLOYER),
        }}
      />
    </Page>
  );
};
