/* eslint-disable no-template-curly-in-string */

import { VideoCameraOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { lang } from '../../../i18n/lang';
import { ComponentProps } from '../../../typings/components';

import styles from './CaptureInput.module.less';

interface Props extends ComponentProps {
  maxDuration: number;
  error?: string;
  onChange: (file: File) => void;
}

export const CaptureInput = ({ maxDuration, error, className, style, onChange }: Props) => {
  const videocCaptureInpuRef = React.useRef<any>(null);

  return (
    <Space className={className} style={{ width: '100%', ...style }} size="large" direction="vertical">
      <Typography.Paragraph>
        {I18n.get(lang.BUTTON_START_RECORD_INFOS).replace('${maxDuration}', maxDuration)}
      </Typography.Paragraph>

      <div>
        <input
          ref={videocCaptureInpuRef}
          type="file"
          capture="user"
          accept="video/*"
          style={{ display: 'none' }}
          multiple={false}
          onChange={(event) => {
            if ((event.target.files?.length ?? 0) > 0) {
              const recordedVideo = (event.target.files as FileList)[0];

              onChange && onChange(recordedVideo);
            }
          }}
        />

        <Space style={{ width: '100%', ...style }} size="small" direction="vertical" align="center">
          <Button
            className={styles.button}
            type="primary"
            size="large"
            onClick={() => videocCaptureInpuRef.current?.click()}
          >
            <VideoCameraOutlined style={{ fontSize: 24 }} />
            <br />
            {I18n.get(lang.BUTTON_START_RECORD_VIDEO)}
          </Button>

          {error ? (
            <Typography.Paragraph className={styles.error} type="danger">
              {error}
            </Typography.Paragraph>
          ) : null}
        </Space>
      </div>
    </Space>
  );
};
