import { Typography } from 'antd';
import * as React from 'react';

import { ComponentWithChildrenProps } from '../../typings/components';

import styles from './PageHeader.module.less';

interface Props extends ComponentWithChildrenProps {
  subTitle?: String;
}

export const PageHeader = ({ children, subTitle, className, style }: Props) => {
  return (
    <div className={`${styles.container} ${className || ''}`} style={style}>
      <Typography.Title>{children}</Typography.Title>
      {subTitle && <Typography.Title level={2}>{subTitle}</Typography.Title>}
    </div>
  );
};
