/* eslint-disable no-template-curly-in-string */
import { I18n } from 'aws-amplify';
import { generatePath } from 'react-router';

import { lang } from '../i18n/lang';
import { Notification, ProcessedNotification, Roles } from '../typings';

import { APP_NAVIGATION_ROUTES, employerRole, investorRole, jobSeekerRole, startupRole } from './constants';

interface MSGNotificationMeta {
  senders: Array<{ id: string; name: string } | null>;
}

interface LIKENotificationMeta {
  searchId: string;
  sourceId: string;
}

interface LikeNotificationConfig {
  message: string;
  link: string;
  linkTitle: string;
}

const LIKE_NOTIFICATION_CONFIG: Record<Roles, LikeNotificationConfig> = {
  [jobSeekerRole]: {
    message: lang.NOTIFICATON_LIKE,
    link: APP_NAVIGATION_ROUTES.DASHBOARD_ROUTE,
    linkTitle: lang.VIEW,
  },
  [startupRole]: {
    message: lang.NOTIFICATON_LIKE,
    link: APP_NAVIGATION_ROUTES.DASHBOARD_ROUTE,
    linkTitle: lang.VIEW,
  },
  [employerRole]: {
    message: lang.NOTIFICATON_LIKE_BACK,
    link: APP_NAVIGATION_ROUTES.CHAT_ROUTE,
    linkTitle: lang.CHAT,
  },
  [investorRole]: {
    message: lang.NOTIFICATON_LIKE_BACK,
    link: APP_NAVIGATION_ROUTES.CHAT_ROUTE,
    linkTitle: lang.CHAT,
  },
};

/**
 * Returns meta data to use for displaying information about the specified
 * notification to the user with the specified role.
 *
 * @param notification notification for which to return the meta data
 * @param userRole user role
 */
export function processNotification(notification: Notification, userRole?: Roles | null): ProcessedNotification[] {
  const kind: string = notification.kind || 'LIKE';

  switch (kind) {
    case 'MSG':
      return processMSGNotification(notification);

    case 'LIKE':
      return processLIKENotification(notification, userRole);
  }

  return [];
}

function processMSGNotification(notification: Notification) {
  const meta: MSGNotificationMeta = notification.meta ? JSON.parse(notification.meta) : { senders: [null] };
  const title = I18n.get(lang.NOTIFICATON_MSG);

  const senders = meta.senders
    .map((sender) => sender?.name)
    .filter(Boolean)
    .sort();

  return [
    {
      id: notification.id,
      read: notification.read,
      title,
      description: senders.length ? senders.join(', ') : undefined,
      link: generatePath(APP_NAVIGATION_ROUTES.CHAT_ROUTE),
      linkTitle: I18n.get(lang.CHAT),
    },
  ];
}

function processLIKENotification(notification: Notification, userRole?: Roles | null) {
  const meta: LIKENotificationMeta = notification.meta ? JSON.parse(notification.meta) : null;
  const config = userRole ? LIKE_NOTIFICATION_CONFIG[userRole] : null;
  const linkParams: any = {};

  if (!config) return [];

  if (meta) {
    if (config.link === APP_NAVIGATION_ROUTES.CHAT_ROUTE) {
      linkParams.senderId = meta.sourceId;
    } else if (config.link === APP_NAVIGATION_ROUTES.DASHBOARD_ROUTE) {
      linkParams.profileId = meta.sourceId;
    }
  }

  return [
    {
      id: notification.id,
      read: notification.read,
      title: I18n.get(config.message).replace('${name}', notification.source),
      link: generatePath(config.link, linkParams),
      linkTitle: I18n.get(config.linkTitle),
    },
  ];
}
