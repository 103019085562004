/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setMessagesAsRead = /* GraphQL */ `
  mutation SetMessagesAsRead(
    $senderId: ID!
    $receiverId: ID!
    $from: AWSDateTime!
  ) {
    setMessagesAsRead(
      senderId: $senderId
      receiverId: $receiverId
      from: $from
    ) {
      senderId
      receiverId
      readMessages {
        id
        senderId
        receiverId
        text
        read
        createdAt
        updatedAt
        type
      }
    }
  }
`;
export const setNotificationsAsRead = /* GraphQL */ `
  mutation SetNotificationsAsRead($ids: [ID!]!) {
    setNotificationsAsRead(ids: $ids) {
      id
      userId
      source
      read
      createdAt
      type
      kind
      meta
      updatedAt
    }
  }
`;
export const createProfileJobSeeker = /* GraphQL */ `
  mutation CreateProfileJobSeeker(
    $input: CreateProfileJobSeekerInput!
    $condition: ModelProfileJobSeekerConditionInput
  ) {
    createProfileJobSeeker(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const updateProfileJobSeeker = /* GraphQL */ `
  mutation UpdateProfileJobSeeker(
    $input: UpdateProfileJobSeekerInput!
    $condition: ModelProfileJobSeekerConditionInput
  ) {
    updateProfileJobSeeker(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfileJobSeeker = /* GraphQL */ `
  mutation DeleteProfileJobSeeker(
    $input: DeleteProfileJobSeekerInput!
    $condition: ModelProfileJobSeekerConditionInput
  ) {
    deleteProfileJobSeeker(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const createProfileStartup = /* GraphQL */ `
  mutation CreateProfileStartup(
    $input: CreateProfileStartupInput!
    $condition: ModelProfileStartupConditionInput
  ) {
    createProfileStartup(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      location
      sectors
      creationYear
      size {
        min
        max
      }
      desiredFunding {
        min
        max
      }
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const updateProfileStartup = /* GraphQL */ `
  mutation UpdateProfileStartup(
    $input: UpdateProfileStartupInput!
    $condition: ModelProfileStartupConditionInput
  ) {
    updateProfileStartup(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      location
      sectors
      creationYear
      size {
        min
        max
      }
      desiredFunding {
        min
        max
      }
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfileStartup = /* GraphQL */ `
  mutation DeleteProfileStartup(
    $input: DeleteProfileStartupInput!
    $condition: ModelProfileStartupConditionInput
  ) {
    deleteProfileStartup(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      location
      sectors
      creationYear
      size {
        min
        max
      }
      desiredFunding {
        min
        max
      }
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const createProfileEmployer = /* GraphQL */ `
  mutation CreateProfileEmployer(
    $input: CreateProfileEmployerInput!
    $condition: ModelProfileEmployerConditionInput
  ) {
    createProfileEmployer(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      sectors
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const updateProfileEmployer = /* GraphQL */ `
  mutation UpdateProfileEmployer(
    $input: UpdateProfileEmployerInput!
    $condition: ModelProfileEmployerConditionInput
  ) {
    updateProfileEmployer(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      sectors
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfileEmployer = /* GraphQL */ `
  mutation DeleteProfileEmployer(
    $input: DeleteProfileEmployerInput!
    $condition: ModelProfileEmployerConditionInput
  ) {
    deleteProfileEmployer(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      jobTitle
      sectors
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const createProfileInvestor = /* GraphQL */ `
  mutation CreateProfileInvestor(
    $input: CreateProfileInvestorInput!
    $condition: ModelProfileInvestorConditionInput
  ) {
    createProfileInvestor(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const updateProfileInvestor = /* GraphQL */ `
  mutation UpdateProfileInvestor(
    $input: UpdateProfileInvestorInput!
    $condition: ModelProfileInvestorConditionInput
  ) {
    updateProfileInvestor(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfileInvestor = /* GraphQL */ `
  mutation DeleteProfileInvestor(
    $input: DeleteProfileInvestorInput!
    $condition: ModelProfileInvestorConditionInput
  ) {
    deleteProfileInvestor(input: $input, condition: $condition) {
      id
      userId
      identityId
      email
      firstName
      lastName
      phone {
        prefix
        number
      }
      birthDate
      companyName
      locations
      video
      document
      createdAt
      updatedAt
    }
  }
`;
export const createJobSeekerSearch = /* GraphQL */ `
  mutation CreateJobSeekerSearch(
    $input: CreateJobSeekerSearchInput!
    $condition: ModelJobSeekerSearchConditionInput
  ) {
    createJobSeekerSearch(input: $input, condition: $condition) {
      id
      employerId
      name
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      createdAt
      updatedAt
    }
  }
`;
export const updateJobSeekerSearch = /* GraphQL */ `
  mutation UpdateJobSeekerSearch(
    $input: UpdateJobSeekerSearchInput!
    $condition: ModelJobSeekerSearchConditionInput
  ) {
    updateJobSeekerSearch(input: $input, condition: $condition) {
      id
      employerId
      name
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobSeekerSearch = /* GraphQL */ `
  mutation DeleteJobSeekerSearch(
    $input: DeleteJobSeekerSearchInput!
    $condition: ModelJobSeekerSearchConditionInput
  ) {
    deleteJobSeekerSearch(input: $input, condition: $condition) {
      id
      employerId
      name
      expertises
      sectors
      experienceYears {
        min
        max
      }
      location
      languages
      createdAt
      updatedAt
    }
  }
`;
export const createStartupSearch = /* GraphQL */ `
  mutation CreateStartupSearch(
    $input: CreateStartupSearchInput!
    $condition: ModelStartupSearchConditionInput
  ) {
    createStartupSearch(input: $input, condition: $condition) {
      id
      investorId
      name
      locations
      sectors
      desiredFunding {
        min
        max
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStartupSearch = /* GraphQL */ `
  mutation UpdateStartupSearch(
    $input: UpdateStartupSearchInput!
    $condition: ModelStartupSearchConditionInput
  ) {
    updateStartupSearch(input: $input, condition: $condition) {
      id
      investorId
      name
      locations
      sectors
      desiredFunding {
        min
        max
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteStartupSearch = /* GraphQL */ `
  mutation DeleteStartupSearch(
    $input: DeleteStartupSearchInput!
    $condition: ModelStartupSearchConditionInput
  ) {
    deleteStartupSearch(input: $input, condition: $condition) {
      id
      investorId
      name
      locations
      sectors
      desiredFunding {
        min
        max
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEmployerJobSeekerMatch = /* GraphQL */ `
  mutation CreateEmployerJobSeekerMatch(
    $input: CreateEmployerJobSeekerMatchInput!
    $condition: ModelEmployerJobSeekerMatchConditionInput
  ) {
    createEmployerJobSeekerMatch(input: $input, condition: $condition) {
      id
      employerId
      jobSeekerId
      employer {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      jobSeeker {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      employerResponse
      jobSeekerResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateEmployerJobSeekerMatch = /* GraphQL */ `
  mutation UpdateEmployerJobSeekerMatch(
    $input: UpdateEmployerJobSeekerMatchInput!
    $condition: ModelEmployerJobSeekerMatchConditionInput
  ) {
    updateEmployerJobSeekerMatch(input: $input, condition: $condition) {
      id
      employerId
      jobSeekerId
      employer {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      jobSeeker {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      employerResponse
      jobSeekerResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmployerJobSeekerMatch = /* GraphQL */ `
  mutation DeleteEmployerJobSeekerMatch(
    $input: DeleteEmployerJobSeekerMatchInput!
    $condition: ModelEmployerJobSeekerMatchConditionInput
  ) {
    deleteEmployerJobSeekerMatch(input: $input, condition: $condition) {
      id
      employerId
      jobSeekerId
      employer {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        sectors
        locations
        video
        document
        createdAt
        updatedAt
      }
      jobSeeker {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        expertises
        sectors
        experienceYears {
          min
          max
        }
        location
        languages
        video
        document
        createdAt
        updatedAt
      }
      employerResponse
      jobSeekerResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const createInvestorStartupMatch = /* GraphQL */ `
  mutation CreateInvestorStartupMatch(
    $input: CreateInvestorStartupMatchInput!
    $condition: ModelInvestorStartupMatchConditionInput
  ) {
    createInvestorStartupMatch(input: $input, condition: $condition) {
      id
      investorId
      startupId
      investor {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      startup {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      investorResponse
      startupResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateInvestorStartupMatch = /* GraphQL */ `
  mutation UpdateInvestorStartupMatch(
    $input: UpdateInvestorStartupMatchInput!
    $condition: ModelInvestorStartupMatchConditionInput
  ) {
    updateInvestorStartupMatch(input: $input, condition: $condition) {
      id
      investorId
      startupId
      investor {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      startup {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      investorResponse
      startupResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvestorStartupMatch = /* GraphQL */ `
  mutation DeleteInvestorStartupMatch(
    $input: DeleteInvestorStartupMatchInput!
    $condition: ModelInvestorStartupMatchConditionInput
  ) {
    deleteInvestorStartupMatch(input: $input, condition: $condition) {
      id
      investorId
      startupId
      investor {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        locations
        video
        document
        createdAt
        updatedAt
      }
      startup {
        id
        userId
        identityId
        email
        firstName
        lastName
        phone {
          prefix
          number
        }
        birthDate
        companyName
        jobTitle
        location
        sectors
        creationYear
        size {
          min
          max
        }
        desiredFunding {
          min
          max
        }
        video
        document
        createdAt
        updatedAt
      }
      investorResponse
      startupResponse
      lastMessageSentAt
      searchId
      type
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      senderId
      receiverId
      text
      read
      createdAt
      updatedAt
      type
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      senderId
      receiverId
      text
      read
      createdAt
      updatedAt
      type
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      senderId
      receiverId
      text
      read
      createdAt
      updatedAt
      type
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      source
      read
      createdAt
      type
      kind
      meta
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      source
      read
      createdAt
      type
      kind
      meta
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      source
      read
      createdAt
      type
      kind
      meta
      updatedAt
    }
  }
`;
