/* eslint-disable no-template-curly-in-string */

import { Button, Col, Form, Input, Row } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { lang } from '../../i18n/lang';
import { CountrySelect } from '../CountrySelect';
import { ExpertiseSelect } from '../ExpertiseSelect';
import { LanguageSelect } from '../LanguageSelect';
import { RangeSelect } from '../RangeSelect';
import { SectorSelect } from '../SectorSelect';

import styles from './JobSeekerSearchForm.module.less';

interface Props extends FormProps {
  loading?: boolean;
}

const experienceYearsBoundaries = [0, 2, 2, 5, 5, 8, 8, 12, 12];

const maxExpertisesItems = 5;
const maxSectorsItems = 5;
const maxLocationsItems = 1;
const maxLanguagesItems = 5;

export function JobSeekerSearchForm({ loading, ...rest }: Props) {
  return (
    <Form layout="vertical" hideRequiredMark {...rest}>
      <Row>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 12, offset: 6 }}
          lg={{ span: 12, offset: 6 }}
          className={styles.formItemCol}
        >
          <Form.Item
            hasFeedback
            name="name"
            rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_INPUT_SEARCH) }]}
          >
            <Input placeholder={I18n.get(lang.PLACEHOLDER_INPUT_SEARCH_JOBSEEKER)} />
          </Form.Item>
        </Col>
      </Row>
      <Row className={styles.container} justify="space-around">
        <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol}>
          <Form.Item
            hasFeedback
            labelCol={{ span: 24 }}
            label={
              <div>
                <b>{I18n.get(lang.LABEL_EXPERTISES_SEARCH)}</b>
                <br />
                <span className={styles.labelInfo}>
                  {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxExpertisesItems)}
                </span>
              </div>
            }
            name="expertises"
            rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_EXPERTISES_SEARCH) }]}
          >
            <ExpertiseSelect maxItems={maxExpertisesItems} />
          </Form.Item>
        </Col>

        <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol}>
          <Form.Item
            hasFeedback
            labelCol={{ span: 24 }}
            label={
              <div>
                <b>{I18n.get(lang.LABEL_LOCATION_SEARCH)}</b>
                <br />
                <span className={styles.labelInfo}>{I18n.get(lang.SELECT_A_COUNTRY)}</span>
              </div>
            }
            name="location"
            rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LOCATION_SEARCH) }]}
          >
            <CountrySelect maxItems={maxLocationsItems} />
          </Form.Item>
        </Col>

        <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol}>
          <Form.Item
            hasFeedback
            labelCol={{ span: 24 }}
            label={
              <div>
                <b>{I18n.get(lang.LABEL_SECTORS_SEARCH_JOBSEEKER)}</b>
                <br />
                <span className={styles.labelInfo}>
                  {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxSectorsItems)}
                </span>
              </div>
            }
            name="sectors"
            rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_SECTORS_SEARCH_JOBSEEKER) }]}
          >
            <SectorSelect maxItems={maxSectorsItems} />
          </Form.Item>
        </Col>

        <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol}>
          <Form.Item
            hasFeedback
            labelCol={{ span: 24 }}
            label={
              <div>
                <b>{I18n.get(lang.LABEL_LANGUAGES_SEARCH)}</b>
                <br />
                <span className={styles.labelInfo}>
                  {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxLanguagesItems)}
                </span>
              </div>
            }
            name="languages"
            rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LANGUAGES_SEARCH) }]}
          >
            <LanguageSelect maxItems={maxLanguagesItems} />
          </Form.Item>
        </Col>

        <Col xs={20} sm={20} md={10} lg={10} className={styles.formItemCol}>
          <Form.Item
            hasFeedback
            name="experienceYears"
            labelCol={{ span: 24 }}
            label={
              <div>
                <b>{I18n.get(lang.LABEL_EXPERIENCE_YEARS_SEARCH)}</b>
                <br />
                <span className={styles.labelInfo}>{I18n.get(lang.CHOOSE_AN_OPTION)}</span>
              </div>
            }
          >
            <RangeSelect boundaries={experienceYearsBoundaries} addAny />
          </Form.Item>
        </Col>
        <Col xs={20} sm={20} md={10} lg={10}>
          <Button className={styles.actionButton} type="primary" htmlType="submit" loading={loading}>
            <Row>
              <Col span={24}>
                <b style={{ fontSize: 28, lineHeight: 0.1 }}>→</b>
              </Col>
              <Col span={24}>{I18n.get(lang.SEE_RESULT_BTN)}</Col>
            </Row>
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
