import { useQuery } from '@apollo/react-hooks';
import { Button, Empty, List, Spin, Typography } from 'antd';
import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { NotificationsByCreatedAtQuery } from '../../api/elevid';
import EmptyInbox from '../../assets/EmptyInbox.svg';
import { Page } from '../../components/Page';
import { notificationsByCreatedAt } from '../../graphql/queries';
import { useSetNotifsAsRead } from '../../hooks';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { Notification, ProcessedNotification } from '../../typings';
import { processNotification } from '../../utils/notifications';

import styles from './NotificationsList.module.less';

const LIST_NOTIFICATIONS_BY_CREATED_AT = gql(notificationsByCreatedAt);

export const NotificationsList = () => {
  const history = useHistory();
  const { profile, userRole } = useAuthStore((state) => state, shallow);
  const userId = profile?.userId;
  const setNotifsAsRead = useSetNotifsAsRead({ userId });

  const notificationsQueryVariables = {
    type: 'Notification',
    sortDirection: 'DESC',
    filter: { userId: { eq: userId } },
  };

  const { data, loading } = useQuery<NotificationsByCreatedAtQuery>(LIST_NOTIFICATIONS_BY_CREATED_AT, {
    variables: notificationsQueryVariables,
    skip: !userId,
  });

  const notifs = React.useMemo(
    () =>
      (data?.NotificationsByCreatedAt?.items ?? []).reduce<ProcessedNotification[]>((notifs, notification) => {
        const processed = processNotification(notification as Notification, userRole);

        notifs = [...notifs, ...processed];

        return notifs;
      }, []),
    [data, userRole]
  );

  const handleMarkAllAsReadClick = React.useCallback(() => {
    const unreadNotifIds = notifs.reduce((acc: string[], curr: any) => {
      if (!curr.read) {
        acc.push(curr.id);
      }

      return acc;
    }, []);

    if (unreadNotifIds.length > 0) {
      setNotifsAsRead(unreadNotifIds);
    }
  }, [notifs, setNotifsAsRead]);

  const handleNotifClick = React.useCallback(
    (item: ProcessedNotification) => {
      setNotifsAsRead([item.id]);
      history.push(item.link);
    },
    [history, setNotifsAsRead]
  );

  const noNotifications = notifs.length === 0;

  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.TITLE_NOTIFICATONS)}
      title={I18n.get(lang.TITLE_NOTIFICATONS)}
      layout={loading || noNotifications ? 'center' : 'default'}
    >
      {loading ? (
        <Spin />
      ) : noNotifications ? (
        <Empty description={I18n.get(lang.NO_NOTIFICATIONS_MESSAGE)} image={EmptyInbox} />
      ) : (
        <List
          className={styles.list}
          itemLayout="horizontal"
          dataSource={['', ...notifs]}
          loading={loading}
          renderItem={(item: ProcessedNotification | string) =>
            typeof item === 'string' ? (
              <List.Item
                className={styles.actions}
                actions={[
                  <Button type="default" size="small" onClick={handleMarkAllAsReadClick}>
                    {I18n.get(lang.MARK_ALL_AS_READ)}
                  </Button>,
                ]}
              >
                <List.Item.Meta title=" " />
              </List.Item>
            ) : (
              <List.Item
                actions={[
                  <Button type="primary" size="small" onClick={() => handleNotifClick(item)}>
                    {item.linkTitle}
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={<Typography.Text strong={!item.read}>{item.title}</Typography.Text>}
                  description={item.description}
                  avatar={
                    <svg height="20" width="20">
                      <circle cx="7" cy="10" r="4" fill="#126a8d" opacity={item.read ? 0.6 : 1} />
                    </svg>
                  }
                />
              </List.Item>
            )
          }
        />
      )}
    </Page>
  );
};
