import { useLazyQuery } from '@apollo/react-hooks';
import { Card, Col, Row, Spin } from 'antd';
import { gql } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { SearchAsCard } from '../../components/SearchAsCard';
import { listStartupSearchs } from '../../graphql/queries';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES, investorRole } from '../../utils/constants';

import styles from './SearchListStartup.module.less';

const LIST_STARTUPS_SEARCHES = gql(listStartupSearchs);

export function SearchListStartup() {
  const history = useHistory();
  const { user, userRole } = useAuthStore((store) => ({ ...store }), shallow);
  const [getStartupsSearches, { loading, data, variables }] = useLazyQuery(LIST_STARTUPS_SEARCHES);

  React.useEffect(() => {
    if (userRole === investorRole) {
      const shouldFetch =
        typeof user?.attributes.sub === 'string' && variables?.filter.investorId.eq !== user?.attributes.sub;

      if (shouldFetch) getStartupsSearches({ variables: { filter: { investorId: { eq: user?.attributes.sub } } } });
    } else {
      // TODO: This redirect will be removed when PrivateRoute supports roles
      history.push({ pathname: APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE });
    }
  }, [getStartupsSearches, history, user, userRole, variables]);

  return (
    <Row align="middle" justify="center" className={styles.container}>
      <Col>
        <Row align="middle" justify="center" gutter={[32, 32]}>
          <Col xs={20} sm={20} md={8} lg={8} className={styles.cardCol}>
            <Link to={APP_NAVIGATION_ROUTES.STARTUPS_ADD_SEARCH_ROUTE}>
              <div className={styles.cardContainer}>
                <Card title="+" className={styles.addSearchCard}>
                  <b>
                    {I18n.get(lang.NEW)} <br />
                    {I18n.get(lang.SEARCH)}
                  </b>
                </Card>
              </div>
            </Link>
          </Col>
          {loading ? (
            <Spin />
          ) : (
            data?.listStartupSearchs?.items?.map((searchItem: any) => (
              <Col key={searchItem.id} xs={20} sm={20} md={8} lg={8} className={styles.cardCol}>
                <SearchAsCard
                  title={searchItem.name}
                  to={`${APP_NAVIGATION_ROUTES.STARTUPS_SEARCH_ROUTE}/${searchItem.id}`}
                />
              </Col>
            ))
          )}
        </Row>
      </Col>
    </Row>
  );
}
