import { LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Auth, I18n } from 'aws-amplify';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { AuthFormWrapper } from '../../components/AuthFormWrapper';
import { Page } from '../../components/Page';
import { lang } from '../../i18n/lang';
import { LOGIN_ROUTE } from '../../utils/constants';
import { captureException } from '../../utils/sentry';

export function ResetPassword() {
  const location = useLocation();
  const history = useHistory();

  const submit = React.useCallback(
    async (values: Store) => {
      const { password, code } = values;
      const username = location.search.split('=')[1];

      try {
        await Auth.forgotPasswordSubmit(username.trim(), code.trim(), password.trim());

        notification.success({
          message: I18n.get(lang.SUCCESS_MSG_RESET_PASSWORD),
          description: I18n.get(lang.SUCCESS_DESC_RESET_PASSWORD),
          placement: 'topRight',
        });

        history.push(LOGIN_ROUTE);
      } catch (err) {
        captureException(err);

        notification['error']({
          message: I18n.get(lang.ERROR_MSG_RESET_PASSWORD),
          description: err.message,
          placement: 'topRight',
        });
      }
    },
    [history, location.search]
  );

  const [{ loading }, handleSubmit] = useAsyncFn(submit, [location]);

  return (
    <Page
      type="public"
      browserTitle={I18n.get(lang.RESET_PASSWORD)}
      title={I18n.get(lang.RESET_PASSWORD)}
      layout="topCenter"
    >
      <AuthFormWrapper>
        <Form onFinish={handleSubmit} autoComplete="off">
          <div style={{ textAlign: 'center' }}>
            <p>{I18n.get(lang.TEXT_CHECK_CODE)}</p>
          </div>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: I18n.get(lang.VALIDATOR_MSG_CODE),
              },
            ]}
          >
            <Input prefix={<LockOutlined />} placeholder={I18n.get(lang.PLACEHOLDER_INPUT_CODE)} autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: I18n.get(lang.VALIDATOR_MSG_PASSWORD),
              },
              {
                min: 8,
                message: I18n.get(lang.VALIDATOR_MSG_PASSWORD_LEN),
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={I18n.get(lang.PLACEHOLDER_NEW_PASSWORD)}
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            rules={[
              {
                required: true,
                message: I18n.get(lang.VALIDATOR_MSG_CONFIRM_PASSWORD),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(I18n.get(lang.TWO_PASSWORD_NOT_MUCH));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder={I18n.get(lang.LABEL_CONFIRM_PASSWORD)}
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item className="text-center">
            <Button
              style={{ width: '100%' }}
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {I18n.get(lang.CONFIRM_BTN)}
            </Button>
          </Form.Item>
        </Form>
      </AuthFormWrapper>
    </Page>
  );
}
