import { useMutation, useQuery } from '@apollo/react-hooks';
import { Spin, message } from 'antd';
import { gql } from 'apollo-boost';
import type { MutationUpdaterFn } from 'apollo-boost';
import { I18n } from 'aws-amplify';
import { omit } from 'ramda';
import * as React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { shallow } from 'zustand/shallow';

import { JobSeekerSearchForm } from '../../components/JobSeekerSearchForm';
import { Page } from '../../components/Page';
import { updateJobSeekerSearch } from '../../graphql/mutations';
import { getJobSeekerSearch, listJobSeekerSearchs } from '../../graphql/queries';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { APP_NAVIGATION_ROUTES, employerRole } from '../../utils/constants';

const UPDATE_JOB_SEEKER_SEARCH = gql(updateJobSeekerSearch);
export const LIST_JOB_SEEKERS_SEARCHES = gql(listJobSeekerSearchs);
const GET_JOB_SEEKER_SEARCH = gql(getJobSeekerSearch);

export const UpdateJobSeekerSearch = () => {
  const { user, userRole } = useAuthStore((store) => ({ ...store }), shallow);
  const { id: searchId } = useParams<{ id: string }>();

  const { loading: loadingSearchData, data: searchData } = useQuery(GET_JOB_SEEKER_SEARCH, {
    variables: { id: searchId },
  });

  const employerId = user?.attributes.sub;

  // FIXME: Fix missing __typename on cache update
  const updateSearch: MutationUpdaterFn<any> = React.useCallback(
    (proxy, { data: { updateJobSeekerSearch } }) => {
      try {
        const { listJobSeekerSearchs }: any = proxy.readQuery({
          query: LIST_JOB_SEEKERS_SEARCHES,
          variables: { filter: { employerId: { eq: employerId } } },
        });

        const searchIndex = listJobSeekerSearchs.items.findIndex(
          (search: any) => search.id === updateJobSeekerSearch.id
        );

        const searches = [...listJobSeekerSearchs.items];
        searches[searchIndex] = updateJobSeekerSearch;

        proxy.writeQuery({
          query: LIST_JOB_SEEKERS_SEARCHES,
          data: { listJobSeekerSearchs: { items: searches } },
          variables: { filter: { employerId: { eq: employerId } } },
        });
      } catch {}

      proxy.writeQuery({
        query: GET_JOB_SEEKER_SEARCH,
        variables: { id: searchId },
        data: { getJobSeekerSearch: updateJobSeekerSearch },
      });

      return proxy;
    },
    [employerId, searchId]
  );

  const [updateJobSeekerSearch, { data }] = useMutation(UPDATE_JOB_SEEKER_SEARCH, { update: updateSearch });

  const onFinish = React.useCallback(
    async (values: any) => {
      const mutationProps = {
        variables: {
          input: {
            ...values,
            id: searchId,
            employerId,
          },
        },
      };

      try {
        await updateJobSeekerSearch(mutationProps);
        message.success(I18n.get(lang.SUCCESS_SEARCH_SAVED));
      } catch {
        message.error(I18n.get(lang.ERROR_SEARCH_SAVED));
      }
    },
    [searchId, employerId, updateJobSeekerSearch]
  );

  const [{ loading }, handleSubmit] = useAsyncFn(onFinish, [updateJobSeekerSearch, employerId]);

  if (userRole !== employerRole) return <Redirect to={APP_NAVIGATION_ROUTES.DEFAULT_DASHBOARD_ROUTE} />;

  if (data?.updateJobSeekerSearch?.id) {
    return <Redirect to={`${APP_NAVIGATION_ROUTES.JOBSEEKERS_SEARCH_ROUTE}/${data?.updateJobSeekerSearch?.id}`} />;
  }

  if (loadingSearchData) return <Spin />;

  const searchName = searchData?.getJobSeekerSearch?.name;

  const experienceYears = searchData?.getJobSeekerSearch?.experienceYears
    ? omit(['__typename'], searchData?.getJobSeekerSearch?.experienceYears)
    : null;

  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.UPDATE_SEARCH)}
      title={I18n.get(lang.UPDATE_SEARCH)}
      subTitle={searchName || I18n.get(lang.LOADING)}
    >
      <JobSeekerSearchForm
        onFinish={handleSubmit}
        loading={loading}
        initialValues={{
          name: searchName,
          expertises: searchData?.getJobSeekerSearch?.expertises,
          location: searchData?.getJobSeekerSearch?.location,
          sectors: searchData?.getJobSeekerSearch?.sectors,
          languages: searchData?.getJobSeekerSearch?.languages,
          experienceYears,
        }}
      />
    </Page>
  );
};
