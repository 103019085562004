/* eslint-disable no-template-curly-in-string */

export const lang = {
  TITLE_UPDATE_PASSWORD_PAGE: 'Please Update Your Password',
  SUCCESS_MSG_LOGIN: 'Successfully logged in!',
  SUCCESS_DESC_LOGIN: 'Logged in successfully, Redirecting you in a few!',
  SUCCESS_DESC_RESET_PASSWORD_FIRST: 'You have to reset your password first',
  ERROR: 'Error',
  LABEL_NEW_PASSWORD: 'Your new password',
  VALIDATOR_MSG_NEW_PASSWORD: 'Please type your new password!',
  LABEL_CONFIRM_PASSWORD: 'Confirm Password',
  VALIDATOR_MSG_CONFIRM_PASSWORD: 'Please confirm your password!',
  TWO_PASSWORD_NOT_MUCH: 'The two passwords that you entered do not match!',
  SUBMIT_BTN: 'Submit',
  NO_USER_FOUND_MSG:
    'Sorry, we have nothing to show you at the moment! No users match your search criteria – please start a new search with different criteria, and we are sure you will find what you are looking for!',
  ALL_VIDEOS_VIEWED_MSG:
    'That’s all we have got for now! You have viewed all video pitches that match your search criteria.',
  NO_USER_SAID_YES_MSG:
    'Sorry, we have nothing to show you at the moment! No new users have said "Yes, please!" to your video pitch. Please come back later, and we are sure you will find what you are looking for!',
  ALL_VIDEOS_OF_USER_SAID_YES_VIEWED_MSG:
    'That’s all we have got for now! You have viewed all video pitches of users that said "Yes, please!" to your video pitch.',
  IM_A_CANDIDATE: 'I’m a \n candidate',
  IM_AN_EMPLOYER: 'I’m an \n employer',
  IM_A_STARTUP: 'I’m a \n start-up',
  IM_AN_INVESTOR: 'I’m an \n investor',
  SUBTITLE_JOB_SEEKER_CARD: 'looking for a new opportunity.',
  SUBTITLE_EMPLOYER_CARD: 'looking for bright talent.',
  SUBTITLE_STARTUP_CARD: 'looking for investment.',
  SUBTITLE_INVESTOR_CARD: 'looking to invest in promising start-ups.',
  REGISTER: 'Register',
  REGISTER_FOR_FREE: 'Register for free',
  REGISTER_AS_JOBSEEKER: 'Register as a candidate',
  REGISTER_AS_EMPLOYER: 'Register as an employer',
  REGISTER_AS_STARTUP: 'Register as a start-up',
  REGISTER_AS_INVESTOR: 'Register as an investor',
  SUCCESS_MSG_SIGNUP: 'Successfully signed up!',
  SUCCESS_DESC_SIGNUP: 'Check your email to complete your signup process',
  ERROR_SIGNUP: 'Sign Up Error',
  ERROR_EMAIL_SIGNUP: 'Email used!',
  ERROR_EMAIL_SIGNUP_DESC: 'An account with the given email already exists.',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  LOGIN: 'Login',
  UPDATE_PASSWORD: 'UPDATE PASSWORD',
  FORGOT_PASSWORD: 'Forgot password',
  OR: 'OR',
  MESSENGER: 'Messenger',
  NO_MESSAGES_TO_SHOW:
    'There are currently no messages to show. Once you get matched with other users you will be able to message them here.',
  REDIRECTION_MSG_FORGOT_PASSWORD: 'Redirecting you in a few!',
  REDIRECTION_DESCRIPTION_FORGOT_PASSWORD: 'Account confirmed successfully!',
  ERROR_FORGOT_PASSWORD: 'User confirmation failed',
  RESET_PASSWORD: 'Password reset',
  VALIDATOR_MSG_EMAIL: 'Please type your email address!',
  VALIDATOR_MSG_EMAIL_INVALID: 'Please type a valid email address!',
  RESET_PASSWORD_BTN: 'Reset password',
  IVE_REMEMBERED_LINK: 'Ooh! Wait! I’ve remembered!',
  LOGIN_FAILED_CONFIRM_EMAIL_MSG: 'Login failed!',
  LOGIN_FAILED_CONFIRM_EMAIL_DESC: 'Automatic login failed, please try again.',
  ERROR_CONFIRM_EMAIL_MSG: 'Confirmation failed!',
  ERROR_CONFIRM_EMAIL_DESC: 'Account confirmation failed, please try again.',
  SUCCESS_MSG_CONFIRM_EMAIL: 'Code resent.',
  SUCCESS_DESC_CONFIRM_EMAIL: 'Please check your email for the new confirmation code.',
  ERROR_MSG_CONFIRM_EMAIL: 'Resend code failed!',
  ERROR_DESC_CONFIRM_EMAIL: 'Failed to resend code, please try again.',
  TITLE_CONFIRM_EMAIL_PAGE: 'Email confirmation',
  CONFIRMATION_CODE_MSG:
    'We have now sent you a six digit confirmation code via email. Please also check your spam folder!',
  SUBTITLE_PASSWORD_CONFIRM_EMAIL: 'Please enter the password you choose during registration.',
  VALIDATOR_MSG_PASSWORD: 'Please enter your password',
  VALIDATOR_MSG_PASSWORD_LEN: 'Please enter at least 8 characters',
  LABEL_CONFIMATION_CODE: 'Confirmation code',
  SUBLABEL_CONFIMATION_CODE: 'Please enter the 6 digit code you received by email.',
  VALIDATOR_MSG_REQUIRED_CODE: 'Please enter the confirmation code',
  VALIDATOR_MSG_PATTERN_CODE: 'Code must be a 6 digits number',
  CONFIRM_EMAIL_BTN: 'Confirm Email',
  RESEND_CONFIRM_CODE_BTN: 'Resend confirmation code',
  THIS_MINUTE_MATTERS: 'this minute matters.',
  FIRST_PARAGRAPH_HOME:
    'There is no second chance for a first impression. Make yours count, and join elevid: A cutting-edge video-based matching platform that links you to exactly who you should be speaking to – wherever you are in the world.',
  SECONDE_PARAGRAPH_HOME:
    'Based on a one-minute video pitch and a brief questionnaire, elevid lets you make relevant connections with what often goes unseen: the people, not the KPIs.',
  THIRD_PARAGRAPH_HOME:
    'Whether you are looking for bright talent for your company, or whether you are on the hunt for bold ideas to invest in: This is the place for you.',
  FOURTH_PARAGRAPH_HOME:
    'Are you currently browsing the job market, tired of standard job descriptions that don’t give you a true glimpse of who will really receive your application? Is your startup trying to navigate the endless universe of investors and pitching events? elevid will change your game.',

  SUCCESS_SEARCH_SAVED: 'Search saved with success!',
  ERROR_SEARCH_SAVED: 'Search save failed!',
  TITLE_SEARCH_STARTUP: 'Invest in clever ideas.',
  VALIDATOR_MSG_INPUT_SEARCH: 'Please input your Search',
  PLACEHOLDER_INPUT_SEARCH_STARTUP: 'Name this search (e.g ’Automotive in the UK’)',
  LABEL_LOCATIONS_SEARCH: 'Where should the start-up be based?',
  VALIDATOR_MSG_LOCATIONS_SEARCH: 'Please select locations',
  LABEL_SECTORS_SEARCH_STARTUP: 'What sectors should the start-up be operating in? (optional*)',
  NO_SECTOR_SELECT_INFO: '*Leave this field blank to access ALL start-ups in your search results.',
  VALIDATOR_MSG_SECTORS_SEARCH_STARTUP: 'Please select sectors',
  LABEL_DESIRED_FUNDING_SEARCH: 'How much funding should the start-up be looking for?',
  CHOOSE_AN_OPTION: 'Choose an option.',
  VALIDATOR_DESIRED_FUNDING_SEARCH: 'Please input amount',
  SEE_RESULT_BTN: 'SEE RESULTS',
  NEW: 'New',
  SEARCH: 'Search',

  TITLE_SEARCH_JOBSEEKER: 'Find bright talent',
  PLACEHOLDER_INPUT_SEARCH_JOBSEEKER: 'Name this search (e.g "HR Experts" or "UK Accountants")',
  LABEL_EXPERTISES_SEARCH: 'What expertise should the candidate have?',
  VALIDATOR_MSG_EXPERTISES_SEARCH: 'Please select the desired Expertises',
  LABEL_LOCATION_SEARCH: 'Where should the candidate be based?',
  SELECT_A_COUNTRY: 'Select a country.',
  VALIDATOR_MSG_LOCATION_SEARCH: 'Please select the desired job seekers Locations',
  LABEL_SECTORS_SEARCH_JOBSEEKER: 'What sectors should the candidate have experience in?',
  VALIDATOR_MSG_SECTORS_SEARCH_JOBSEEKER: 'Please select the desired job seekers Sectors',
  LABEL_LANGUAGES_SEARCH: 'What languages should the candidate speak fluently?',
  VALIDATOR_MSG_LANGUAGES_SEARCH: 'Please select the desired job seekers Languages',
  LABEL_EXPERIENCE_YEARS_SEARCH: 'How many total years of experience should the candidate have?',

  SUCCESS_MSG_RESET_PASSWORD: 'Success!',
  SUCCESS_DESC_RESET_PASSWORD: 'Password reset successful, Redirecting you in a few!',
  ERROR_MSG_RESET_PASSWORD: 'Error resetting password',
  TEXT_CHECK_CODE: 'Check your email for the confirmation code',
  VALIDATOR_MSG_CODE: 'Please type your confirmation code!',
  PLACEHOLDER_INPUT_CODE: 'Enter your verification code',
  PLACEHOLDER_NEW_PASSWORD: 'New Password',
  CONFIRM_BTN: 'Confirm',

  TITLE_REGISTER_STARTUP: 'I’m a start-up',
  TITLE_REGISTER_JOBSEEKER: 'I’m a candidate',
  TITLE_REGISTER_INVESTOR: 'I’m an investor',
  TITLE_REGISTER_EMPLOYER: 'I’m an employer',

  LABEL_COMPANY_NAME: 'Company Name',
  VALIDATOR_MSG_COMPANY_NAME: 'Please type your Company name',
  LABEL_JOB_TITLE: 'Job Title',
  VALIDATOR_MSG_JOB_TITLE: 'Please type your Job title',
  LABEL_FIRST_NAME: 'First Name',
  VALIDATOR_MSG_FIRST_NAME: 'Please type your First Name',
  LABEL_PHONE: 'Phone Number (optional)',
  LABEL_LAST_NAME: 'Last Name',
  VALIDATOR_MSG_LAST_NAME: 'Please type your Last Name',
  LABEL_BIRTH_DATE: 'Birth Date',
  VALIDATOR_MSG_BIRTH_DATE: 'Please select your Birth Date',
  LABEL_LOCATION_STARTUP: 'Where is your start-up based?',
  VALIDATOR_MSG_LOCATION_STARTUP: 'Please select your start-up Location',
  LABEL_SECTORS: 'What sectors do you operate in?',
  VALIDATOR_MSG_SECTORS_STARTUP: 'Please select your start-up Sectors',
  LABEL_CREATION_YEAR: 'What year was your start-up created?',
  VALIDATOR_MSG_CREATION_YEAR: 'Please select your start-up creation year',
  LABEL_SIZE: 'How many employees do you currently have?',
  VALIDATOR_MSG_SIZE: 'Please select the Range of employees number',
  LABEL_DESIRED_FUNDING: 'How much funding are you looking to raise?',
  VALIDATOR_DESIRED_FUNDING: "Please select your Funding Range that you're looking for",
  LABEL_EXPERTISES: 'What are your fields of expertise?',
  VALIDATOR_MSG_EXPERTISES: 'Please select your fields of Expertise',
  LABEL_LOCATION_JOBSEEKER: 'Where are you based?',
  VALIDATOR_MSG_LOCATION_JOBSEEKER: 'Please select where are you based',
  LABEL_SECTORS_JOBSEEKER: 'What sectors do you have the most experience in?',
  VALIDATOR_MSG_SECTORS: 'Please select your Sectors',
  LABEL_LANGUAGES: 'What languages do you speak fluently?',
  VALIDATOR_MSG_LANGUAGES: 'Please select your spoken Languages',
  LABEL_EXPERIENCE_YEARS: 'How many total years of experience do you have?',
  VALIDATOR_MSG_EXPERIENCE_YEARS: 'Please select your Experience Years range',
  LABEL_LOCATIONS: 'What are your primary business locations?',
  VALIDATOR_MSG_LOCATIONS: 'Please select your business Locations',
  LABEL_COMPANY_NAME_OPTIONAL: 'Company Name (optional)',
  SUCCESS_MSG_SAVE_PROFILE: 'Profile saved successfully.',
  ERROR_MSG_SAVE_PROFILE: 'Failed to save profile, please try again later.',
  PROFILE: 'Profile',
  PERSONAL_INFORMATION: 'Personal information',
  QUESTIONNAIRE: 'Questionnaire',
  VIDEO: 'Video',
  VALIDATOR_MSG_VIDEO: 'Please add your video',
  SUPPORTING_DOCUMENT: 'Supporting document',
  UPDATE: 'Update',

  DOWNLOAD_DOC_BTN: 'Download supporting document',
  BRAND_NEW_CHAT: 'This is a brand new chat, send your first message below!',
  TITLE_SUPPORT_DOC: 'Upload a supporting document (optional)',
  PARAGRAPH_SUPPORT_DOC: `* If you like, you can add a supporting document to your profile
* Users you have matched with will be able to download this document
* Upload your...
  * CV
  * company presentation
  * portfolio
  * bio
  * or any other pdf document
* to highlight who you are and what you want to show fellow elevid users.`,
  CLICK_DRAG_FILE: 'Click or Drag your file to upload it',
  ALLOWED_FILE_TYPE: 'Allowed file types',
  ALL_RIGHT: 'ELEVID © ALL RIGHT RESERVED 2020',
  TERMS_OF_SERVICES: 'Terms of Services',
  PRIVACY: 'Privacy',
  PRIVACY_POLICY: 'Privacy Policy',
  LOGOUT: 'Log out',
  GO_STEP_TWO: 'Go to step 2/4',
  GO_STEP_THREE: 'Validate video\n& go to step 3/4',
  GO_STEP_FOUR: 'Go to step 4/4',
  FINISH_BTN: 'Finish',
  SUCCESS_MSG_REGISTRAION: 'Registration done with success!',
  REGISTRAION_FILAED_MSG: 'Error saving profile!',
  REGISTRAION_FILAED_DESC: 'An error occurred when saving your profile, please try again later.',
  PREVIOUS_BTN: 'Previous',
  VIEW_CANDIDATES: 'View Candidates',
  FREE: 'FREE - CLICK HERE',
  LIMITED_OFFER: 'LIMITED OFFER',
  YEAR: 'Year',
  NO_THANKS: 'No, thanks!',
  YES_PLEASE: 'Yes, please!',
  MY_VIDEO: 'My video',
  VIDEO_UPLOAD_TITLE_EMPLOYER: 'Add your 1-minute video pitch to your profile',
  VIDEO_UPLOAD_DESCRIPTION_EMPLOYER: `This minute matters! Your video pitch is the first impression other users will get from you – make it count. Here is how:
1. You don’t need special equipment – simply film yourself with your smartphone or computer.
2. Introduce yourself and your professional background & activity.
3. Describe what you are looking for.

**Important note:** If you don’t wish to show your face, or if you currently don’t have time to film a video, you can also upload a slideshow or simply your company logo (as a video in an .mp4 format)!`,
  VIDEO_RECORD_TITLE_EMPLOYER: 'Record your 1-minute video pitch NOW!',
  VIDEO_UPLOAD_TITLE_JOB_SEEKER: 'Add your 1-minute video pitch to your profile',
  VIDEO_UPLOAD_DESCRIPTION_JOB_SEEKER: `This minute matters! Your video pitch is the first impression other users will get from you – make it count. Here is how:
1. You don’t need special equipment – simply film yourself with your smartphone or computer.
2. Choose a neutral outfit and background.
3. Introduce yourself and your professional background & activity.
4. Highlight your competencies and soft skills.
5. Present your goals, added value, and what solution / expertise you offer.`,
  VIDEO_RECORD_TITLE_JOB_SEEKER: 'Record your 1-minute video pitch NOW and impress!',
  CLICK_DRAG_VIDEO: 'Click or drag file to this area to upload',
  ALLOWED_VIDEO_TYPE: 'Allowed file types: ',
  MAX_TIME_VIDEO: 'Max time allowed: 60 seconds',
  BUTTON_START_RECORD_VIDEO: 'Click to start recording your video',
  BUTTON_START_RECORD_INFOS:
    "To start recording your video, click on the button below. Make sure the video's duration doesn't exceed ${maxDuration} seconds.",
  PREVIEW_VIDEO: 'Preview Video',
  UNSUPPORTED_VIDEO: 'Unsupported video file, please try with another file.',
  MAX_DURATION_VIDEO:
    'The maximum allowed duration of the video is maxDuration seconds. Your video is durationTime seconds long.',
  VIDEO_RECORDING_NOT_SUPPORTED: 'Video recording is not supported by your browser',
  VIDEO_RECORDING_NOT_SUPPORTED_INFOS:
    'Use a different browser or record a video of maximum ${maxDuration} seconds on your device, and then upload it using the "${uploadVideoLabel}" tool.',

  MORE_THAN: 'More than number €',
  NEW_MESSAGE: 'New Message: text',
  JOBSEEKER_LIKED: 'user’s Job Seeker profile has been liked!',
  JOBSEEKER_DISLIKED: 'user’s Job Seeker profile has been disliked!',
  EMPLOYER_LIKED: 'user’s employer profile of company (companyName) has been responded with a like!',
  EMPLOYER_DISLIKED: 'user’s employer profile of company (companyName) has been responded with a dislike!',
  INVESTOR_LIKED: 'user’s investor profile of company (companyName) has been responded with a like!',
  INVESTOR_DISLIKED: 'user’s investor profile of company (companyName) has been responded with a dislike!',
  STARTUP_LIKED: 'user’s start-up profile (companyName) has been liked!',
  STARTUP_DISLIKED: 'user’s start-up profile (companyName) has been disliked!',
  LIKE_DISLIKE_ERROR: 'An has error occurred, please try again later!',

  IS_REQUIRED: '${name} is required!',
  IS_NOT_VALID: '${label} is not valid!',

  TITLE_NOTIFICATONS: 'Notifications',
  NOTIFICATON_LIKE: '${name} liked your pitch! Go to the dashboard to watch theirs.',
  NOTIFICATON_LIKE_BACK: '${name} also liked your pitch! Go to the messenger to chat with them.',
  NOTIFICATON_MSG: 'You have unread messages! Go to the messenger to read them.',
  NOTIFICATON_MSG_WITH_NAME: '${name} sent you new messages! Go to the messenger to read them.',
  NO_NOTIFICATIONS_MESSAGE: 'You have no notifications yet. Once you get new notifications they will appear here.',
  NEW_NOTIFIATION_MESSAGE: 'You just received new notifications!',
  MARK_ALL_AS_READ: 'Mark all as read',

  DELETE_SEARCH_TITLE: 'Delete "${searchName}"?',
  DELETE_SEARCH_TEXT: 'After confirmation this search will be deleted definitely.',
  EDIT_SEARCH_ACTION_LABEL: 'edit',
  DELETE_SEARCH_ACTION_LABEL: 'delete',

  COMING_SOON: 'COMING SOON',
  LEARN_MORE: 'LEARN MORE >',

  DASHBOARD: 'Dashboard',
  DASHBOARD_WELCOME: 'Welcome ${name}',

  AGREE_TERMS_PRIVACY: 'by registering, you agree to our ${terms} and our ${privacy}',

  CREATE_SEARCH: 'Create a new search',
  UPDATE_SEARCH: 'Update your search',
  SEARCH_RESULTS: 'Search results',

  LOADING: 'Loading...',

  VIEW: 'View',
  CHAT: 'Chat',

  REGISTRATION_PROCESS: 'elevid registration process',

  SAVING_PROFILE_MSG:
    'Saving your profile. This might take up to a minute depending on the size of the video and the support document.',
  UPLOAD_VIDEO_STAGE: 'Upload your video',
  UPLOAD_DOC_STAGE: 'Upload your supporting document',
  UPLOAD_DATA_STAGE: 'Save your profile data',
  CLOSE_FINISH: 'Close & finish',

  START_RECORDING: 'Start\nrecording',
  STOP_RECORDING: 'Stop\nrecording',

  SELECT_LIMITED_OPTIONS: 'Choose up to ${count} options.',

  OPTION_ANY: 'Any',

  NO_CAMERA_FOUND: 'No camera found!',
  CONNECT_CAMERA: "Please connect a camera then click on 'Activate'.",
  NO_CAMERA_ACCESS: 'No access to camera!',
  ALLOW_CAMERA:
    "Please check your browser configuration and give Elevid access to your camera then click on 'Activate'.",
  ACTIVATE: 'Activate',

  NOT_AUTH_INFO_TITLE: 'Authentication',
  NOT_AUTH_INFO_MESSAGE:
    'You are not authenticated. Either you signed out in another browser window or your session has expired. Please login again.',

  //Expertises
  ACCOUNTING: 'Accounting',
  ADMINISTRATION: 'Administration',
  AIRLINES: 'Airlines / Aviation',
  ARCHITECTURE_PLANNING: 'Architecture & Planning',
  ARTS_CRAFTS: 'Arts & Crafts',
  AUTOMOTIVE: 'Automotive',
  AVIATION_AEROSPACE: 'Aviation & Aerospace',
  BANKING: 'Banking',
  BROADCAST_MEDIA: 'Broadcast Media',
  BUILDING_MATERIALS: 'Building Materials',
  CAPITAL_MARKETS: 'Capital Markets',
  CHEMICALS: 'Chemicals',
  CIVIL_ENGINEERING_CONSTRUCTION: 'Civil Engineering & Construction',
  COMMERCE_EXPERTISE: 'Commerce',
  CONSUMER_GOODS: 'Consumer Goods',
  CUSTOMER_SERVICE: 'Customer Service',
  DEFENSE_SPACE: 'Defense & Space',
  ECOMMERCE: 'E-commerce',
  EDUCATION: 'Education',
  ENGINEERING: 'Engineering',
  FINANCE: 'Finance',
  FMCG: 'FMCG',
  GOVERNMENT_PUBLIC_SECTOR: 'Government & Public Sector',
  GRAPHIC_DESIGN: 'Graphic Design',
  HOSPITAL: 'Hospital & Health Care',
  HOSPITALITY: 'Hospitality',
  HUMAN_RESOURCES: 'Human Resources',
  IMPORT_EXPORT: 'Import & Export',
  INDUSTRIAL_AUTOMATION: 'Industrial Automation',
  INFORMATION_TECHNOLOGY: 'Information Technology & Services',
  INSURANCE: 'Insurance',
  INVESTMENT_BANKING: 'Investment Banking',
  LAW_PRACTICE: 'Law Practice & Legal Services',
  LEISURE_TRAVEL_TOURISM: 'Leisure, Travel & Tourism',
  LOGISTICS_SUPPLY_CHAIN: 'Logistics & Supply Chain',
  MANAGEMENT: 'Management',
  MANAGEMENT_CONSULTING: 'Management Consulting',
  MARKETING_ADVERTISING: 'Marketing & Advertising',
  MECHANICAL: 'Mechanical or Industrial Engineering',
  MEDIA: 'Media',
  NGO: 'NGO',
  OFFICE_MANAGEMENT: 'Office Management',
  OIL_ENERGY: 'Oil & Energy',
  OPERATIONS_MANAGEMENT: 'Operations Management',
  OUTSOURCING_OFFSHORING: 'Outsourcing & Offshoring',
  PHARMACEUTICALS: 'Pharmaceuticals',
  PRINTING_PUBLISHING: 'Printing & Publishing',
  PROCESS_MANAGEMENT: 'Process Management',
  PRODUCTION: 'Production',
  PUBLIC_RELATIONS: 'Public Relations & Communications',
  PURCHASING: 'Purchasing',
  QHSE: 'QHSE',
  REAL_ESTATE: 'Real Estate',
  RESEARCH: 'Research',
  RESEARCH_DEVELOPMENT: 'Research & Development',
  RETAIL: 'Retail',
  SALES: 'Sales',
  SECURITY: 'Security',
  SERVICES: 'Services',
  SOFTWARE_DEVELOPMENT: 'Software Development',
  TECHNICAL_SUPPORT: 'Technical Support',
  TELECOMMUNICATIONS: 'Telecommunications',
  TRAINING_DEVELOPMENT: 'Training & Development',
  TRANSPORT: 'Transport',
  VENTURE_CAPITAL: 'Venture Capital & Private Equity',
  WAREHOUSING: 'Warehousing',

  //Sector
  AGRICULTURE: 'Agriculture',
  ARTS_CULTURE: 'Arts & Culture',
  AUDIT_ADVISORY: 'Audit & Advisory',
  AVIATION_AERONAUTICAL_DEFENSE: 'Aviation, Aeronautical & Defense',
  CIVIL_STRUCTURAL_ENGINEERING: 'Civil & Structural Engineering',
  COMMERCE_SECTOR: 'Commerce',
  CONSTRUCTION: 'Construction',
  FINANCE_ACCOUNTING: 'Finance & Accounting',
  HEALTHCARE_MEDICAL: 'Healthcare & Medical',
  INVESTMENT_PRIVATE_EQUITY: 'Investment & Private Equity',
  IT_SOFTWARE: 'IT & Software',
  LEGAL: 'Legal',
  MANUFACTURING_INDUSTRIES: 'Manufacturing & Industries',
  MARKETING_PR: 'Marketing & PR',
  MEDIA_PUBLISHING_PRINTING: 'Media, Publishing & Printing',
  OIL_GAS: 'Oil & Gas',
  PHARMACEUTICAL: 'Pharmaceutical',
  TOURISM_HOSPITALITY: 'Tourism & Hospitality',
  TRANSPORTATION: 'Transportation',

  //additional text - new version
  TAG_LINE_1_1: 'elevid is a video-based',
  TAG_LINE_1_2: 'hiring platform',
  TAG_LINE_2: 'Use elevid to find your dream job or to hire the best talent.',
  TAG_LINE_3: 'We match job seekers and employers anywhere!',
  ALREADY_HAVE_ACCOUNT: 'already have an account?',
  REGISTER_AS_JOB_SEEKER: 'Register as job seeker',
  REGISTER_AS_JOB_EMPLOYEUR: 'Register as employer',
  WHY_ELEVID: 'Why elevid ?',
  WHY_1: 'SAVE TIME',
  WHY_2: '100% FREE',
  WHY_3: 'DISCOVER SOFT SKILLS & PERSONALITY',
  WHY_4: 'PRIVACY',
  WHY_5: 'CHAT',
  ARG_1: '- watch 1 minute videos - no more CVs!',
  ARG_2: '- helping you focus on the hiring process',
  ARG_3: '- no more wasted interviews',
  ARG_4: '- only you control who you match with',
  ARG_5: '- use the built-in messenger',
  HOW_DOES_IT_WORK: 'How does it work?',
  HOW_1: '1. Record your video on smartphone or computer',
  HOW_2: '2. Matching',
  HOW_3: '3. Hired !',
  WELCOME_BACK: 'Welcome back to elevid !',
  FORGOT_PASSWORD_LOGIN: 'Forgot password?',
  CREATE_ACCOUNT: 'Create one',
  NO_ACCOUNT: 'No account? ',
};
