import * as React from 'react';

import content from '../../i18n/terms-en.html';
import { ComponentProps } from '../../typings';

interface Props extends ComponentProps {}

export function TermsContent({ style, className }: Props) {
  return <div style={style} className={className} dangerouslySetInnerHTML={{ __html: content }} />;
}
