import { Select } from 'antd';
import { countries } from 'countries-list';
import * as React from 'react';

import { useFixSelectTabIndex } from '../../hooks';
import { useMaxItems } from '../../hooks/useMaxItems';
import { SelectProps } from '../../typings/forms';

// @ts-ignore Exclude 'Western Sahara'
delete countries.EH;

type ValueType = string | string[];

interface Props extends SelectProps<ValueType> {
  maxItems?: number;
  showSearch?: boolean;
  tabIndex?: number;
}

const countryItems = Object.entries(countries)
  .map(([key, { name }]) => ({ key, name }))
  .sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  })
  .map(({ key, name }) => ({
    key: `${key} ${name}`,
    value: key,
    label: `${name} (${key})`,
  }));

const InternalCountrySelect = ({ maxItems, onChange, placeholder, showSearch = true, tabIndex, ...rest }: Props) => {
  const isMultiple = maxItems !== 1;
  const handleChange = useMaxItems(maxItems, onChange);
  const selectRef = useFixSelectTabIndex();

  return (
    <div ref={selectRef}>
      <Select
        style={{ width: '100%' }}
        placeholder={placeholder}
        options={countryItems}
        mode={isMultiple ? 'multiple' : undefined}
        onChange={handleChange}
        optionFilterProp="key"
        showSearch={showSearch}
        tabIndex={tabIndex}
        {...rest}
      />
    </div>
  );
};

export const CountrySelect = React.memo(InternalCountrySelect);
