import { Auth } from 'aws-amplify';
import type { StoreApi, UseStore } from 'zustand';

import { AuthUser, Profile, Roles } from '../typings/auth';
import { Locale } from '../typings/i18n';
import { cognitoUserRoles } from '../utils/constants';
import { captureException } from '../utils/sentry';
import { storeDecorator } from '../utils/storeDecorator';

interface AuthStoreType {
  user: AuthUser;
  roles: [Roles] | null;
  userRole: Roles | null;
  profile: Profile;
  loaded: boolean;
  isAuthenticated: boolean;
  setUser: (user: AuthUser | null) => void;
  setLoaded: Function;
  setProfile: (profile: Profile) => void;
  profileChecked: boolean;
  setProfileChecked: Function;
  signOut: () => void;
  updateLocale: (locale: Locale) => void;
}

const store = (set: Function, get: Function, api: StoreApi<AuthStoreType>) => ({
  user: null,
  roles: null,
  userRole: null,
  loaded: false,
  isAuthenticated: false,
  profile: null,
  profileChecked: false,
  setUser: (user: AuthUser = null): void =>
    set((state: AuthStoreType) => {
      state.user = user;
      state.isAuthenticated = !!user;
      if (state.isAuthenticated) {
        state.roles = user?.signInUserSession?.accessToken?.payload['cognito:groups'] ?? null;
        if (Array.isArray(state.roles)) {
          state.userRole = state.roles.find((role: string) => cognitoUserRoles.includes(role)) ?? null;
        }
      } else {
        state.roles = null;
        state.userRole = null;
      }
    }),
  setLoaded: (loaded: boolean = false) => set((state: AuthStoreType) => void (state.loaded = loaded)),
  setProfileChecked: (checked: boolean = false) => set((state: AuthStoreType) => void (state.profileChecked = checked)),
  setProfile: (profile: Profile = null) => set((state: AuthStoreType) => void (state.profile = profile)),
  signOut: () => {
    set((state: AuthStoreType) => void (state.profile = null));
    Auth.signOut();
  },
  updateLocale: async (locale: Locale) => {
    if (get().isAuthenticated) {
      try {
        const user = await Auth.currentAuthenticatedUser();

        await Auth.updateUserAttributes(user, { locale });
      } catch (e) {
        captureException(e);
      }
    }
  },
});

export const [useAuthStore, authStore]: [UseStore<AuthStoreType>, StoreApi<AuthStoreType>] = storeDecorator(store);
