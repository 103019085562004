import { lang } from '../../i18n/lang';

export const items = [
  { value: 'AGRI', label: lang.AGRICULTURE },
  { value: 'ARTS', label: lang.ARTS_CULTURE },
  { value: 'AUD', label: lang.AUDIT_ADVISORY },
  { value: 'AUTO', label: lang.AUTOMOTIVE },
  { value: 'AVIA', label: lang.AVIATION_AERONAUTICAL_DEFENSE },
  { value: 'BANK', label: lang.BANKING },
  { value: 'CIVIL', label: lang.CIVIL_STRUCTURAL_ENGINEERING },
  { value: 'CMRC', label: lang.COMMERCE_SECTOR },
  { value: 'CONS', label: lang.CONSTRUCTION },
  { value: 'EDU', label: lang.EDUCATION },
  { value: 'FIN', label: lang.FINANCE_ACCOUNTING },
  { value: 'FMCG', label: lang.FMCG },
  { value: 'GOV', label: lang.GOVERNMENT_PUBLIC_SECTOR },
  { value: 'HEAL', label: lang.HEALTHCARE_MEDICAL },
  { value: 'INS', label: lang.INSURANCE },
  { value: 'INV', label: lang.INVESTMENT_PRIVATE_EQUITY },
  { value: 'IT', label: lang.IT_SOFTWARE },
  { value: 'LEG', label: lang.LEGAL },
  { value: 'LOG', label: lang.LOGISTICS_SUPPLY_CHAIN },
  { value: 'MGMT', label: lang.MANAGEMENT_CONSULTING },
  { value: 'MAN', label: lang.MANUFACTURING_INDUSTRIES },
  { value: 'MKT', label: lang.MARKETING_PR },
  { value: 'MED', label: lang.MEDIA_PUBLISHING_PRINTING },
  { value: 'NGO', label: lang.NGO },
  { value: 'OIL', label: lang.OIL_GAS },
  { value: 'PHA', label: lang.PHARMACEUTICAL },
  { value: 'REST', label: lang.REAL_ESTATE },
  { value: 'RESE', label: lang.RESEARCH },
  { value: 'RET', label: lang.RETAIL },
  { value: 'SVC', label: lang.SERVICES },
  { value: 'TCOM', label: lang.TELECOMMUNICATIONS },
  { value: 'TOUR', label: lang.TOURISM_HOSPITALITY },
  { value: 'TRA', label: lang.TRANSPORTATION },
  { value: 'PROD', label: lang.PRODUCTION },
];
