import { I18n } from 'aws-amplify';
import * as React from 'react';
import { shallow } from 'zustand/shallow';

import { Page } from '../../components/Page';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { employerRole, investorRole, jobSeekerRole, startupRole } from '../../utils/constants';
import { ProfileEmployer } from '../ProfileEmployer';
import { ProfileInvestor } from '../ProfileInvestor';
import { ProfileJobSeeker } from '../ProfileJobSeeker';
import { ProfileStartup } from '../ProfileStartup';

const component = {
  [jobSeekerRole]: ProfileJobSeeker,
  [employerRole]: ProfileEmployer,
  [startupRole]: ProfileStartup,
  [investorRole]: ProfileInvestor,
};

export function Profile() {
  const { userRole, profile } = useAuthStore((store) => store, shallow);

  const Component: React.FunctionComponent | null = React.useMemo(
    () => (userRole ? component[userRole] : null),
    [userRole]
  );

  return (
    <Page
      type="private"
      browserTitle={I18n.get(lang.PROFILE)}
      title={I18n.get(lang.PROFILE)}
      subTitle={`${profile?.firstName} ${profile?.lastName}`}
    >
      {Component ? <Component /> : null}
    </Page>
  );
}
