import { ConfigProvider, Layout, Space, Spin } from 'antd';
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';
import { CollapseType } from 'antd/lib/layout/Sider';
import classNames from 'classnames';
import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import styles from './App.module.less';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Menu } from './components/Header/components/Menu';
import { PageHeader } from './components/PageHeader/PageHeader';
import { PrivateRoute } from './components/PrivateRoute';
import { UnauthenticatedRoute } from './components/UnauthenticatedRoute';
import { useAuthListener, useCheckAuth, useGA, useMessenger, useNotifications } from './hooks';
import { ConfirmEmail } from './pages/ConfirmEmail';
import { Dashboard } from './pages/Dashboard';
import { ForgotPassword } from './pages/ForgotPassword';
import { Home } from './pages/Home';
import { JobSeekersSearchResult } from './pages/JobSeekersSearchResult';
import { Login } from './pages/Login';
import { Messenger } from './pages/Messenger';
import { NotificationsList } from './pages/NotificationsList';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { Profile } from './pages/Profile';
import { RegisterEmployer } from './pages/RegisterEmployer';
import { RegisterInvestor } from './pages/RegisterInvestor';
import { RegisterJobSeeker } from './pages/RegisterJobSeeker';
import { RegisterStartup } from './pages/RegisterStartup';
import { ResetPassword } from './pages/ResetPassword';
import { SearchJobSeeker } from './pages/SearchJobSeeker';
import { SearchStartup } from './pages/SearchStartup';
import { Signup } from './pages/Signup';
import { SignupPlans } from './pages/SignupPlans';
import { StartupsSearchResult } from './pages/StartupsSearchResult';
import { Terms } from './pages/Terms';
import { UpdateJobSeekerSearch } from './pages/UpdateJobSeekerSearch';
import { UpdateStartupSearch } from './pages/UpdateStartupSearch/UpdateStartupSearch';
import { useCurrentPageStore } from './stores/currentPage';
import { useI18nStore } from './stores/i18n';
import { APP_NAVIGATION_ROUTES } from './utils/constants';
import variables from './variables.less';

function App() {
  useGA();
  useCheckAuth();

  const [menuCanCollapse, setMenuCanCollapse] = React.useState(false);
  const [menuCollapsed, setMenuCollapsed] = React.useState(false);
  const location = useLocation();
  const currentPage = useCurrentPageStore((store) => store.currentPage);

  const { loaded: authLoaded, isAuthenticated, profile, profileChecked, userRole } = useAuthListener();

  const { numberOfUnreadMessages } = useMessenger({ profile, userRole });
  const { numberOfUnreadNotifications } = useNotifications(profile, userRole);
  const locale = useI18nStore((store) => store.locale);

  React.useEffect(() => {
    if (menuCanCollapse) {
      setMenuCollapsed(true);
    }
  }, [location.pathname, menuCanCollapse]);

  const handleMenuCollapse = (collapsed: boolean, type: CollapseType) => {
    setMenuCollapsed(collapsed);

    if (type === 'responsive') {
      setMenuCanCollapse(collapsed);
    }
  };

  if (!authLoaded || (isAuthenticated && !profileChecked)) {
    return (
      <Space className="loader-content" size="large" align="center" style={{ width: '100%', height: 'auto' }}>
        <Spin size="large" />
      </Space>
    );
  }

  return (
    <ConfigProvider locale={locale === 'fr' ? frFR : enUS}>
      <Layout
        className={classNames(styles.container, {
          viewportHeightPage: currentPage.viewportHeightPage,
          [styles.bigHeader]: currentPage.bigLogo,
        })}
      >
        <Layout.Header
          className={classNames(styles.header, {
            [styles.smallHeader]: currentPage.bigLogo,
            [styles.homeIconStyling]: currentPage.homeIcon,
          })}
        >
          <Header
            bigLogo={currentPage.bigLogo}
            showLocaleSelect={currentPage.localeSelect}
            showLoginButton={currentPage.loginButton}
            showLogoutButton={currentPage.logoutButton}
            showRegisterButton={currentPage.registerButton}
            showHomeIcon={currentPage.homeIcon}
          />
        </Layout.Header>

        <Layout.Content style={{ display: 'flex', flexDirection: 'column' }}>
          {currentPage.title ? <PageHeader subTitle={currentPage.subTitle}>{currentPage.title}</PageHeader> : null}
          <Layout>
            {currentPage.sideMenu ? (
              <Layout.Sider
                breakpoint="md"
                collapsedWidth={variables.siderCollapsedWidth}
                collapsed={menuCollapsed}
                onCollapse={handleMenuCollapse}
              >
                <Menu
                  numberOfUnreadNotifications={numberOfUnreadNotifications}
                  numberOfUnreadMessages={numberOfUnreadMessages}
                />
              </Layout.Sider>
            ) : null}
            <Layout.Content style={{ display: 'flex', flexDirection: 'column' }}>
              <Switch>
                <PrivateRoute exact path={APP_NAVIGATION_ROUTES.DASHBOARD_ROUTE} component={Dashboard} />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.JOBSEEKER_REGISTRATION_ROUTE}
                  component={RegisterJobSeeker}
                />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.STARTUP_REGISTRATION_ROUTE}
                  component={RegisterStartup}
                />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.EMPLOYER_REGISTRATION_ROUTE}
                  component={RegisterEmployer}
                />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.INVESTOR_REGISTRATION_ROUTE}
                  component={RegisterInvestor}
                />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.JOBSEEKERS_ADD_SEARCH_ROUTE}
                  component={SearchJobSeeker}
                />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.UPDATE_JOB_SEEKER_SEARCH_ROUTE}
                  component={UpdateJobSeekerSearch}
                />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.JOBSEEKERS_SEARCH_RESULTS_ROUTE}
                  component={JobSeekersSearchResult}
                />
                <PrivateRoute exact path={APP_NAVIGATION_ROUTES.STARTUPS_ADD_SEARCH_ROUTE} component={SearchStartup} />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.UPDATE_STARTUP_SEARCH_ROUTE}
                  component={UpdateStartupSearch}
                />
                <PrivateRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.STARTUPS_SEARCH_RESULTS_ROUTE}
                  component={StartupsSearchResult}
                />
                <PrivateRoute exact path={APP_NAVIGATION_ROUTES.PROFILE_ROUTE} component={Profile} />
                <PrivateRoute exact path={APP_NAVIGATION_ROUTES.CHAT_ROUTE} component={Messenger} />
                <PrivateRoute exact path={APP_NAVIGATION_ROUTES.NOTIFICATIONS_ROUTE} component={NotificationsList} />

                <UnauthenticatedRoute exact path={APP_NAVIGATION_ROUTES.HOME_ROUTE} component={Home} />
                <UnauthenticatedRoute exact path={APP_NAVIGATION_ROUTES.LOGIN_ROUTE} component={Login} />
                <UnauthenticatedRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.FORGOT_PASSWORD_ROUTE}
                  component={ForgotPassword}
                />
                <UnauthenticatedRoute
                  exact
                  path={APP_NAVIGATION_ROUTES.RESET_PASSWORD_ROUTE}
                  component={ResetPassword}
                />
                <UnauthenticatedRoute exact path={APP_NAVIGATION_ROUTES.SIGN_UP_PLANS_ROUTE} component={SignupPlans} />
                <UnauthenticatedRoute exact path={APP_NAVIGATION_ROUTES.SIGN_UP_ROUTE} component={Signup} />
                <UnauthenticatedRoute exact path={APP_NAVIGATION_ROUTES.CONFIRM_EMAIL_ROUTE} component={ConfirmEmail} />
                <Route exact path={APP_NAVIGATION_ROUTES.TERMS_ROUTE} component={Terms} />
                <Route exact path={APP_NAVIGATION_ROUTES.PRIVACY_ROUTE} component={PrivacyPolicy} />

                <Redirect to={APP_NAVIGATION_ROUTES.HOME_ROUTE} />
              </Switch>
            </Layout.Content>
          </Layout>
        </Layout.Content>
        {currentPage.footer ? <Footer /> : null}
      </Layout>
    </ConfigProvider>
  );
}

export default App;
