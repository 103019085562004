import { Col, Form, Row } from 'antd';
import * as React from 'react';

import { FileInput } from '../../FileInput';

export function SupportingDocument() {
  return (
    <Row justify="center" align="middle">
      <Col xs={20} sm={20} md={14} lg={14}>
        <Form.Item name="supportDoc">
          <FileInput accept={'.pdf, .doc, .docx, .jpg, .png, .gif'} />
        </Form.Item>
      </Col>
    </Row>
  );
}
