import { LabeledValue } from 'antd/lib/select';
import { pipe, slice, trim } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

export type ValueType = { min: number; max?: number } | null;
export type RangesById = { [id: string]: { min: number; max?: number } };

const separationChar = '—';

type FormatOptions = {
  boundaryPrefix?: string;
  boundaryFormat?: (boundary: number, value?: ValueType) => string | number;
  hideLastItemPlusSign?: boolean;
};

export const formatBoundaries = (boundaries: number[], options: FormatOptions) => {
  return [...boundaries]
    .sort((a, b) => a - b)
    .reduce(
      (acc: { options: LabeledValue[]; rangesById: RangesById }, curr: number, i, arr) => {
        const lastOptionIndex = acc.options.length - 1;
        if (i % 2 === 0) {
          if (i === arr.length - 1) {
            const rangeId = uuidv4();
            acc.rangesById[rangeId] = { min: curr, max: undefined };
            acc.options[lastOptionIndex + 1] = { label: formatRange(acc.rangesById[rangeId], options), value: rangeId };
          } else {
            // Label & correct value will be set in the next iteration
            acc.options[lastOptionIndex + 1] = { label: '', value: curr };
          }
        } else {
          if (i !== arr.length - 1 || arr.length % 2 === 0) {
            const rangeId = uuidv4();
            const min: number = acc.options[lastOptionIndex].value as number;

            acc.rangesById[rangeId] = { min, max: curr };
            acc.options[lastOptionIndex].value = rangeId;
            acc.options[lastOptionIndex].label = formatRange(acc.rangesById[rangeId], options);
          }
        }

        return acc;
      },
      { options: [], rangesById: {} }
    );
};

export const extractValueFromLabel = (label: string = '') => {
  if (label.includes(separationChar)) {
    const [min, max] = label.split(separationChar).map(pipe(trim, parseInt));

    return { min, max };
  } else if (label.endsWith('+')) {
    const extractValueFromMaxItem = pipe(slice(0, -1) as (s: string) => string, trim, parseInt);
    const min = extractValueFromMaxItem(label);

    return { min, max: undefined };
  }
};

export const formatRange = (
  value: { min: number; max?: number } | null,
  { boundaryPrefix, boundaryFormat, hideLastItemPlusSign }: FormatOptions
) => {
  if (!value) return value;

  const { min, max } = value;
  if (typeof min === 'number' && max === undefined) {
    const minLabel = typeof boundaryFormat === 'function' ? boundaryFormat(min, value) : min;
    return `${minLabel}${boundaryPrefix ?? ''}${hideLastItemPlusSign ? '' : ' +'}`;
  } else if (typeof min === 'number' && typeof max === 'number') {
    const minLabel = typeof boundaryFormat === 'function' ? boundaryFormat(min, value) : min;
    const maxLabel = typeof boundaryFormat === 'function' ? boundaryFormat(max, value) : max;
    return `${minLabel}${boundaryPrefix ?? ''} ${separationChar} ${maxLabel}${boundaryPrefix ?? ''}`;
  }
};

export const matchRangeId = ({
  rangesById,
  value,
}: {
  rangesById: RangesById;
  value?: ValueType;
}): string | undefined => {
  if (!value) return undefined;

  const { min, max } = value;

  for (const [rangeId, { min: rangeMin, max: rangeMax }] of Object.entries(rangesById)) {
    if (typeof max === 'number') {
      if (typeof rangeMax === 'number') {
        if (max <= rangeMax && max >= rangeMin) return rangeId;
      }
      // Generally this implies to the last range item
      else {
        if (max >= rangeMin) return rangeId;
      }
    } else {
      if (typeof rangeMax === 'number') {
        if (min < rangeMax && min >= rangeMin) return rangeId;
      }
      // Generally this implies to the last range item
      else {
        if (min >= rangeMin) return rangeId;
      }
    }
  }

  return undefined;
};
