import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import * as React from 'react';

import styles from './StageStatus.module.less';

export type Status = 'pending' | 'ongoing' | 'done' | 'failed';

interface Props {
  status: Status;
}

export function StageStatus({ status }: Props) {
  switch (status) {
    case 'ongoing':
      return <LoadingOutlined className={styles.primary} spin />;

    case 'done':
      return <CheckOutlined className={styles.success} />;

    case 'failed':
      return <CloseOutlined className={styles.error} />;

    default:
      return null;
  }
}
