import classNames from 'classnames';
import * as React from 'react';

import { ComponentWithChildrenProps } from '../../typings';

interface Props extends ComponentWithChildrenProps {}

export function AuthFormWrapper({ style, className, children }: Props) {
  return (
    <div className={classNames(className)} style={{ width: '100%', maxWidth: 350, ...style }}>
      {children}
    </div>
  );
}
