/* eslint-disable no-template-curly-in-string */

import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Col, Form, Grid, Input, Row, Spin } from 'antd';
import { gql } from 'apollo-boost';
import { I18n, Storage } from 'aws-amplify';
import moment from 'moment';
import { omit } from 'ramda';
import * as React from 'react';
import { useAsync, useAsyncFn } from 'react-use';

import { CountrySelect } from '../../components/CountrySelect';
import { DateSelect } from '../../components/DateSelect';
import { FileInput } from '../../components/FileInput';
import { PhoneInput } from '../../components/PhoneInput';
import { ProfileSaveProgress, ProfileSaveStage } from '../../components/ProfileSaveProgress';
import { VideoInput } from '../../components/VideoInput';
import { updateProfileInvestor } from '../../graphql/mutations';
import { getVideoDataById as getVideoDataByIdQuery } from '../../graphql/queries';
import { lang } from '../../i18n/lang';
import { useAuthStore } from '../../stores/auth';
import { getUploadFileObjectFromUrl, validateBirthDate } from '../../utils/helpers';
import { captureException } from '../../utils/sentry';
import { uploadVideo } from '../../utils/video-helpers';

import styles from './ProfileInvestor.module.less';

const maxLocationsItems = 5;

const UPDATE_INVESTOR_PROFILE = gql(updateProfileInvestor);
const GET_VIDEO_DATA_BY_ID = gql(getVideoDataByIdQuery);

const { useBreakpoint } = Grid;

export const ProfileInvestor = () => {
  const notif = useBreakpoint();
  const [form] = Form.useForm();
  const { profile, setProfile }: any = useAuthStore((store) => store);
  const initialVideoFileId = React.useRef<string>('');
  const initialDocumentFileId = React.useRef<string>('');
  const [profileSaveStage, setProfileSaveStage] = React.useState<ProfileSaveStage>('none');
  const [profileSaveFailed, setProfileSaveFailed] = React.useState(false);

  const { loading: loadingDocument } = useAsync(
    () =>
      profile.document
        ? Storage.get(profile.document, {
            level: 'protected',
            expires: 120,
          })
            .then((url) => getUploadFileObjectFromUrl(url, { fileName: profile.document }))
            .then((file) => {
              initialDocumentFileId.current = file.uid;
              form.setFieldsValue({ supportDoc: [file] });
            })
        : Promise.resolve(null),
    [profile.document]
  );

  const [{ loading: loadingVideoFile }, getVideoFile] = useAsyncFn(
    (value) =>
      getUploadFileObjectFromUrl(value?.getVideoDataById?.assets?.mp4, {
        fileName: I18n.get(lang.MY_VIDEO),
        mimeType: 'video/mp4',
      }).then((file) => {
        initialVideoFileId.current = file.uid;
        form.setFieldsValue({ videoRec: file });
      }),
    []
  );

  const { loading: loadingVideoMetaData } = useQuery(GET_VIDEO_DATA_BY_ID, {
    variables: { id: profile.video },
    onCompleted: getVideoFile,
  });

  const [updateInvestorProfile, { data, loading: loadingUpdateInvestor, called: updateInvestorProfileCalled }] =
    useMutation(UPDATE_INVESTOR_PROFILE);

  React.useEffect(() => {
    if (updateInvestorProfileCalled && !loadingUpdateInvestor && data) {
      setProfile(data?.updateProfileInvestor);
    }
  }, [updateInvestorProfileCalled, data, loadingUpdateInvestor, setProfile]);

  const onFinish = React.useCallback(
    async ({ videoRec, supportDoc, ...input }: any) => {
      try {
        setProfileSaveFailed(false);
        setProfileSaveStage('video');

        let videoUploadResponse;

        if (initialVideoFileId.current !== videoRec.uid) {
          videoUploadResponse = await uploadVideo({ video: videoRec, name: profile?.email });
        }

        let supportDocUploadResponse: { key?: string } | null = null;

        if (supportDoc) {
          const document = supportDoc[0];

          if (document && initialDocumentFileId.current !== document.uid) {
            setProfileSaveStage('doc');

            supportDocUploadResponse = await Storage.put(document.name, document.originFileObj, {
              contentType: document.type,
              level: 'protected',
            });
          }
        }

        const mutationProps = {
          variables: {
            input: {
              ...omit(['updatedAt', 'createdAt', '__typename'], profile),
              ...input,
              phone: input.phone.prefix && input.phone.number ? input.phone : null,
              userId: profile?.userId ?? null,
              birthDate: moment(input?.birthDate).format('YYYY-MM-DD'),
              email: profile?.email,
              video: videoUploadResponse ? videoUploadResponse.videoId : profile?.video,
              document:
                !supportDoc || supportDoc?.length === 0 ? null : supportDocUploadResponse?.key ?? profile?.document,
            },
          },
        };

        setProfileSaveStage('profile');
        await updateInvestorProfile(mutationProps);

        setProfileSaveStage('done');
      } catch (err) {
        captureException(err);
        setProfileSaveFailed(true);
      }
    },
    [profile, updateInvestorProfile]
  );

  const handleSaveProgressClose = () => {
    setProfileSaveStage('none');
  };

  const [{ loading: loadingSubmit }, handleSubmit] = useAsyncFn(onFinish, [updateInvestorProfile, profile]);
  const loading = loadingVideoMetaData || loadingVideoFile || loadingDocument;

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        hideRequiredMark
        layout="vertical"
        initialValues={{
          firstName: profile?.firstName,
          companyName: profile?.companyName,
          lastName: profile?.lastName,
          phone: omit(['__typename'], profile?.phone),
          birthDate: moment(profile?.birthDate),
          locations: profile?.locations,
          videoRec: null,
          supportDoc: null,
        }}
        onFinish={handleSubmit}
      >
        <fieldset>
          <legend>{I18n.get(lang.PERSONAL_INFORMATION)}</legend>

          <Row>
            <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 1 : 1}>
              <Form.Item
                hasFeedback
                label={<b>{I18n.get(lang.LABEL_FIRST_NAME)}</b>}
                name="firstName"
                rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_FIRST_NAME) }]}
              >
                <Input tabIndex={1} />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 2 : 4}>
              <Form.Item hasFeedback label={<b>{I18n.get(lang.LABEL_COMPANY_NAME_OPTIONAL)}</b>} name="companyName">
                <Input tabIndex={4} />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 3 : 2}>
              <Form.Item
                hasFeedback
                label={<b>{I18n.get(lang.LABEL_LAST_NAME)}</b>}
                name="lastName"
                rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LAST_NAME) }]}
              >
                <Input tabIndex={2} />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 10, offset: 2 }} order={notif.md === true ? 4 : 5}>
              <Form.Item label={<b>{I18n.get(lang.LABEL_PHONE)}</b>} name="phone">
                <PhoneInput tabIndex={5} />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 10, offset: 1 }} order={notif.md === true ? 5 : 3}>
              <Form.Item
                label={<b>{I18n.get(lang.LABEL_BIRTH_DATE)}</b>}
                name="birthDate"
                rules={[
                  { required: true, message: I18n.get(lang.VALIDATOR_MSG_BIRTH_DATE) },
                  { validator: validateBirthDate },
                ]}
              >
                <DateSelect tabIndex={3} />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>

        <fieldset>
          <legend>{I18n.get(lang.QUESTIONNAIRE)}</legend>

          <Row>
            <Col span={24} md={{ span: 10, offset: 1 }}>
              <Form.Item
                hasFeedback
                label={
                  <div>
                    <b>{I18n.get(lang.LABEL_LOCATIONS)}</b>
                    <br />
                    <span className={styles.labelInfo}>
                      {I18n.get(lang.SELECT_LIMITED_OPTIONS).replace('${count}', maxLocationsItems)}
                    </span>
                  </div>
                }
                name="locations"
                rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_LOCATIONS) }]}
              >
                <CountrySelect tabIndex={6} maxItems={maxLocationsItems} />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>

        <fieldset>
          <legend>{I18n.get(lang.VIDEO)}</legend>

          <Row>
            <Col span={24}>
              <Form.Item name="videoRec" rules={[{ required: true, message: I18n.get(lang.VALIDATOR_MSG_VIDEO) }]}>
                <VideoInput
                  messages={{
                    uploadTitle: I18n.get(lang.VIDEO_UPLOAD_TITLE_EMPLOYER),
                    uploadDescription: I18n.get(lang.VIDEO_UPLOAD_DESCRIPTION_EMPLOYER),
                    recordTitle: I18n.get(lang.VIDEO_RECORD_TITLE_EMPLOYER),
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>

        <fieldset>
          <legend>{I18n.get(lang.SUPPORTING_DOCUMENT)}</legend>
          <Row justify="center" align="middle">
            <Col xs={20} sm={20} md={20} lg={18} xl={14}>
              <Form.Item name="supportDoc">
                <FileInput accept={'.pdf, .doc, .docx, .jpg, .png, .gif'} />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
        <Row align="bottom" justify="end" style={{ padding: '36px' }}>
          <Col>
            <Button loading={loadingSubmit} className={styles.actionButton} type="primary" htmlType="submit">
              {I18n.get(lang.UPDATE)}
            </Button>
          </Col>
        </Row>
      </Form>
      <ProfileSaveProgress stage={profileSaveStage} failed={profileSaveFailed} onClose={handleSaveProgressClose} />
    </Spin>
  );
};
