import { invertObj } from 'ramda';
import { generatePath } from 'react-router';

import { Roles } from '../typings';

export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/';
export const DASHBOARD_ROUTE = '/dashboard/:profileId?';
export const DEFAULT_DASHBOARD_ROUTE = generatePath(DASHBOARD_ROUTE, {}); // remove profileId param
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';
export const SIGN_UP_PLANS_ROUTE = '/register';
export const CONFIRM_EMAIL_ROUTE = '/verify-code';
export const PROFILE_ROUTE = `/profile`;
export const CHAT_ROUTE = '/chat/:senderId?';
export const DEFAULT_CHAT_ROUTE = generatePath(CHAT_ROUTE, {}); // remove senderId param
export const NOTIFICATIONS_ROUTE = '/notifications';
export const PRIVACY_ROUTE = '/privacy';
export const TERMS_ROUTE = '/terms';

export const jobSeekerType = 'job-seeker';
export const startupType = 'startup';
export const investorType = 'investor';
export const employerType = 'employer';

export const JOBSEEKER_REGISTRATION_ROUTE = `/${jobSeekerType}-registration`;
export const STARTUP_REGISTRATION_ROUTE = `/${startupType}-registration`;
export const EMPLOYER_REGISTRATION_ROUTE = `/${employerType}-registration`;
export const INVESTOR_REGISTRATION_ROUTE = `/${investorType}-registration`;

export const jobSeekerRole = 'JobSeekers';
export const investorRole = 'Investors';
export const startupRole = 'Startups';
export const employerRole = 'Employers';
export const cognitoUserRoles = [jobSeekerRole, investorRole, startupRole, employerRole];

export const userTypesRolesMap = {
  [jobSeekerType]: jobSeekerRole,
  [employerType]: employerRole,
  [startupType]: startupRole,
  [investorType]: investorRole,
};

export const rolesUserTypesMap = invertObj(userTypesRolesMap);

export const getRegistrationRouteByRole = (role: Roles) => `/${rolesUserTypesMap[role]}-registration`;

export const SIGN_UP_ROUTE = `/register/:type(${Object.keys(userTypesRolesMap).join('|')})`;

export const JOB_SEEKER_SIGN_UP_ROUTE = `/register/${jobSeekerType}`;
export const EMPLOYER_SIGN_UP_ROUTE = `/register/${employerType}`;
export const STARTUP_SIGN_UP_ROUTE = `/register/${startupType}`;
export const INVESTOR_SIGN_UP_ROUTE = `/register/${investorType}`;

export const JOBSEEKERS_SEARCH_ROUTE = '/jobseekers-search';
export const JOBSEEKERS_ADD_SEARCH_ROUTE = `${JOBSEEKERS_SEARCH_ROUTE}/add`;
export const JOBSEEKERS_SEARCH_RESULTS_ROUTE = `${JOBSEEKERS_SEARCH_ROUTE}/:id`;
export const UPDATE_JOB_SEEKER_SEARCH_ROUTE = `${JOBSEEKERS_SEARCH_ROUTE}/update/:id`;
export const STARTUPS_SEARCH_ROUTE = '/startups-search';
export const STARTUPS_ADD_SEARCH_ROUTE = `${STARTUPS_SEARCH_ROUTE}/add`;
export const STARTUPS_SEARCH_RESULTS_ROUTE = `${STARTUPS_SEARCH_ROUTE}/:id`;
export const UPDATE_STARTUP_SEARCH_ROUTE = `${STARTUPS_SEARCH_ROUTE}/update/:id`;

export const APP_NAVIGATION_ROUTES = {
  LOGIN_ROUTE,
  HOME_ROUTE,
  DASHBOARD_ROUTE,
  DEFAULT_DASHBOARD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
  SIGN_UP_PLANS_ROUTE,
  SIGN_UP_ROUTE,
  CONFIRM_EMAIL_ROUTE,
  JOB_SEEKER_SIGN_UP_ROUTE,
  EMPLOYER_SIGN_UP_ROUTE,
  STARTUP_SIGN_UP_ROUTE,
  INVESTOR_SIGN_UP_ROUTE,
  JOBSEEKER_REGISTRATION_ROUTE,
  STARTUP_REGISTRATION_ROUTE,
  EMPLOYER_REGISTRATION_ROUTE,
  INVESTOR_REGISTRATION_ROUTE,
  JOBSEEKERS_SEARCH_ROUTE,
  JOBSEEKERS_ADD_SEARCH_ROUTE,
  JOBSEEKERS_SEARCH_RESULTS_ROUTE,
  STARTUPS_SEARCH_ROUTE,
  STARTUPS_ADD_SEARCH_ROUTE,
  STARTUPS_SEARCH_RESULTS_ROUTE,
  PROFILE_ROUTE,
  CHAT_ROUTE,
  DEFAULT_CHAT_ROUTE,
  NOTIFICATIONS_ROUTE,
  UPDATE_JOB_SEEKER_SEARCH_ROUTE,
  UPDATE_STARTUP_SEARCH_ROUTE,
  PRIVACY_ROUTE,
  TERMS_ROUTE,
};
