// https://github.com/apollographql/react-apollo/issues/3499#issuecomment-539346982
import { useApolloClient } from '@apollo/react-hooks';
import type { DocumentNode, OperationVariables } from 'apollo-boost';
import * as React from 'react';

export function useImperativeLazyQuery<TData = any, TVariables = OperationVariables>(query: DocumentNode) {
  const client = useApolloClient();

  return React.useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query,
        variables,
      }),
    [client, query]
  );
}
