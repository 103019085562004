import { Badge } from 'antd';
import classNames from 'classnames';
import * as React from 'react';
import { useAsync } from 'react-use';

import { VideoAssets } from '../../../../typings';
import { VideoPreview } from '../../../VideoPreview';

import styles from './Profile.module.less';

export interface ProfileClickProps {
  matchId: string;
  profileId: string;
  identityId: string;
  title: string;
  document: string;
  videoAssets?: VideoAssets;
}

export type ProfileProps = {
  videoId: string;
  title: string;
  getVideoAssets: (videoId: string) => Promise<VideoAssets>;
  onProfileClick: (props: ProfileClickProps) => void;
  handleChangeProps: Omit<ProfileClickProps, 'videoAssets'>;
  isCurrent: boolean;
  unreadMessagesCount?: number;
};

export const Profile = ({
  handleChangeProps,
  videoId,
  title,
  getVideoAssets,
  onProfileClick,
  isCurrent,
  unreadMessagesCount = 0,
}: ProfileProps) => {
  const { value: videoAssets } = useAsync(() => getVideoAssets(videoId), [videoId]);
  const previewRef = React.useRef<HTMLVideoElement>();

  if (!isCurrent && previewRef.current) {
    previewRef.current?.pause();
  }

  return (
    <div
      className={classNames(styles.container, { [styles.currentChat]: isCurrent })}
      onClick={() => onProfileClick({ ...handleChangeProps, videoAssets })}
    >
      <VideoPreview
        ref={previewRef}
        src={videoAssets?.mp4}
        poster={videoAssets?.thumbnail}
        style={{ pointerEvents: isCurrent ? 'initial' : 'none' }}
      />
      <div className={styles.videoSlideTitle}>
        {title} <Badge count={unreadMessagesCount} />
      </div>
    </div>
  );
};
