import classNames from 'classnames';
import * as React from 'react';

import { useCurrentPageStore } from '../../stores/currentPage';
import type { ComponentWithChildrenProps } from '../../typings/components';
import { CurrentPage, PageType } from '../../typings/page';

import styles from './Page.module.less';

type PageLayout = 'default' | 'full' | 'center' | 'topCenter';

interface PageProps extends ComponentWithChildrenProps, Partial<CurrentPage> {
  type: PageType;
  layout?: PageLayout;
}

export const Page = ({
  style,
  className,
  children,

  browserTitle,
  title,
  subTitle,
  bigLogo,
  loginButton,
  logoutButton,
  registerButton,
  sideMenu,
  viewportHeightPage,
  homeIcon,

  type,
  layout = 'default',
}: PageProps) => {
  const setCurrentPage = useCurrentPageStore((store) => store.setCurrentPage);

  React.useEffect(() => {
    setCurrentPage(
      {
        browserTitle,
        title,
        subTitle,
        bigLogo,
        loginButton,
        logoutButton,
        registerButton,
        sideMenu,
        viewportHeightPage,
        homeIcon,
      },
      type
    );
  }, [
    bigLogo,
    browserTitle,
    loginButton,
    logoutButton,
    registerButton,
    setCurrentPage,
    sideMenu,
    subTitle,
    title,
    type,
    viewportHeightPage,
    homeIcon,
  ]);

  return (
    <div className={classNames(styles.container, layout, className)} style={style}>
      {children}
    </div>
  );
};
