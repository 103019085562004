import { ArrowLeftOutlined, DownloadOutlined, SendOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Form, Input, Layout, Row, Space, Spin } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import NoMessages from '../../assets/NoMessages.svg';
import { lang } from '../../i18n/lang';

import styles from './ChatMessages.module.less';
import { Message } from './components/Message';

const { Header, Content } = Layout;

export const ChatMessages = ({
  currentUserId,
  discussionTitle,
  messages = [],
  sendMessage,
  downloadDocument,
  handleSeen,
  fetchMoreMessages,
  hasMoreMessages,
  loading,
  onShowVideo,
  onBack,
}: any) => {
  const [form] = Form.useForm();
  const messagesContainerRef = React.useRef<any>(null);
  const messageInputRef = React.useRef<any>(null);
  const handleSeenTimeoutRef = React.useRef<any>(null);
  const handleSeenRef = React.useRef<any>(handleSeen);
  const lastMessageId = messages[0]?.id;

  // Set timer to mark messages as read on mount and when window becomes focused
  React.useEffect(() => {
    const start = () => {
      if (handleSeenRef.current) {
        handleSeenTimeoutRef.current = setTimeout(() => {
          handleSeenRef.current();
          handleSeenTimeoutRef.current = null;
        }, 5000);
      }
    };

    const clear = () => {
      if (handleSeenTimeoutRef.current) {
        clearTimeout(handleSeenTimeoutRef.current);
        handleSeenTimeoutRef.current = null;
      }
    };

    window.addEventListener('focus', start);
    window.addEventListener('blur', clear);
    start();

    // return cleanup function
    return () => {
      window.removeEventListener('focus', start);
      window.removeEventListener('blur', clear);
      clear();
    };
  }, []);

  // Scroll down and if window has focus immediately mark message as read for every new message received
  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;

      if (handleSeenRef.current && window.document.hasFocus() && !handleSeenTimeoutRef.current) {
        handleSeenRef.current();
      }
    }
  }, [lastMessageId]);

  // Update handleSeen ref
  React.useEffect(() => {
    handleSeenRef.current = handleSeen;
  }, [handleSeen]);

  return (
    <Layout className={styles.container}>
      <Header className={styles.header}>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Col className={styles.backButton}>
            <Button type="text" onClick={onBack}>
              <ArrowLeftOutlined />
            </Button>
          </Col>
          <Col style={{ flex: '1 0 auto', fontSize: 18, fontWeight: 'bold' }}>{discussionTitle}</Col>
          <Col>
            <Space>
              <Button className={styles.showVideo} type="ghost" onClick={onShowVideo}>
                <VideoCameraOutlined />
              </Button>
              {downloadDocument ? (
                <Button className={styles.downloadFile} type="ghost" onClick={downloadDocument}>
                  <DownloadOutlined className={styles.downloadIcon} />
                  <span className={styles.downloadText}>{I18n.get(lang.DOWNLOAD_DOC_BTN)}</span>
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>
      </Header>
      <Content style={{ display: 'flex', flexDirection: 'column' }}>
        {loading || messages.length === 0 ? (
          <Row style={{ flex: 'auto' }} align="middle" justify="center">
            <Col>{loading ? <Spin /> : <Empty description={I18n.get(lang.BRAND_NEW_CHAT)} image={NoMessages} />}</Col>
          </Row>
        ) : (
          <div id="scrollableMessagesDiv" className={styles.listMessages} ref={messagesContainerRef}>
            {/*Put the scroll bar always on the bottom*/}
            <InfiniteScroll
              dataLength={messages.length}
              next={fetchMoreMessages}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              inverse={true} //
              hasMore={hasMoreMessages}
              loader={<Spin />}
              scrollableTarget="scrollableMessagesDiv"
            >
              {messages.map(({ id, senderId, text, createdAt, read }: any) => {
                const isMe = senderId === currentUserId;
                return (
                  <div key={id} className={isMe ? styles.message : styles.otherMessage}>
                    <Message isMe={isMe} text={text} createdAt={createdAt} read={read} />
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </Content>
      <Form
        form={form}
        onFinish={(...params) => {
          sendMessage(...params);
          form.resetFields();
          messageInputRef.current.focus();
        }}
        initialValues={{ message: '' }}
      >
        <Space className={styles.messageInputContainer}>
          <Form.Item name="message">
            <Input size="large" ref={messageInputRef} autoComplete="off" />
          </Form.Item>
          <Button type="primary" icon={<SendOutlined />} size="large" htmlType="submit" />
        </Space>
      </Form>
    </Layout>
  );
};
