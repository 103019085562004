import { Radio, Select } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { I18n } from 'aws-amplify';
import * as React from 'react';

import { lang } from '../../i18n/lang';
import { ComponentProps, SelectProps } from '../../typings';

import { ValueType, formatBoundaries, matchRangeId } from './shared';

const OPTION_ANY_RADIO_VALUE = '';
const OPTION_ANY_VALUE = null;

interface Props extends ComponentProps, SelectProps<ValueType> {
  boundaries: number[];
  radio?: boolean;
  boundaryPrefix?: string;
  boundaryFormat?: (boundary: number, value?: ValueType) => string | number;
  hideLastItemPlusSign?: boolean;
  tabIndex?: number;
  addAny?: boolean; // used only if radio=true
}

const radioButtonStyles: React.CSSProperties = {
  marginRight: '30px',
};

const InternalRangeSelect = ({
  style,
  boundaries,
  placeholder,
  onChange,
  value: valueObject = undefined,
  radio = true,
  boundaryPrefix,
  boundaryFormat,
  hideLastItemPlusSign = false,
  tabIndex,
  addAny = false,
  ...rest
}: Props) => {
  const { options, rangesById } = React.useMemo(() => {
    const data = formatBoundaries(boundaries, { boundaryPrefix, boundaryFormat, hideLastItemPlusSign });

    if (radio && addAny) {
      data.options.unshift({ label: I18n.get(lang.OPTION_ANY), value: OPTION_ANY_RADIO_VALUE });
    }

    return data;
  }, [addAny, boundaries, boundaryFormat, boundaryPrefix, hideLastItemPlusSign, radio]);

  const value = React.useMemo(() => {
    if (radio && valueObject === OPTION_ANY_VALUE) return OPTION_ANY_RADIO_VALUE;

    return matchRangeId({ rangesById, value: valueObject });
  }, [radio, rangesById, valueObject]);

  const handleSelectChange = React.useCallback(
    (rangeId: string) => onChange && onChange(rangesById[rangeId]),
    [onChange, rangesById]
  );

  const handleRadioChange = React.useCallback(
    (event: RadioChangeEvent) =>
      onChange && onChange(event.target.value ? rangesById[event.target.value] : OPTION_ANY_VALUE),
    [onChange, rangesById]
  );

  return radio ? (
    <Radio.Group onChange={handleRadioChange} value={value}>
      {options.map(({ label, value: rangeId }) => (
        <Radio tabIndex={tabIndex} key={rangeId} value={rangeId} style={radioButtonStyles}>
          {label}
        </Radio>
      ))}
    </Radio.Group>
  ) : (
    <Select
      {...rest}
      style={{ width: '100%', ...style }}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={handleSelectChange}
      tabIndex={tabIndex}
    />
  );
};

export const RangeSelect = React.memo(InternalRangeSelect);
