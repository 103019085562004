import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { I18n } from 'aws-amplify';
import * as React from 'react';
import { Link } from 'react-router-dom';

import Step1 from '../../assets/step1.svg';
import Step2 from '../../assets/step2.svg';
import Step3 from '../../assets/step3.svg';
import { Page } from '../../components/Page';
import { lang } from '../../i18n/lang';
import { useI18nStore } from '../../stores/i18n';
import { Locale } from '../../typings/i18n';
import { APP_NAVIGATION_ROUTES } from '../../utils/constants';

import styles from './Home.module.less';

const PROCESS_1: { [key in Locale]: string } = {
  en: Step1,
  fr: Step1,
};
const PROCESS_2: { [key in Locale]: string } = {
  en: Step2,
  fr: Step2,
};
const PROCESS_3: { [key in Locale]: string } = {
  en: Step3,
  fr: Step3,
};

export function Home() {
  const locale = useI18nStore((store) => store.locale);

  return (
    <Page className={styles.home} type="public" layout="topCenter">
      <Space style={{ width: '90%' }} direction="vertical">
        <Row justify="center" align="middle">
          <Typography.Title style={{ marginBottom: 0 }}>
            <Typography.Paragraph className={styles.firstTagline} style={{ marginBottom: 20 }}>
              {I18n.get(lang.TAG_LINE_1_1)}
            </Typography.Paragraph>
            <Typography.Paragraph className={styles.firstTagline} style={{ marginBottom: 0 }}>
              {I18n.get(lang.TAG_LINE_1_2)}
            </Typography.Paragraph>
          </Typography.Title>
        </Row>
        <Row justify="center" align="middle">
          <Col>
            <small>
              <Typography.Paragraph className={styles.secondTagline} style={{ marginBottom: 0 }}>
                {I18n.get(lang.TAG_LINE_2)}
              </Typography.Paragraph>
              <Typography.Paragraph className={styles.thirdTagline} style={{ marginBottom: 20, marginTop: 20 }}>
                {I18n.get(lang.TAG_LINE_3)}
              </Typography.Paragraph>
            </small>
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[24, 16]} style={{ paddingBottom: 20, paddingTop: 20 }}>
          <Col xs={24} sm={24} md={16} lg={12} xl={10}>
            <Link to={APP_NAVIGATION_ROUTES.JOB_SEEKER_SIGN_UP_ROUTE}>
              <Button type="primary" size="large" className={styles.registerBtn} block>
                {I18n.get(lang.REGISTER_AS_JOB_SEEKER)}
              </Button>
            </Link>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={10}>
            <Link to={APP_NAVIGATION_ROUTES.EMPLOYER_SIGN_UP_ROUTE}>
              <Button type="primary" size="large" className={styles.registerBtn} block>
                {I18n.get(lang.REGISTER_AS_JOB_EMPLOYEUR)}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row justify="center" align="middle" className={styles.waveContainer}>
          <Typography.Title>
            <Typography.Paragraph className={styles.whyTagline}>{I18n.get(lang.WHY_ELEVID)}</Typography.Paragraph>
          </Typography.Title>
        </Row>
        <Row justify="center" align="middle" gutter={[18, 24]} className={styles.whyContainer}>
          <Col xs={24} sm={24} md={16} lg={12} xl={12}>
            <CheckCircleFilled className={styles.iconStyle} />
            <Typography.Text className={styles.whyTaglineText} strong>
              {I18n.get(lang.WHY_1)}
            </Typography.Text>
            <small className={styles.paraContainer}>
              <Typography.Paragraph className={styles.whyParag}>{I18n.get(lang.ARG_1)}</Typography.Paragraph>
            </small>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12}>
            <CheckCircleFilled className={styles.iconStyle} />
            <Typography.Text className={styles.whyTaglineText} strong>
              {I18n.get(lang.WHY_2)}
            </Typography.Text>
            <small className={styles.paraContainer}>
              <Typography.Paragraph className={styles.whyParag}>{I18n.get(lang.ARG_2)}</Typography.Paragraph>
            </small>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12}>
            <CheckCircleFilled className={styles.iconStyle} />
            <Typography.Text className={styles.whyTaglineText} strong>
              {I18n.get(lang.WHY_3)}
            </Typography.Text>
            <small className={styles.paraContainer}>
              <Typography.Paragraph className={styles.whyParag}>{I18n.get(lang.ARG_3)}</Typography.Paragraph>
            </small>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12}>
            <CheckCircleFilled className={styles.iconStyle} />
            <Typography.Text className={styles.whyTaglineText} strong>
              {I18n.get(lang.WHY_4)}
            </Typography.Text>
            <small className={styles.paraContainer}>
              <Typography.Paragraph className={styles.whyParag}>{I18n.get(lang.ARG_4)}</Typography.Paragraph>
            </small>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12} xl={12}>
            <CheckCircleFilled className={styles.iconStyle} />
            <Typography.Text className={styles.whyTaglineText} strong>
              {I18n.get(lang.WHY_5)}
            </Typography.Text>
            <small className={styles.paraContainer}>
              <Typography.Paragraph className={styles.whyParag}>{I18n.get(lang.ARG_5)}</Typography.Paragraph>
            </small>
          </Col>
        </Row>
        <Row justify="center" align="middle" className={styles.waveContainer}>
          <Typography.Title>
            <Typography.Paragraph className={styles.howTagline}>{I18n.get(lang.HOW_DOES_IT_WORK)}</Typography.Paragraph>
          </Typography.Title>
        </Row>
        <Row justify="center" align="middle" gutter={[40, 24]}>
          <Col xs={18} sm={18} md={8} lg={8} xl={8}>
            <div className={styles.imageContainer}>
              <img src={PROCESS_1[locale] || PROCESS_1['en']} alt={I18n.get(lang.HOW_1)} />
              <Typography.Paragraph className={styles.stepContainer}>{I18n.get(lang.HOW_1)}</Typography.Paragraph>
            </div>
          </Col>
          <Col xs={18} sm={18} md={8} lg={8} xl={8}>
            <div className={styles.imageContainer}>
              <img src={PROCESS_2[locale] || PROCESS_2['en']} alt={I18n.get(lang.HOW_2)} />
              <Typography.Paragraph className={styles.stepContainer}>{I18n.get(lang.HOW_2)}</Typography.Paragraph>
            </div>
          </Col>
          <Col xs={18} sm={18} md={8} lg={8} xl={8}>
            <div className={styles.imageContainer}>
              <img src={PROCESS_3[locale] || PROCESS_3['en']} alt={I18n.get(lang.HOW_3)} />
              <Typography.Paragraph className={styles.stepContainer}>{I18n.get(lang.HOW_3)}</Typography.Paragraph>
            </div>
          </Col>
        </Row>
      </Space>
    </Page>
  );
}
