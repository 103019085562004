import { notification } from 'antd';
import { Auth, I18n } from 'aws-amplify';
import type { History } from 'history';

import { lang } from '../../i18n/lang';
import { AuthUser } from '../../typings/auth';
import { CONFIRM_EMAIL_ROUTE } from '../../utils/constants';
import { captureException } from '../../utils/sentry';

type Options = {
  username: string;
  password: string;
};

type Dependencies = {
  setUser: (user: AuthUser | null) => void;
  history: History;
};

export async function login({ username, password }: Options, { setUser, history }: Dependencies) {
  try {
    const cognitoUser = await Auth.signIn(username, password);
    const newPasswordRequired = cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED';

    if (!newPasswordRequired) {
      notification.success({
        message: I18n.get(lang.SUCCESS_MSG_LOGIN),
        description: newPasswordRequired
          ? I18n.get(lang.SUCCESS_DESC_RESET_PASSWORD_FIRST)
          : I18n.get(lang.SUCCESS_DESC_LOGIN),
        placement: 'topRight',
      });
    }

    return cognitoUser;
  } catch (err) {
    // @ts-ignore
    if (err?.code === 'UserNotConfirmedException') {
      history.push({
        pathname: CONFIRM_EMAIL_ROUTE,
        search: `?email=${username}`,
      });
    } else {
      captureException(err);

      notification.error({
        message: I18n.get(lang.ERROR),
        // @ts-ignore
        description: err.message,
        placement: 'topRight',
      });

      throw err;
    }
  }
}

export async function completeNewPassword(cognitoUser: AuthUser, newPassword: string) {
  try {
    const { userAttributes: { email = undefined, phone_number = undefined } = {} } = cognitoUser?.challengeParam ?? {};

    await Auth.completeNewPassword(cognitoUser, newPassword, {
      email,
      phone_number,
    });

    notification.success({
      message: I18n.get(lang.SUCCESS_MSG_LOGIN),
      description: I18n.get(lang.SUCCESS_DESC_LOGIN),
      placement: 'topRight',
    });
  } catch (err) {
    captureException(err);

    notification.error({
      message: I18n.get(lang.ERROR),
      // @ts-ignore
      description: err.message,
      placement: 'topRight',
    });
  }
}
