import { Button, Dropdown, Menu } from 'antd';
import type { MenuProps } from 'antd';
import * as React from 'react';
import { shallow } from 'zustand/shallow';

import fr from '../../assets/fr.svg';
import en from '../../assets/gb.svg';
import { saveLocaleToLocalStorage } from '../../i18n';
import { useAuthStore } from '../../stores/auth';
import { useI18nStore } from '../../stores/i18n';
import { Locale, isSupportedLocale } from '../../typings/i18n';

const LOCALE = {
  [Locale.FR]: { name: 'Français', flag: fr },
  [Locale.EN]: { name: 'English', flag: en },
};

export function LocaleSelect() {
  const { locale, setLocale } = useI18nStore(({ locale, setLocale }) => ({ locale, setLocale }), shallow);
  const updateUserLocale = useAuthStore(({ updateLocale }) => updateLocale, shallow);

  const handleLocaleMenuClick: MenuProps['onClick'] = (info) => {
    if (isSupportedLocale(info.key)) {
      setLocale(info.key);
      saveLocaleToLocalStorage(info.key);
      updateUserLocale(info.key);
    }
  };

  const localeMenu = (
    <Menu selectedKeys={[locale]} onClick={handleLocaleMenuClick}>
      {(Object.keys(LOCALE) as Array<keyof typeof LOCALE>).map((locale) => (
        <Menu.Item key={locale}>
          <img style={{ height: '.8em' }} src={LOCALE[locale].flag} alt={LOCALE[locale].name} />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={localeMenu} trigger={['click']} placement="topRight">
      <Button style={{ padding: 0 }} size="small" type="text">
        <img style={{ height: '1em' }} src={LOCALE[locale].flag} alt={LOCALE[locale].name} />
      </Button>
    </Dropdown>
  );
}
