/* eslint-disable no-template-curly-in-string */

import { lang } from './lang';

export const dict = {
  fr: {
    [lang.REGISTER]: "S'inscrire",
    [lang.REGISTER_FOR_FREE]: "S'inscrire gratuitement",
    [lang.SUCCESS_MSG_SIGNUP]: 'Inscription réussie!',
    [lang.SUCCESS_DESC_SIGNUP]: "Vérifiez votre messagerie pour terminer votre processus d'inscription",
    [lang.ERROR_SIGNUP]: "Erreur d'inscription",
    [lang.ERROR_EMAIL_SIGNUP]: 'E-mail utilisé!',
    [lang.ERROR_EMAIL_SIGNUP_DESC]: 'Il existe déjà un compte avec cet email.',
    [lang.EMAIL]: 'E-mail',
    [lang.PASSWORD]: 'Mot de passe',
    [lang.TITLE_UPDATE_PASSWORD_PAGE]: 'Veuillez mettre à jour votre mot de passe',
    [lang.SUCCESS_MSG_LOGIN]: 'Connexion réussie!',
    [lang.SUCCESS_DESC_LOGIN]: 'Connecté avec succès, vous redirigeant dans quelques-uns!',
    [lang.ERROR]: 'Erreur',
    [lang.LABEL_NEW_PASSWORD]: 'Votre nouveau mot de passe',
    [lang.VALIDATOR_MSG_NEW_PASSWORD]: 'Veuillez saisir votre nouveau mot de passe',
    [lang.LABEL_CONFIRM_PASSWORD]: 'Confirmez le mot de passe',
    [lang.VALIDATOR_MSG_CONFIRM_PASSWORD]: 'Veuillez confirmer votre mot de passe',
    [lang.TWO_PASSWORD_NOT_MUCH]: 'Les deux mots de passe que vous avez saisis ne correspondent pas!',
    [lang.SUBMIT_BTN]: 'Soumettre',
    [lang.TITLE_REGISTER_JOBSEEKER]: 'Je suis candidat',
    [lang.SUBTITLE_JOB_SEEKER_CARD]: "à la recherche d'une nouvelle opportunité.",
    [lang.TITLE_REGISTER_EMPLOYER]: 'Je suis un employeur',
    [lang.SUBTITLE_EMPLOYER_CARD]: 'à la recherche de talents brillants.',
    [lang.TITLE_REGISTER_STARTUP]: 'Je suis une startup',
    [lang.SUBTITLE_STARTUP_CARD]: "à la recherche d'investissement.",
    [lang.TITLE_REGISTER_INVESTOR]: 'Je suis un investisseur',
    [lang.SUBTITLE_INVESTOR_CARD]: 'cherche à investir dans des start-ups prometteuses.',
    [lang.SUCCESS_SEARCH_SAVED]: 'Recherche enregistrée avec succès!',
    [lang.ERROR_SEARCH_SAVED]: "L'enregistrement de la recherche a échoué!",
    [lang.VALIDATOR_MSG_INPUT_SEARCH]: 'Veuillez saisir votre recherche',
    [lang.TITLE_SEARCH_STARTUP]: 'Investissez dans des idées intelligentes.',
    [lang.PLACEHOLDER_INPUT_SEARCH_STARTUP]: 'Nommez cette recherche (par exemple,Automotive dans la UK)',
    [lang.LABEL_LOCATIONS_SEARCH]: 'Où la start-up devrait-elle être basée?',
    [lang.VALIDATOR_MSG_LOCATIONS_SEARCH]: 'Veuillez sélectionner des emplacements',
    [lang.LABEL_SECTORS_SEARCH_STARTUP]: 'Dans quels secteurs la start-up doit-elle opérer? (facultatif*)',
    [lang.NO_SECTOR_SELECT_INFO]:
      '*Laissez ce champ vide pour accéder à TOUTES les start-ups dans vos résultats de recherche.',
    [lang.VALIDATOR_MSG_SECTORS_SEARCH_STARTUP]: 'Veuillez sélectionner des secteurs',
    [lang.LABEL_DESIRED_FUNDING_SEARCH]: 'Quel montant de financement la start-up devrait-elle rechercher?',
    [lang.CHOOSE_AN_OPTION]: 'Choisis une option.',
    [lang.VALIDATOR_DESIRED_FUNDING_SEARCH]: 'Veuillez saisir le montant',
    [lang.SEE_RESULT_BTN]: 'VOIR LES RÉSULTATS',
    [lang.NEW]: 'Nouvelle',
    [lang.SEARCH]: 'recherche',
    [lang.TITLE_SEARCH_JOBSEEKER]: 'Trouvez des talents brillants',
    [lang.PLACEHOLDER_INPUT_SEARCH_JOBSEEKER]:
      'Nommez cette recherche (par exemple "Experts RH" ou "Comptables britanniques")',
    [lang.LABEL_EXPERTISES_SEARCH]: 'Quelle expertise le candidat doit-il avoir?',
    [lang.VALIDATOR_MSG_EXPERTISES_SEARCH]: 'Veuillez sélectionner les expertises souhaitées',
    [lang.LABEL_LOCATION_SEARCH]: 'Où le candidat devrait-il être basé?',
    [lang.SELECT_A_COUNTRY]: 'Choisissez un pays.',
    [lang.VALIDATOR_MSG_LOCATION_SEARCH]: "Veuillez sélectionner les emplacements de recherche d'emploi souhaités",
    [lang.LABEL_SECTORS_SEARCH_JOBSEEKER]: "Dans quels secteurs le candidat doit-il avoir de l'expérience?",
    [lang.VALIDATOR_MSG_SECTORS_SEARCH_JOBSEEKER]: "Veuillez sélectionner les secteurs de recherche d'emploi souhaités",
    [lang.LABEL_LANGUAGES_SEARCH]: 'Quelles langues le candidat doit-il parler couramment?',
    [lang.VALIDATOR_MSG_LANGUAGES_SEARCH]: "Veuillez sélectionner les demandeurs d'emploi souhaités Langues",
    [lang.LABEL_EXPERIENCE_YEARS_SEARCH]: "Combien d'années d'expérience au total le candidat devrait-il avoir?",
    [lang.SUCCESS_MSG_RESET_PASSWORD]: 'Succès!',
    [lang.SUCCESS_DESC_RESET_PASSWORD]: 'Réinitialisation du mot de passe réussie, vous redirigeant en quelques-uns!',
    [lang.ERROR_MSG_RESET_PASSWORD]: 'Erreur lors de la réinitialisation du mot de passe',
    [lang.TEXT_CHECK_CODE]: 'Vérifiez votre e-mail pour le code de confirmation',
    [lang.VALIDATOR_MSG_CODE]: 'Veuillez saisir votre code de confirmation',
    [lang.PLACEHOLDER_INPUT_CODE]: 'Saisissez votre code de confirmation',
    [lang.PLACEHOLDER_NEW_PASSWORD]: 'Nouveau mot de passe',
    [lang.CONFIRM_BTN]: 'Confirmer',
    [lang.SUCCESS_DESC_RESET_PASSWORD_FIRST]: "Vous devez d'abord réinitialiser votre mot de passe",
    [lang.LOGIN]: 'Se connecter',
    [lang.FORGOT_PASSWORD]: 'Mot de passe oublié',
    [lang.OR]: 'OU',
    [lang.UPDATE_PASSWORD]: 'METTRE À JOUR LE MOT DE PASSE',
    [lang.REDIRECTION_MSG_FORGOT_PASSWORD]: 'Vous rediriger en quelques-uns!',
    [lang.REDIRECTION_DESCRIPTION_FORGOT_PASSWORD]: 'Compte confirmé avec succès!',
    [lang.ERROR_FORGOT_PASSWORD]: "La confirmation de l'utilisateur a échoué",
    [lang.RESET_PASSWORD]: 'Réinitialisation du mot de passe',
    [lang.VALIDATOR_MSG_EMAIL]: 'Veuillez saisir votre adresse mail!',
    [lang.VALIDATOR_MSG_EMAIL_INVALID]: 'Veuillez saisir une adresse mail valide!',
    [lang.RESET_PASSWORD_BTN]: 'Réinitialiser le mot de passe',
    [lang.IVE_REMEMBERED_LINK]: 'Ooh! Attendez! Je me suis souvenu!',
    [lang.LABEL_CONFIMATION_CODE]: 'Code de confirmation',
    [lang.CONFIRM_EMAIL_BTN]: 'Confirmez votre e-mail',
    [lang.THIS_MINUTE_MATTERS]: 'cette minute compte.',
    [lang.FIRST_PARAGRAPH_HOME]:
      "Il n'y a pas de seconde chance pour une première impression. Faites en sorte que le vôtre compte et rejoignez elevid: une plate-forme de correspondance vidéo de pointe qui vous relie exactement à qui vous devriez parler, où que vous soyez dans le monde.",
    [lang.SECONDE_PARAGRAPH_HOME]:
      "Sur la base d'un pitch vidéo d'une minute et d'un bref questionnaire, elevid vous permet d'établir des liens pertinents avec ce qui passe souvent inaperçu: les personnes, pas les KPI.",
    [lang.THIRD_PARAGRAPH_HOME]:
      "Que vous soyez à la recherche de talents brillants pour votre entreprise ou que vous soyez à la recherche d'idées audacieuses dans lesquelles investir: c'est l'endroit qu'il vous faut.",
    [lang.FOURTH_PARAGRAPH_HOME]:
      "Parcourez-vous actuellement le marché du travail, fatigué des descriptifs de poste standards qui ne vous donnent pas un véritable aperçu de qui recevra réellement votre candidature? Votre startup essaie-t-elle de naviguer dans l'univers infini des investisseurs et des événements de pitch? elevid changera votre jeu.",
    [lang.LABEL_COMPANY_NAME]: 'Nom de la compagnie',
    [lang.VALIDATOR_MSG_COMPANY_NAME]: 'Veuillez saisir le nom de votre entreprise',
    [lang.LABEL_JOB_TITLE]: 'Profession',
    [lang.VALIDATOR_MSG_JOB_TITLE]: 'Veuillez saisir le titre de votre poste',
    [lang.LABEL_FIRST_NAME]: 'Prénom',
    [lang.VALIDATOR_MSG_FIRST_NAME]: 'Veuillez saisir votre prénom',
    [lang.LABEL_PHONE]: 'Numéro de téléphone (facultatif)',
    [lang.LABEL_LAST_NAME]: 'Nom de famille',
    [lang.VALIDATOR_MSG_LAST_NAME]: 'Veuillez saisir votre nom de famille',
    [lang.LABEL_BIRTH_DATE]: 'Date de naissance',
    [lang.VALIDATOR_MSG_BIRTH_DATE]: 'Veuillez sélectionner votre date de naissance',
    [lang.LABEL_LOCATION_STARTUP]: 'Où est basée votre start-up?',
    [lang.VALIDATOR_MSG_LOCATION_STARTUP]: 'Veuillez sélectionner votre emplacement de démarrage',
    [lang.LABEL_SECTORS]: 'Dans quels secteurs opérez-vous?',
    [lang.VALIDATOR_MSG_SECTORS_STARTUP]: 'Veuillez sélectionner vos secteurs de démarrage',
    [lang.LABEL_CREATION_YEAR]: 'En quelle année votre start-up a-t-elle été créée?',
    [lang.VALIDATOR_MSG_CREATION_YEAR]: 'Veuillez sélectionner votre année de création de start-up',
    [lang.LABEL_SIZE]: "Combien d'employés avez-vous actuellement?",
    [lang.VALIDATOR_MSG_SIZE]: "Veuillez sélectionner le nombre d'employés",
    [lang.LABEL_DESIRED_FUNDING]: 'Quel montant de financement souhaitez-vous collecter?',
    [lang.VALIDATOR_DESIRED_FUNDING]: 'Veuillez sélectionner votre gamme de financement que vous recherchez',
    [lang.LABEL_EXPERTISES]: "Quels sont vos domaines d'expertise?",
    [lang.VALIDATOR_MSG_EXPERTISES]: "Veuillez sélectionner vos domaines d'expertise",
    [lang.LABEL_LOCATION_JOBSEEKER]: 'Où êtes-vous basé?',
    [lang.VALIDATOR_MSG_LOCATION_JOBSEEKER]: 'Veuillez sélectionner votre lieu de résidence',
    [lang.LABEL_SECTORS_JOBSEEKER]: "Dans quels secteurs avez-vous le plus d'expérience?",
    [lang.VALIDATOR_MSG_SECTORS]: 'Veuillez sélectionner vos secteurs',
    [lang.LABEL_LANGUAGES]: 'Quelles langues parlez-vous couramment?',
    [lang.VALIDATOR_MSG_LANGUAGES]: 'Veuillez sélectionner vos langues parlées',
    [lang.LABEL_EXPERIENCE_YEARS]: "Combien d'années d'expérience avez-vous au total?",
    [lang.VALIDATOR_MSG_EXPERIENCE_YEARS]: "Veuillez sélectionner votre gamme d'années d'expérience",
    [lang.LABEL_COMPANY_NAME_OPTIONAL]: "Nom de l'entreprise (facultatif)",
    [lang.LABEL_LOCATIONS]: 'Quels sont vos principaux emplacements commerciaux?',
    [lang.VALIDATOR_MSG_LOCATIONS]: 'Veuillez sélectionner les adresses de votre entreprise',
    [lang.SUCCESS_MSG_SAVE_PROFILE]: 'Profil enregistré avec succès.',
    [lang.ERROR_MSG_SAVE_PROFILE]: "L'enregistrement du profil a échoué, veuillez réessayer plus tard.",
    [lang.VALIDATOR_MSG_VIDEO]: 'Veuillez ajouter votre vidéo',
    [lang.UPDATE]: 'Mettre à jour',
    [lang.PERSONAL_INFORMATION]: 'Renseignements personnels',
    [lang.QUESTIONNAIRE]: 'Questionnaire',
    [lang.VIDEO]: 'Vidéo',
    [lang.SUPPORTING_DOCUMENT]: "Document d'appui",
    [lang.PROFILE]: 'Profil',
    [lang.VIDEO_UPLOAD_TITLE_EMPLOYER]: "Ajoutez votre vidéo pitch d'une minute à votre profil",
    [lang.VIDEO_UPLOAD_DESCRIPTION_EMPLOYER]: `Cette minute compte! Votre présentation vidéo est la première impression que les autres utilisateurs auront de vous - faites en sorte que cela compte. Voici comment:
1. Vous n’avez pas besoin d’équipement spécial - filmez-vous simplement avec votre smartphone ou votre ordinateur.
2. Présentez-vous ainsi que votre parcours professionnel et votre activité.
3. Décrivez ce que vous recherchez.

**Remarque importante:** Si vous ne souhaitez pas montrer votre visage ou si vous n’avez pas le temps de filmer une vidéo pour le moment, vous pouvez également mettre en ligne un slideshow ou simplement le logo de votre entreprise (sous forme de vidéo au format .mp4)!`,
    [lang.VIDEO_RECORD_TITLE_EMPLOYER]: "Enregistrez votre vidéo pitch d'une minute MAINTENANT!",
    [lang.VIDEO_UPLOAD_TITLE_JOB_SEEKER]: "Ajoutez votre vidéo pitch d'une minute à votre profil",
    [lang.VIDEO_UPLOAD_DESCRIPTION_JOB_SEEKER]: `Cette minute compte! Votre présentation vidéo est la première impression que les autres utilisateurs auront de vous - faites en sorte que cela compte. Voici comment:
1. Vous n’avez pas besoin d’équipement spécial - filmez-vous simplement avec votre smartphone ou votre ordinateur.
2. Choisissez une tenue et un arrière-plan neutres.
3. Présentez-vous ainsi que votre parcours professionnel et votre activité.
4. Mettez en valeur vos compétences et vos compétences générales.
5. Présentez vos objectifs, votre valeur ajoutée et la solution / expertise que vous proposez.`,
    [lang.VIDEO_RECORD_TITLE_JOB_SEEKER]: "Enregistrez votre vidéo pitch d'une minute MAINTENANT et impressionnez!",
    [lang.CLICK_DRAG_VIDEO]: 'Cliquez ou faites glisser le fichier dans cette zone pour télécharger',
    [lang.ALLOWED_VIDEO_TYPE]: 'Types de fichiers autorisés: ',
    [lang.MAX_TIME_VIDEO]: 'Temps maximum autorisé: 60 secondes',
    [lang.PREVIEW_VIDEO]: 'Aperçu de la vidéo',
    [lang.UNSUPPORTED_VIDEO]: 'Fichier vidéo non pris en charge, veuillez essayer avec un autre fichier.',
    [lang.MAX_DURATION_VIDEO]:
      'La durée maximale autorisée de la vidéo est de maxDuration secondes. Votre vidéo est durationTime secondes.',
    [lang.VIDEO_RECORDING_NOT_SUPPORTED]: "L'enregistrement vidéo n'est pas pris en charge par votre navigateur",
    [lang.VIDEO_RECORDING_NOT_SUPPORTED_INFOS]:
      "Utilisez un autre navigateur ou enregistrez une vidéo d'une durée maximale de ${maxDuration} secondes sur votre appareil, puis mettez-la en ligne à l'aide de l'outil '${uploadVideoLabel}'.",
    [lang.NO_THANKS]: 'Non, merci!',
    [lang.YES_PLEASE]: "Oui, s'il vous plaît!",
    [lang.FREE]: 'GRATUIT - CLIQUEZ ICI',
    [lang.LIMITED_OFFER]: 'OFFRE LIMITEE',
    [lang.YEAR]: 'An',
    [lang.VIEW_CANDIDATES]: 'Voir les candidats',
    [lang.SUCCESS_MSG_REGISTRAION]: 'Inscription réussie!',
    [lang.REGISTRAION_FILAED_MSG]: "Échec d'enregistrement du profile!",
    [lang.REGISTRAION_FILAED_DESC]:
      "Une erreur s'est produite lors de l'enregistrement de votre profile, veuillez réessayer plus tard.",
    [lang.PREVIOUS_BTN]: 'Précédent',
    [lang.FINISH_BTN]: 'Terminer',
    [lang.ALL_RIGHT]: 'ELEVID © TOUS DROITS RÉSERVÉS 2020',
    [lang.TERMS_OF_SERVICES]: 'Conditions de services',
    [lang.PRIVACY]: 'Secret',
    [lang.PRIVACY_POLICY]: 'Politique de confidentialité',
    [lang.TITLE_SUPPORT_DOC]: "Télécharger un document d'appui (facultatif)",
    [lang.PARAGRAPH_SUPPORT_DOC]: `* Si vous le souhaitez, vous pouvez ajouter un document d'appui à votre profil
* Les utilisateurs avec lesquels vous avez jumelé seront capables de télécharger ce document
* Téléchargez votre...
  * CV
  * présentation d'entreprise
  * portfolio
  * bio
  * tout autre document PDF
* pour mettre en évidence qui vous êtes et ce que vous voulez montrer aux autres utilisateurs d'elevid.`,
    [lang.CLICK_DRAG_FILE]: 'Cliquez ou faites glisser votre fichier pour le télécharger',
    [lang.ALLOWED_FILE_TYPE]: 'Types de fichiers autorisés',
    [lang.BUTTON_START_RECORD_VIDEO]: 'Cliquez pour commencer à enregistrer votre vidéo',
    [lang.BUTTON_START_RECORD_INFOS]:
      "Pour commencer l'enregistrement de votre vidéo, cliquez sur le bouton ci-dessous. Assurez-vous que la durée de la vidéo ne dépasse pas ${maxDuration} secondes.",
    [lang.DOWNLOAD_DOC_BTN]: 'Télécharger le document de support',
    [lang.BRAND_NEW_CHAT]: 'Ceci est un tout nouveau chat, envoyez votre premier message ci-dessous!',
    [lang.LOGOUT]: 'Se déconnecter',
    [lang.GO_STEP_TWO]: "Passez à l'étape 2/4",
    [lang.GO_STEP_THREE]: "Validez la vidéo\net passez à l'étape 3/4",
    [lang.GO_STEP_FOUR]: "Passez à l'étape 4/4",
    [lang.MY_VIDEO]: 'Ma vidéo',
    [lang.LOGIN_FAILED_CONFIRM_EMAIL_MSG]: 'Échec de la connexion!',
    [lang.LOGIN_FAILED_CONFIRM_EMAIL_DESC]: 'La connexion automatique a échoué, veuillez réessayer.',
    [lang.ERROR_CONFIRM_EMAIL_MSG]: 'La confirmation a échoué!',
    [lang.ERROR_CONFIRM_EMAIL_DESC]: 'La confirmation du compte a échoué, veuillez réessayer.',
    [lang.SUCCESS_MSG_CONFIRM_EMAIL]: 'Code renvoyé.',
    [lang.SUCCESS_DESC_CONFIRM_EMAIL]: 'Veuillez vérifier votre e-mail pour le nouveau code de confirmation.',
    [lang.ERROR_MSG_CONFIRM_EMAIL]: 'Le renvoi du code a échoué!',
    [lang.ERROR_DESC_CONFIRM_EMAIL]: 'Échec du renvoi du code, veuillez réessayer.',
    [lang.TITLE_CONFIRM_EMAIL_PAGE]: "Confirmation de l'adresse e-mail",
    [lang.CONFIRMATION_CODE_MSG]:
      'Nous vous avons maintenant envoyé un code de confirmation à six chiffres par e-mail. .',
    [lang.SUBTITLE_PASSWORD_CONFIRM_EMAIL]:
      "Veuillez saisir le mot de passe que vous avez choisi lors de l'inscription.",
    [lang.VALIDATOR_MSG_PASSWORD]: 'Veuillez saisir votre mot de passe',
    [lang.VALIDATOR_MSG_PASSWORD_LEN]: 'Veuillez entrer en moins 8 caractères',
    [lang.SUBLABEL_CONFIMATION_CODE]: 'Veuillez saisir le code à 6 chiffres que vous avez reçu par e-mail.',
    [lang.VALIDATOR_MSG_REQUIRED_CODE]: 'Veuillez saisir le code de confirmation',
    [lang.VALIDATOR_MSG_PATTERN_CODE]: 'Le code doit être un nombre à 6 chiffres',
    [lang.RESEND_CONFIRM_CODE_BTN]: 'Renvoyer le code de confirmation',
    [lang.NO_USER_SAID_YES_MSG]:
      "Désolé, nous n'avons rien à vous montrer pour le moment! Aucun nouvel utilisateur n'a dit 'Oui, s'il vous plaît!' à votre présentation vidéo. Revenez plus tard et nous sommes sûrs que vous trouverez ce que vous cherchez!",
    [lang.ALL_VIDEOS_OF_USER_SAID_YES_VIEWED_MSG]:
      "C'est tout ce que nous avons pour le moment! Vous avez vu tous les pitchs vidéo des utilisateurs qui ont dit 'Oui, s'il vous plaît!' à votre présentation vidéo.",
    [lang.NO_USER_FOUND_MSG]:
      "Désolé, nous n'avons rien à vous montrer pour le moment! Aucun utilisateur ne correspond à vos critères de recherche - veuillez lancer une nouvelle recherche avec des critères différents, et nous sommes sûrs que vous trouverez ce que vous recherchez.",
    [lang.ALL_VIDEOS_VIEWED_MSG]:
      'C’est tout ce que nous avons pour le moment! Vous avez vu tous les pitchs vidéo qui correspondent à vos critères de recherche.',
    [lang.MESSENGER]: 'Messagerie',
    [lang.NO_MESSAGES_TO_SHOW]:
      "Il n'y a actuellement aucun message à afficher. Une fois que vous serez jumelé avec d'autres utilisateurs, vous pourrez leur envoyer un message ici.",
    [lang.IM_A_CANDIDATE]: 'Je suis un \n candidat',
    [lang.IM_AN_EMPLOYER]: 'Je suis un \n employeur',
    [lang.IM_AN_INVESTOR]: 'Je suis un \n investisseur',
    [lang.IM_A_STARTUP]: 'Je suis une \n start-up',

    [lang.MORE_THAN]: 'Plus de number €',
    [lang.NEW_MESSAGE]: 'Nouveau message: text',
    [lang.JOBSEEKER_LIKED]: 'Le profil du candidat user a été aimé!',
    [lang.JOBSEEKER_DISLIKED]: "Le profil du candidat user n'a pas été aimé!",
    [lang.EMPLOYER_LIKED]:
      "Le profil d'employeur de user de l'entreprise (companyName) a été répondu par un 'Oui, s'il vous plaît!'!",
    [lang.EMPLOYER_DISLIKED]:
      "Le profil d’employeur de user de l'entreprise (companyName) a été répondu avec un 'Non, merci!'!",
    [lang.INVESTOR_LIKED]:
      "Le profil d'investisseur de user de l'entreprise (companyName) a été répondu par un 'Oui, s'il vous plaît!'!",
    [lang.INVESTOR_DISLIKED]:
      "Le profil d'investisseur de user de l'entreprise (companyName) a été répondu avec un 'Non, merci!'!",
    [lang.STARTUP_LIKED]: 'Le profil de démarrage de user (companyName) a été aimé!',
    [lang.STARTUP_DISLIKED]: "Le profil de démarrage de user (companyName) n'a pas été aimé!",
    [lang.LIKE_DISLIKE_ERROR]: "Une erreur s'est produite, veuillez réessayer plus tard.",
    [lang.IS_REQUIRED]: '${name} est requis!',
    [lang.IS_NOT_VALID]: '${label} n’est pas valide!',
    [lang.TITLE_NOTIFICATONS]: 'Notifications',
    [lang.NOTIFICATON_LIKE]: '${name} a aimé votre présentation! Allez au tableau de bord pour regarder la leur.',
    [lang.NOTIFICATON_LIKE_BACK]: '${name} a aussi aimé votre présentation! Allez à la messagerie pour discuter avec.',
    [lang.NOTIFICATON_MSG]: 'Vous avez des messages non lus! Allez à la messagerie pour les lire.',
    [lang.NOTIFICATON_MSG_WITH_NAME]:
      '${name} vous a envoyé de nouveaux messages! Allez à la messagerie pour les lire.',
    [lang.NO_NOTIFICATIONS_MESSAGE]:
      "Vous n'avez pas encore de notifications. Une fois que vous recevez de nouvelles notifications, elles apparaîtront ici.",
    [lang.NEW_NOTIFIATION_MESSAGE]: 'Vous venez de recevoir de nouvelles notifications!',
    [lang.MARK_ALL_AS_READ]: 'Tout marquer comme lu',

    [lang.DELETE_SEARCH_TITLE]: 'Supprimer "${searchName}"?',
    [lang.DELETE_SEARCH_TEXT]: 'Après confirmation cette recherche sera définitivement supprimée.',
    [lang.EDIT_SEARCH_ACTION_LABEL]: 'modifier',
    [lang.DELETE_SEARCH_ACTION_LABEL]: 'supprimer',

    [lang.COMING_SOON]: 'BIENTÔT DISPONIBLE',
    [lang.LEARN_MORE]: 'EN SAVOIR PLUS >',

    [lang.DASHBOARD]: 'Tableau de bord',
    [lang.DASHBOARD_WELCOME]: 'Bienvenue ${name}',

    [lang.AGREE_TERMS_PRIVACY]: 'en vous inscrivant, vous acceptez nos ${terms} et notre ${privacy}',

    [lang.CREATE_SEARCH]: 'Créer une nouvelle recherche',
    [lang.UPDATE_SEARCH]: 'Mettre à jour la recherche',
    [lang.SEARCH_RESULTS]: 'Résultats de la recherche',

    [lang.LOADING]: 'Chargement...',

    [lang.VIEW]: 'Afficher',
    [lang.CHAT]: 'Discuter',

    [lang.REGISTRATION_PROCESS]: "Processus d'enregistrement d'elevid",

    [lang.SAVING_PROFILE_MSG]:
      "Enregistrement de votre profil. Cela peut prendre jusqu'à une minute selon la taille de la vidéo et du document d'appui.",
    [lang.UPLOAD_VIDEO_STAGE]: 'Téléchargement de votre vidéo',
    [lang.UPLOAD_DOC_STAGE]: "Téléchargement de votre document d'appui",
    [lang.UPLOAD_DATA_STAGE]: 'Enregistrement des données de votre profil',
    [lang.CLOSE_FINISH]: 'Fermer et terminer',

    [lang.START_RECORDING]: 'Enregistrer',
    [lang.STOP_RECORDING]: "Arrêter\nl'enregistrement",

    [lang.SELECT_LIMITED_OPTIONS]: "Sélectionnez jusqu'à ${count} options.",

    [lang.OPTION_ANY]: 'Tout',

    [lang.NO_CAMERA_FOUND]: 'Aucune caméra trouvée!',
    [lang.CONNECT_CAMERA]: 'Veuillez connecter une caméra puis cliquez sur "Activer".',
    [lang.NO_CAMERA_ACCESS]: 'Accès à la caméra bloqué!',
    [lang.ALLOW_CAMERA]:
      'Veuillez vérifier la configuration de votre navigateur et donner à Elevid accès à votre caméra, puis cliquez sur "Activer".',
    [lang.ACTIVATE]: 'Activer',

    [lang.NOT_AUTH_INFO_TITLE]: 'Authentification',
    [lang.NOT_AUTH_INFO_MESSAGE]:
      "Vous n'êtes pas authentifié. Soit vous vous êtes déconnecté dans une autre fenêtre du navigateur, soit votre session a expiré. Veuillez vous connecter à nouveau.",

    //Expertise
    [lang.ACCOUNTING]: 'Comptabilité',
    [lang.ADMINISTRATION]: 'Administration',
    [lang.AIRLINES]: 'Compagnies aériennes / Aviation',
    [lang.ARCHITECTURE_PLANNING]: 'Architecture et planification',
    [lang.ARTS_CRAFTS]: 'Art et Bricolages',
    [lang.AUTOMOTIVE]: 'Automobile',
    [lang.AVIATION_AEROSPACE]: 'Aviation et aérospatiale',
    [lang.BANKING]: 'Bancaire',
    [lang.BROADCAST_MEDIA]: 'Médias de diffusion',
    [lang.BUILDING_MATERIALS]: 'Matériaux de construction',
    [lang.CAPITAL_MARKETS]: 'Les marchés de capitaux',
    [lang.CHEMICALS]: 'Produits chimiques',
    [lang.CIVIL_ENGINEERING_CONSTRUCTION]: 'Génie civil et construction',
    [lang.COMMERCE_EXPERTISE]: 'Commerce',
    [lang.CONSUMER_GOODS]: 'Biens de consommation',
    [lang.CUSTOMER_SERVICE]: 'Service Clients',
    [lang.DEFENSE_SPACE]: 'Défense et espace',
    [lang.ECOMMERCE]: 'Commerce électronique',
    [lang.EDUCATION]: 'Education',
    [lang.ENGINEERING]: 'Ingénierie',
    [lang.FINANCE]: 'La finance',
    [lang.FMCG]: 'FMCG',
    [lang.GOVERNMENT_PUBLIC_SECTOR]: 'Gouvernement et secteur public',
    [lang.GRAPHIC_DESIGN]: 'Conception graphique',
    [lang.HOSPITAL]: 'Hôpital et soins de santé',
    [lang.HOSPITALITY]: 'Hospitalité',
    [lang.HUMAN_RESOURCES]: 'Ressources humaines',
    [lang.IMPORT_EXPORT]: 'Importer / Exporter',
    [lang.INDUSTRIAL_AUTOMATION]: " L'automatisation industrielle",
    [lang.INFORMATION_TECHNOLOGY]: "Technologies et services de l'information",
    [lang.INSURANCE]: 'Assurance',
    [lang.INVESTMENT_BANKING]: "Banque d'investissement",
    [lang.LAW_PRACTICE]: 'Pratique du droit et services juridiques',
    [lang.LEISURE_TRAVEL_TOURISM]: 'Loisirs, voyages et tourisme',
    [lang.LOGISTICS_SUPPLY_CHAIN]: "Logistique et chaîne d'approvisionnement",
    [lang.MANAGEMENT]: 'La gestion',
    [lang.MANAGEMENT_CONSULTING]: 'Conseil en management',
    [lang.MARKETING_ADVERTISING]: 'Publicité Marketing',
    [lang.MECHANICAL]: 'Génie mécanique ou industriel',
    [lang.MEDIA]: 'Médias',
    [lang.NGO]: 'ONG',
    [lang.OFFICE_MANAGEMENT]: 'Gestion de bureau',
    [lang.OIL_ENERGY]: 'Pétrole et énergie',
    [lang.OPERATIONS_MANAGEMENT]: 'Gestion des opérations',
    [lang.OUTSOURCING_OFFSHORING]: 'Externalisation et délocalisation',
    [lang.PHARMACEUTICALS]: 'Médicaments',
    [lang.PRINTING_PUBLISHING]: 'Impression et édition',
    [lang.PROCESS_MANAGEMENT]: 'La gestion des processus',
    [lang.PRODUCTION]: 'Production',
    [lang.PUBLIC_RELATIONS]: 'Relations publiques et communications',
    [lang.PURCHASING]: 'Achat',
    [lang.QHSE]: 'QHSE',
    [lang.REAL_ESTATE]: 'Immobilier',
    [lang.RESEARCH]: 'Recherche',
    [lang.RESEARCH_DEVELOPMENT]: 'Recherche & Développement',
    [lang.RETAIL]: 'Vente au détail',
    [lang.SALES]: 'Ventes',
    [lang.SECURITY]: 'Sécurité',
    [lang.SERVICES]: 'Prestations de service',
    [lang.SOFTWARE_DEVELOPMENT]: 'Développement de logiciels',
    [lang.TECHNICAL_SUPPORT]: 'Soutien technique',
    [lang.TELECOMMUNICATIONS]: 'Télécommunications',
    [lang.TRAINING_DEVELOPMENT]: 'Formation et DEVELOPPEMENT',
    [lang.TRANSPORT]: 'Transport',
    [lang.VENTURE_CAPITAL]: 'Capital-risque et Private Equity',
    [lang.WAREHOUSING]: 'Entreposage',

    //Sector
    [lang.AGRICULTURE]: 'Agriculture',
    [lang.ARTS_CULTURE]: 'Arts et culture',
    [lang.AUDIT_ADVISORY]: 'Audit et conseil',
    [lang.AVIATION_AERONAUTICAL_DEFENSE]: 'Aviation, aéronautique et défense',
    [lang.CIVIL_STRUCTURAL_ENGINEERING]: 'Génie civil et structurel',
    [lang.COMMERCE_SECTOR]: 'Commerce',
    [lang.CONSTRUCTION]: 'Construction',
    [lang.FINANCE_ACCOUNTING]: 'Finance et comptabilité',
    [lang.HEALTHCARE_MEDICAL]: 'Santé et médecine',
    [lang.INVESTMENT_PRIVATE_EQUITY]: 'Investissement & Private Equity',
    [lang.IT_SOFTWARE]: 'Informatique et logiciels',
    [lang.LEGAL]: 'Légal',
    [lang.MANUFACTURING_INDUSTRIES]: 'Industries manufacturières',
    [lang.MARKETING_PR]: 'Marketing et relations publiques',
    [lang.MEDIA_PUBLISHING_PRINTING]: 'Médias, édition et impression',
    [lang.OIL_GAS]: 'Gaz de pétrole',
    [lang.PHARMACEUTICAL]: 'Pharmaceutique',
    [lang.TOURISM_HOSPITALITY]: 'Tourisme et hôtellerie',
    [lang.TRANSPORTATION]: 'Transport',
    [lang.REGISTER_AS_JOBSEEKER]: "S'inscrire en tant que candidat",
    [lang.REGISTER_AS_EMPLOYER]: "S'inscrire en tant qu'employeur",
    [lang.REGISTER_AS_STARTUP]: "S'inscrire en tant que start-up",
    [lang.REGISTER_AS_INVESTOR]: "S'inscrire en tant qu'investisseur",
    //additional text - redesign
    [lang.TAG_LINE_1_1]: 'elevid est une plateforme',
    [lang.TAG_LINE_1_2]: "d'embauche par vidéo",
    [lang.TAG_LINE_2]: "Trouvez l'emploi de vos rêves ou embauchez les meilleurs talents sur elevid.",
    [lang.TAG_LINE_3]: "Nous connectons  les chercheurs d'emploi et des employeurs partout dans le monde!",
    [lang.ALREADY_HAVE_ACCOUNT]: 'Vous avez déjà un compte?',
    [lang.REGISTER_AS_JOB_SEEKER]: "S'enregistrer en tant que chercheur d'emploi",
    [lang.REGISTER_AS_JOB_EMPLOYEUR]: "S'enregistrer en tant qu'employeur",
    [lang.WHY_ELEVID]: 'Pourquoi elevid ?',
    [lang.WHY_1]: 'GAGNEZ DU TEMPS',
    [lang.WHY_2]: '100% GRATUIT',
    [lang.WHY_3]: 'DECOUVREZ LES SOFT SKILLS & LA PERSONNALITE',
    [lang.WHY_4]: 'CONFIDENTIALITE',
    [lang.WHY_5]: 'CHAT',
    [lang.ARG_1]: "- regardez des vidéos d'une minute - plus de CVs!",
    [lang.ARG_2]: "- concentrez-vous sur le processus d'embauche plutôt que le coût de la prestation",
    [lang.ARG_3]: "- pas d'entretiens ratés",
    [lang.ARG_4]: '- vous seul controlez avec qui vous matchez',
    [lang.ARG_5]: '- utilisez la messagerie intégrée',
    [lang.HOW_DOES_IT_WORK]: 'Comment ça marche?',
    [lang.HOW_1]: '1. Enregistrez votre vidéo avec votre smartphone ou ordinateur',
    [lang.HOW_2]: '2. Matching',
    [lang.HOW_3]: '3. Embauché(e) !',
    [lang.WELCOME_BACK]: 'Content de vous revoir sur elevid !',
    [lang.FORGOT_PASSWORD_LOGIN]: 'Mot de passe oublié?',
    [lang.CREATE_ACCOUNT]: 'Créez-en un',
    [lang.NO_ACCOUNT]: 'Pas de compte? ',
  },
};
